import { getSetting, setSetting, Setting } from "../../data/local/ls";

export const pagination = {
	data() {
		return {
			tableOptions: {
				page: this.$route.query.page ? Number(this.$route.query.page) : 1,
				itemsPerPage: getSetting(Setting.data_table_page_size) || 10,
				sortBy: [],
				sortDesc: [],
				groupBy: [],
				groupDesc: [],
				multiSort: false,
				mustSort: false,
			},
			tableFooterProps: {
				showFirstLastPage: true,
				firstIcon: "mdi-chevron-triple-left",
				lastIcon: "mdi-chevron-triple-right",
				itemsPerPageOptions: [5, 10, 15, 20, 50],
			},
			pagination: {
				totalItemCount: 0,
			},
		};
	},
	watch: {
		tableOptions(newValue, oldValue) {
			if (this.pagination && (newValue.itemsPerPage != oldValue.itemsPerPage || newValue.page != oldValue.page)) {
				this.loadItems();
				let query = Object.assign({}, this.$route.query);
				if (newValue.page != query.page) {
					query.page = newValue.page;
					this.$router.replace({ query });
				}
				setSetting(Setting.data_table_page_size, newValue.itemsPerPage);
			}
		},
	},
};

const shipmentMethodIcons = {
	sea: "mdi-ferry",
	aerial: "mdi-airplane",
	land: "mdi-truck",
	railway: "mdi-train",
};

export const iconHelper = {
	methods: {
		getShipmentMethodIcon(method, defaultIcon) {
			return shipmentMethodIcons[method] || (defaultIcon !== undefined ? defaultIcon : "mdi-call-split");
		},
	},
};
