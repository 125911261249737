<template>
	<page-template>
		<template #content>
			<v-container class="pa-8" style="max-width: 900px">
				<v-card-title class="font-weight-bold pa-2">
					{{ $t("company_profile") }}
				</v-card-title>

				<v-row
					class="pr-3 mb-1 align-center"
					:class="{
						'justify-space-between': $vuetify.breakpoint.smAndUp,
						'justify-center': $vuetify.breakpoint.smAndDown,
					}"
				>
					<v-card-subtitle class="pa-4">
						{{ $t("manage_profile") }}
					</v-card-subtitle>

					<v-btn class="text-capitalize" text outlined elevation="0" density="comfortable" @click="editButtonAction()">
						<v-icon class="mr-2" small>icon-edit</v-icon>
						{{ $t("edit_profile") }}
					</v-btn>
				</v-row>
				<v-card class="mb-1 pa-4" rounded="lg" outlined elevation="0">
					<v-row :class="{ 'justify-center text-center flex-column': $vuetify.breakpoint.smAndDown }">
						<v-col cols="12" sm="3" :class="{ 'd-flex justify-center': $vuetify.breakpoint.smAndDown }">
							<v-avatar size="120" style="position: relative" :color="!editMode || logoUrl ? null : 'primary'">
								<v-img
									v-if="logoUrl || newLogoUrl"
									:src="newLogoUrl || logoUrl"
									:style="`opacity: ${editMode ? 0.5 : 1}`"
									class="rounded-circle ml-4"
									height="120px"
									width="120px"
								>
									<template #placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate />
										</v-row>
									</template>
								</v-img>
								<v-icon v-else class="rounded-circle ml-4" size="120"> mdi-image-outline </v-icon>
								<v-btn
									v-if="editMode"
									icon
									size="x-large"
									absolute
									elevation="2"
									@click="$refs.selectedFileInput.click()"
								>
									<v-icon size="x-large" elevation="2">mdi-pencil</v-icon>
								</v-btn>
								<input
									ref="selectedFileInput"
									type="file"
									accept="image/png, image/jpg, image/jpeg"
									style="display: none"
									@change="onFileSelected"
								/>
							</v-avatar>
						</v-col>

						<v-col cols="12" sm="8" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
							<v-row :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
								<v-col>
									<h3>{{ corporation.name }}</h3>
									<v-row class="mt-4 align-baseline" :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
										<v-icon class="icon-link mr-2 mt-1" size="15"></v-icon>
										<p>{{ corporation.email }}</p>
									</v-row>
									<v-row class="mt-1 align-baseline" :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
										<v-icon class="icon-location mt-1 mr-2" size="15"></v-icon>
										<p>{{ corporation.address }}</p>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="d-flex flex-column align-center justify-center pa-3 my-4" outlined rounded="lg" elevation="0">
					<v-tabs
						v-model="currentTab"
						color="primary"
						class="company-tabs"
						v-if="!showInvitationsTable"
						@change="tabChangeAction"
					>
						<v-tab v-for="(tab, i) in tabs" :key="i" class="text-capitalize">
							{{ $t(tab) }}
						</v-tab>
						<v-col align="end" class="pt-1">
							<v-btn class="text-end text-capitalize" text @click="openInvitationsTable">
								<span class="v-typography--paragraph"> {{ $t("pending_invitations") }} ({{ invitationsCount }}) </span>
								<v-icon class="ml-1" size="16">mdi-arrow-right</v-icon>
							</v-btn>
						</v-col>
					</v-tabs>

					<v-divider v-if="!showInvitationsTable" />

					<v-tabs-items v-model="currentTab" class="mt-4 expanded" v-if="!showInvitationsTable">
						<v-tab-item>
							<company-tab
								ref="companyTab"
								:selected-file="selectedFile"
								:edit-mode="editMode"
								@save="onSave"
								@cancel="onCancel"
								@update:editMode="editMode = $event"
							/>
						</v-tab-item>
						<v-tab-item>
							<employees-tab />
						</v-tab-item>

						<v-tab-item v-if="employeeType === employeeTypes.Admin && !customsAgency">
							<subscription-tab v-if="tabStates['subscription']" />
						</v-tab-item>
					</v-tabs-items>

					<v-container v-if="showInvitationsTable">
						<v-btn text class="text-start text-capitalize mb-2" @click="closeInvitationsTable">
							<v-icon class="mr-2" size="16">mdi-arrow-left</v-icon>
							{{ $t("pending_invitations") }}
						</v-btn>
						<invitations-table :isEmployee="true" />
					</v-container>
				</v-card>

				<invitation-dialog
					v-model="dialog.visibility"
					:item="dialog.item"
					:onlyCompany="false"
					@saved="dialog.saved"
					@emailSent="dialog.emailSent"
					@deleted="dialog.deleted"
				/>
			</v-container>
		</template>
	</page-template>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PageTemplate from "../../templates/PageTemplate";
import uiMixin from "../../../store/interfaces/ui.mixin";
import CompanyTab from "./CompanyTab";
import InvitationsTable from "../partners/partial/InvitationsTable.vue";
import EmployeesTab from "./EmployeesTab";
import remote from "../../../data/remote";
import { invitationTypes } from "../../../utils/enum";
import InvitationDialog from "./dialogs/InvitationDialog.vue";
import SubscriptionTab from "./SubscriptionTab.vue";
import { corporationTypes, employeeTypes } from "../../../utils/enum";

export default {
	mixins: [uiMixin],
	components: {
		PageTemplate,
		CompanyTab,
		InvitationsTable,
		EmployeesTab,
		InvitationDialog,
		SubscriptionTab,
	},
	computed: {
		...mapState(["corporation", "invitationTypes"]),
		...mapGetters(["customsAgency", "corporationType", "employeeType"]),
		corporationTypes: () => corporationTypes,
		invitationTypes: () => invitationTypes,
		employeeTypes: () => employeeTypes,
	},
	data() {
		return {
			logoUrl: null,
			newLogoUrl: null,
			selectedFile: null,
			editMode: false,
			showInvitationsTable: false,
			invitationsCount: 0,
			currentTab: 0,
			previousTab: null,
			tabs: [],
			tabsAccessibility: [
				{
					name: "about",
					rules: {
						employeeTypes: [employeeTypes.Admin],
						corporationTypes: [corporationTypes.Company, corporationTypes.CustomsAgency],
					},
				},
				{
					name: "employees",
					rules: {
						employeeTypes: [employeeTypes.Admin],
						corporationTypes: [corporationTypes.Company, corporationTypes.CustomsAgency],
					},
				},
				{
					name: "subscription",
					rules: {
						employeeTypes: [employeeTypes.Admin],
						corporationTypes: [corporationTypes.Company, corporationTypes.CustomsAgency],
					},
				},
				// {
				// 	name: "warehouses",
				// 	rules: {
				// 		employeeTypes: [employeeTypes.Admin],
				// 		corporationTypes: [corporationTypes.Company],
				// 	},
				// },
				// {
				// 	name: "social_media",
				// 	rules: {
				// 		employeeTypes: [employeeTypes.Admin],
				// 		corporationTypes: [corporationTypes.Company],
				// 	},
				// },
			],
			dialog: {
				visibility: false,
				item: null,
				show: (invitation) => {
					this.dialog.item = invitation;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadInvitations();
				},
				emailSent: () => {
					this.dialog.hide();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadInvitations();
				},
			},
			tabStates: {},
		};
	},
	methods: {
		...mapActions("auth", ["signOut"]),
		editButtonAction() {
			this.currentTab = 0;
			this.editMode = true;
		},
		openInvitationsTable() {
			this.previousTab = this.currentTab;
			this.showInvitationsTable = true;
			this.currentTab = null;
		},
		closeInvitationsTable() {
			this.showInvitationsTable = false;
			if (this.previousTab !== null) {
				this.currentTab = this.previousTab;
				this.previousTab = null;
			}
		},
		loadItems() {
			remote.invitations.filter(
				{
					status: "active",
					types: `${invitationTypes.Employee}`,
				},
				{
					pager: { number: 1, size: 1 },
				},
				{
					onSuccess: (result) => {
						this.invitationsCount = result.pagination.totalItemCount;
					},
					onFail: (error) => {
						if (error) console.error(error);
					},
				}
			);
		},
		tabChangeAction(index) {
			this.tabStates[this.tabs[index]] = true;
		},
		checkAccessibility(rules) {
			return rules.employeeTypes.includes(this.employeeType) && rules.corporationTypes.includes(this.corporationType);
		},
		loadLogo() {
			if (this.corporation && this.corporation.logo) {
				remote.files.get(this.corporation.logo.key, {
					onSuccess: (result) => {
						this.logoUrl = window.URL.createObjectURL(new Blob([result]));
					},
					onFail: (error) => {
						console.error("Failed to load logo:", error);
					},
				});
			}
		},
		changeLogo(file) {
			if (file && file.size <= 262144) {
				this.selectedFile = file;
				this.newLogoUrl = URL.createObjectURL(file);
			} else if (file) {
				this.showSnackBar({
					message: this.$t("$message.file_oversize", { size: "256kB" }),
				});
			}
		},
		onFileSelected(event) {
			const file = event.target.files[0];
			this.changeLogo(file);
		},
		onSave() {
			this.editMode = false;
			this.loadLogo();
			this.selectedFile = null;
			this.newLogoUrl = null;
		},
		onCancel() {
			this.editMode = false;
			this.selectedFile = null;
			this.newLogoUrl = null;
		},
	},
	watch: {
		corporation(newVal) {
			if (newVal) {
				this.loadLogo();
			}
		},
	},
	created() {
		this.loadItems();
		this.$trackPageView(this.$route.path);
		this.tabsAccessibility.forEach((t) => {
			if (this.checkAccessibility(t.rules)) this.tabs.push(t.name);
		});
		this.loadLogo();
	},
};
</script>
