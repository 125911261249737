<template>
	<v-snackbar
		v-model="$store.state.ui.snackBar.visibility"
		bottom
		outlined
		multi-line
		:color="$store.state.ui.snackBar.color"
		:timeout="$store.state.ui.snackBar.timeout"
	>
		<div class="d-flex align-center">
			<v-icon color="success" left class="mr-2 success-icon">mdi-check-circle</v-icon>
			<span class="text-caption grey--text">{{ $store.state.ui.snackBar.message }}</span>
		</div>

		<v-progress-linear
			v-if="$store.state.ui.snackBar.visibility"
			absolute
			bottom
			:value="progress"
			:height="3"
			color="primary"
		></v-progress-linear>

		<template v-slot:action="{}">
			<v-btn icon @click="$store.dispatch('ui/hideSnackBar')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	data() {
		return {
			progress: 0,
			duration: this.$store.state.ui.snackBar.timeout - 400,
			stepTime: 50,
		};
	},
	computed: {
		increment() {
			return 100 / (this.duration / this.stepTime);
		},
	},
	methods: {
		startProgress() {
			const interval = setInterval(() => {
				if (this.progress < 100) {
					this.progress += this.increment;
				} else {
					clearInterval(interval);
					this.showSnackbar = false;
				}
			}, this.stepTime);
		},
	},
	watch: {
		"$store.state.ui.snackBar.visibility": function (newVal) {
			if (newVal) {
				this.startProgress();
			} else {
				this.progress = 0;
			}
		},
	},
};
</script>
