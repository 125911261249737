<template>
	<v-container>
		<common-table
			:headers="headers"
			:items="items"
			:items-length="pagination.totalItemCount"
			:options.sync="tableOptions"
			:loading="loading"
			:items-per-page-options="tableFooterProps.itemsPerPageOptions"
			:entity-name="$t('proforma_invoice')"
			:button-text="buttonText"
			@row-click="rowAction"
			@button-click="handleButtonClick"
			:disable-sort="true"
		>
			<template #item.date="{ item }">
				{{ item.date ? item.date.format($t("$format.date")) : "" }}
			</template>
			<template #item.status="{ item }">
				<v-chip :ripple="false" :color="getChipColor(item.status)" :text-color="getChipTextColor(item.status)">
					{{ $t(`$proformaInvoice.$status.${item.status}`) }}
				</v-chip>
			</template>
		</common-table>
	</v-container>
</template>

<script>
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import remote from "../../../../data/remote";
import CommonTable from "../../../partials/CommonTable.vue";

export default {
	props: {
		role: "sender" | "receiver",
		filterData: Object,
		initialPage: Number,
		atFront: Boolean,
		listChanged: Boolean,
		buttonText: String,
	},
	mixins: [paginationMixin],
	components: {
		CommonTable,
	},
	data() {
		return {
			items: [],
			loading: false,
			lastFilter: {},
			outgoingHeaders: [
				{ text: this.$t("proforma_number"), value: "no" },
				{ text: this.$t("receiver"), value: "receiver.name" },
				{ text: this.$t("date"), value: "date" },
				{ text: this.$t("status"), value: "status" },
			],
			incomingHeaders: [
				{ text: this.$t("proforma_number"), value: "no" },
				{ text: this.$t("sender"), value: "sender.name" },
				{ text: this.$t("date"), value: "date" },
				{ text: this.$t("status"), value: "status" },
			],
		};
	},
	computed: {
		oppositeRole() {
			return this.role == "sender" ? "receiver" : "sender";
		},
		headers() {
			return this.role == "sender" ? this.outgoingHeaders : this.incomingHeaders;
		},
	},
	watch: {
		filterData(val) {
			if (JSON.stringify(val) != JSON.stringify(this.lastFilter)) {
				this.lastFilter = val;
				this.loadItems();
			}
		},
		atFront(val) {
			if (val) {
				let query = Object.assign({}, this.$route.query);
				if (this.tableOptions.page != query.page) {
					query.page = this.tableOptions.page;
					this.$router.replace({ query });
				}
			}
		},
		listChanged() {
			this.loadItems();
		},
	},
	methods: {
		getChipColor(status) {
			const colorMap = {
				Template: "grey lighten-2",
				Revoked: "yellow lighten-4",
				Processed: "purple lighten-4",
				Rejected: "red lighten-4",
				Approved: "green lighten-4",
			};
			return colorMap[status] || "grey lighten-2";
		},
		getChipTextColor(status) {
			const textColorMap = {
				Template: "grey darken-4",
				Revoked: "grey darken-4",
				Processed: "purple darken-2",
				Rejected: "red darken-2",
				Approved: "green darken-2",
			};
			return textColorMap[status] || "grey darken-4";
		},
		loadItems() {
			this.loading = true;

			const query = Object.assign({}, this.$route.query);
			if (query.page != this.tableOptions.page) query.page = this.tableOptions.page;
			query.referenceId = this.filterData.referenceId || undefined;
			query.statuses = this.filterData.statuses.length ? this.filterData.statuses.join() : undefined;
			if (JSON.stringify(query) != JSON.stringify(this.$route.query)) this.$router.replace({ query });

			remote.proformaInvoices.filter(
				{
					filter: this.filterData,
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.pagination = result.pagination;
						this.items = result.items.sort((a, b) => {
							return a.date && b.date ? (a.date < b.date ? 1 : -1) : !a.date ? 1 : -1;
						});
						this.loading = false;
					},
					onFail: (_) => (this.loading = false),
				}
			);
		},
		rowAction(item) {
			this.$emit("rowAction", item);
		},
		handleButtonClick() {
			this.$emit("buttonClick");
		},
	},
	created() {
		if (this.initialPage) this.tableOptions.page = this.initialPage;
		this.loadItems();
	},
};
</script>
