<template>
	<page-template>
		<template slot="content">
			<common-table
				:title="$t('products')"
				showFilter
				:filtered="filterDialog.data.text || filterDialog.data.productGroupId ? true : false"
				:options.sync="tableOptions"
				:headers="headers"
				:items="products"
				:items-length="pagination.totalItemCount"
				:items-per-page="tableOptions.itemsPerPage"
				:items-per-page-options="tableFooterProps.itemsPerPageOptions"
				:loading="loading"
				:entity-name="$t('product')"
				:button-text="$t('add_item', { item: $t('product') })"
				@edit-item="productAction"
				@delete-item="deleteAction"
				@row-click="productAction"
				@button-click="dialog.show(null)"
				@filter-button-click="filterDialog.show"
			>
				<template #[`item.name`]="{ item }">
					{{ item.name[$lang] && item.name[$lang].length ? item.name[$lang] : item.name["_default"] }}
				</template>
				<template #[`item.productGroup`]="{ item }">
					{{ getProductGroupName(item.productGroupId) }}
				</template>
			</common-table>
			<!-- <common-table
					:options.sync="tableOptions"
					:headers="headers"
					:items="products"
					:items-length="pagination.totalItemCount"
					:items-per-page="tableOptions.itemsPerPage"
					:items-per-page-options="tableFooterProps.itemsPerPageOptions"
					:loading="loading"
					:entity-name="$t('product')"
					@edit-item="productAction"
					@delete-item="deleteAction"
					@row-click="productAction"
				>
					<template #[`item.name`]="{ item }">
						{{ item.name[$lang] && item.name[$lang].length ? item.name[$lang] : item.name["_default"] }}
					</template>
					<template #[`item.productGroup`]="{ item }">
						{{ getProductGroupName(item.productGroupId) }}
					</template>
				</common-table> -->

			<product-filter-dialog v-model="filterDialog.visibility" :data="filterDialog.data" @done="filterDialog.done" />

			<product-dialog v-model="dialog.visibility" :item="dialog.item" @saved="dialog.saved" @deleted="dialog.deleted" />
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import { pagination as paginationMixin } from "../../mixins/utils.mixin";
import PageTemplate from "../../templates/PageTemplate";
import remote from "../../../data/remote";
import ProductDialog from "./dialog/ProductDialog.vue";
import ProductFilterDialog from "./dialog/ProductFilterDialog.vue";
import CommonTable from "../../partials/CommonTable.vue";

export default {
	mixins: [uiMixin, paginationMixin],
	components: { PageTemplate, ProductDialog, ProductFilterDialog, CommonTable },
	data() {
		return {
			products: [],
			productGroupTree: {},
			productGroups: [],
			loading: false,
			filterDialog: {
				visibility: false,
				data: { text: null, productGroupId: null },
				show: () => (this.filterDialog.visibility = true),
				hide: () => (this.filterDialog.visibility = false),
				done: (data) => {
					this.filterDialog.data = data;
					this.$router.push({ query: this.filterDialog.data });
					this.filterDialog.hide();
					this.loadItems();
				},
			},
			dialog: {
				visibility: false,
				item: null,
				show(product) {
					this.item = product;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
			},
			headers: [
				{ text: this.$t("code"), value: "code" },
				{ text: this.$t("name"), value: "name" },
				{ text: this.$t("product_group"), value: "productGroupName" },
				{ text: this.$t("actions"), value: "actions" },
			],
		};
	},
	computed: {
		filterIcon() {
			return this.filterDialog.data.text || this.filterDialog.data.productGroupId ? "mdi-filter" : "mdi-filter-outline";
		},
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.products.filter({
				filter: this.filterDialog.data,
				pager: {
					number: this.tableOptions.page,
					size: this.tableOptions.itemsPerPage,
				},
				sorter: this.tableOptions.sortBy.length
					? {
							property: this.tableOptions.sortBy,
							method: this.tableOptions.sortDesc ? "desc" : "asc",
					  }
					: undefined,
				onSuccess: (result) => {
					this.pagination = result.pagination;

					this.products = result.items
						.sort((a, b) => {
							if (a.createdAt && b.createdAt) {
								return a.createdAt < b.createdAt ? 1 : -1;
							} else {
								return !a.createdAt ? 1 : -1;
							}
						})
						.map((product) => {
							const productGroupName = this.getProductGroupName(product.productGroupId);
							return {
								...product,
								productGroupName: productGroupName,
							};
						});

					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},

		loadProductGroupTree() {
			remote.productGroups.getMyProductGroupTree({
				onSuccess: (result) => {
					this.productGroupTree = result;
					this.loadItems();
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},

		deleteAction(item) {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.products.delete(item.id, {
						onSuccess: (result) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("product"),
								}),
							});
							this.loadItems();
							this.$emit("deleted", result);
						},
						onFail: () => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.delete_failed", {
									item: this.$t("product"),
								}),
								color: "error",
							});
						},
					});
				},
			});
		},
		productAction(product) {
			this.dialog.show(product);
		},
		getProductGroupName(productGroupId) {
			const searchTree = (nodes) => {
				for (let node of nodes) {
					if (node.id === productGroupId) {
						const currentLang = this.$lang || "en";
						return node.name?.[currentLang] || node.name?._default || node.name?.en || node.name?.tr;
					}
					if (node.children && node.children.length > 0) {
						const found = searchTree(node.children);
						if (found) return found;
					}
				}
				return null;
			};

			return searchTree(this.productGroupTree.children || []);
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.filterDialog.data.text = this.$route.query.text;
		this.filterDialog.data.productGroupId = this.$route.query.productGroupId;
		this.loadProductGroupTree();
	},
};
</script>

<style scoped>
.custom-add-btn {
	border: 1.6px solid #005d8f;
	color: #005d8f;
}
</style>
