<template>
	<v-container fluid class="d-flex justify-center text-center">
		<v-col>
			<v-row class="d-flex justify-center">
				<v-card-subtitle
					:class="isMobile ? 'v-typography--title1' : 'v-typography--title2'"
					class="accent--text title-with-line pa-0"
				>
					{{ $t(`$landing.$lifecycle.headline`) }}
				</v-card-subtitle>
			</v-row>
			<v-row class="d-flex justify-center align-center">
				<v-card-title :class="isMobile ? 'v-typography--title' : 'v-typography--title3'">
					{{ $t(`$landing.$lifecycle.tracking_guide_title`) }}
				</v-card-title>
			</v-row>
			<v-row class="d-flex justify-center align-center">
				<v-card-subtitle :class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'" class="pa-0">
					{{ $t(`$landing.$lifecycle.productivity_title`) }}
				</v-card-subtitle>
			</v-row>
			<v-row class="d-flex justify-center align-center mb-4">
				<v-btn class="rounded-lg mt-6 px-16 py-6" color="secondary" elevation="0" @click="goToContact">
					{{ $t(`$landing.$lifecycle.info_button`) }}
				</v-btn>
			</v-row>

			<v-row class="d-flex justify-center mt-12">
				<v-col
					v-for="(img, index) in images"
					:key="index"
					cols="12"
					sm="6"
					md="3"
					class="d-flex flex-column align-start justify-start mb-6 mx-4"
				>
					<v-card elevation="0" class="h-100 bg_transparent">
						<v-row class="d-flex align-start justify-center">
							<v-img :src="img.src" class="image-style mb-4" :alt="getAltText(index)" loading="lazy" />
						</v-row>
						<v-row>
							<v-card-text class="v-typography--title1">
								{{ $t(`$landing.$lifecycle.lifecycle_titles.${index}`) }}
							</v-card-text>
						</v-row>
						<v-row>
							<v-card-text class="v-typography--paragraph pa-1 text-center">
								{{ $t(`$landing.$lifecycle.lifecycle_texts.${index}`) }}
							</v-card-text>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-container>
</template>

<script>
import loginImg from "@/assets/login.png";
import shippingImg from "@/assets/shipping.png";
import followUpImg from "@/assets/follow_up.png";

export default {
	props: { isMobile: Boolean },
	data() {
		return {
			images: [{ src: loginImg }, { src: shippingImg }, { src: followUpImg }],
		};
	},
	methods: {
		getAltText(index) {
			const altTexts = [
				"Login Process Illustration",
				"Shipping Process Illustration",
				"Follow-up Process Illustration",
			];
			return altTexts[index];
		},
		goToContact() {
			this.$nextTick(() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" }));
		},
	},
};
</script>

<style scoped>
.image-style {
	max-width: 100px;
	height: auto;
}

.title-with-line {
	color: #005d8f;
	position: relative;
	display: inline-block;
}

.title-with-line::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transform: scaleX(0.6);
	transform-origin: left;
	margin-left: 1px;
}
@media screen and (max-width: 600px) {
	.title-with-line::after {
		height: 0;
	}
}
</style>
