<template>
	<v-container v-if="innerCompany" class="pb-0">
		<v-form ref="form" v-model="valid">
			<v-row class="pa-0">
				<v-col cols="12" class="py-2">
					<title-value-list-item v-if="!editMode" :title="$t('company_name')" :value="innerCompany.name" />
					<v-text-field
						v-if="editMode"
						:label="$t('company_name')"
						v-model="dummyCompany.name"
						class="rounded-xl"
						:rules="rules.name"
					/>
				</v-col>

				<v-col cols="6" class="py-2">
					<title-value-list-item
						v-if="!editMode"
						:title="$t('country')"
						:value="getCountryName(innerCompany.countryId)"
					/>
					<country-select
						v-if="editMode"
						:label="$t('country')"
						v-model="dummyCompany.countryId"
						:options="countries"
						:rules="rules.country"
					/>
				</v-col>

				<v-col cols="6" class="py-2">
					<title-value-list-item v-if="!editMode" :title="$t('website')" :value="innerCompany.website" />
					<v-text-field
						v-if="editMode"
						:label="$t('website')"
						v-model="dummyCompany.website"
						class="rounded-xl"
						:rules="rules.website"
					/>
				</v-col>

				<v-col cols="6" class="py-2">
					<title-value-list-item
						v-if="!editMode && innerCompany.phoneNumber"
						:title="$t('phone_number')"
						:value="innerCompany.phoneNumber"
					/>
					<v-text-field
						v-if="editMode"
						v-model="dummyCompany.phoneNumber"
						:label="$t('phone_number')"
						class="rounded-xl"
						:rules="rules.phoneNumber"
					/>
				</v-col>

				<v-col cols="6" class="py-2">
					<title-value-list-item
						v-if="!editMode && innerCompany.email"
						:title="$t('email')"
						:value="innerCompany.email"
					/>
					<v-text-field
						v-if="editMode"
						v-model="dummyCompany.email"
						:label="$t('email')"
						class="rounded-xl"
						:rules="rules.email"
					/>
				</v-col>

				<v-col cols="6" class="py-2">
					<title-value-list-item v-if="!editMode" :title="$t('tax_number')" :value="innerCompany.taxNumber" />
					<v-text-field
						v-if="editMode"
						v-model="dummyCompany.taxNumber"
						:label="$t('tax_number')"
						type="number"
						hide-spin-buttons
						counter="11"
						:rules="rules.taxNumber"
						class="rounded-xl"
					/>
				</v-col>

				<v-col cols="6" class="py-0">
					<title-value-list-item v-if="!editMode" :title="$t('address')" :value="innerCompany.address" />
					<v-text-field
						v-if="editMode"
						v-model="dummyCompany.address"
						:label="$t('address')"
						class="rounded-xl"
						counter="100"
						:rules="rules.address"
					/>
				</v-col>

				<v-col cols="6" class="py-4">
					<p v-if="!editMode" class="mb-2 text-body-1">
						{{ $t("default_language") }}
					</p>
					<span v-if="!editMode" class="rounded-pill grey lighten-3 px-4 py-2 text-body-1 d-inline-block">
						{{ $t(`$languageLocal.${innerCompany.settings.localization.defaultLanguageCode}`) }}
					</span>
					<v-select
						v-if="editMode"
						:label="$t('default_language')"
						v-model="dummyCompany.settings.localization.defaultLanguageCode"
						:items="languageOptions"
						outlined
						class="rounded-xl"
						@change="defaultLanguageChangeAction"
						:menu-props="{ offsetY: true }"
					></v-select>
				</v-col>

				<v-col cols="6" class="py-4">
					<p v-if="!editMode" class="text-body-1 mb-2">
						{{ $t("language_options") }}
					</p>
					<div v-if="!editMode" class="d-flex flex-wrap">
						<span
							v-for="i in innerCompany.settings.localization.options"
							:key="i"
							class="rounded-pill grey lighten-3 px-4 py-2 mr-1 text-body-1"
						>
							{{ $t(`$languageLocal.${i}`) }}
						</span>
					</div>
					<v-select
						v-if="editMode"
						:label="$t('language_options')"
						v-model="dummyCompany.settings.localization.options"
						:items="languageOptions"
						multiple
						outlined
						class="rounded-xl"
						:menu-props="{ offsetY: true }"
					></v-select>
				</v-col>
			</v-row>

			<v-row
				v-if="editMode"
				class="mb-4 d-flex align-center flex-wrap"
				:class="{
					'justify-end': $vuetify.breakpoint.mdAndUp,
					'justify-center': $vuetify.breakpoint.smAndDown,
				}"
			>
				<v-col cols="auto" class="py-2">
					<v-btn class="text-capitalize" width="120" large elevation="0" text :disabled="loading" @click="emitCancel">
						{{ $t("cancel") }}
					</v-btn>
				</v-col>

				<v-col cols="auto" class="py-2">
					<v-btn
						variant="text"
						large
						color="primary"
						elevation="1"
						class="text-capitalize text-white rounded-xl"
						:disabled="loading"
						:loading="saving"
						width="120"
						@click="saveAction"
					>
						{{ $t("save") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import uiMixin from "../../../store/interfaces/ui.mixin";
import TitleValueListItem from "../../partials/TitleValueListItem";
import remote from "../../../data/remote";
import CountrySelect from "../../controls/CountrySelect";
import cloneDeep from "lodash.clonedeep";

export default {
	mixins: [uiMixin],
	components: { TitleValueListItem, CountrySelect },
	props: {
		selectedFile: {
			type: File,
			default: null,
		},
		editMode: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company", "customsAgency", "employee"]),

		loading() {
			return this.saving || this.uploading;
		},
	},
	data() {
		return {
			innerCompany: null,
			dummyCompany: null,
			absolute: true,
			overlay: false,
			valid: true,
			uploading: false,
			saving: false,
			logoUrl: null,
			newLogoUrl: null,
			statuses: {
				0: this.$t("passive"),
				1: this.$t("active"),
			},
			languageOptions: [
				{ text: this.$t("$languageLocal.ar"), value: "ar" },
				{ text: this.$t("$languageLocal.de"), value: "de" },
				{ text: this.$t("$languageLocal.en"), value: "en" },
				{ text: this.$t("$languageLocal.es"), value: "es" },
				{ text: this.$t("$languageLocal.fr"), value: "fr" },
				{ text: this.$t("$languageLocal.hi"), value: "hi" },
				{ text: this.$t("$languageLocal.ind"), value: "ind" },
				{ text: this.$t("$languageLocal.it"), value: "it" },
				{ text: this.$t("$languageLocal.ja"), value: "ja" },
				{ text: this.$t("$languageLocal.ko"), value: "ko" },
				{ text: this.$t("$languageLocal.pt"), value: "pt" },
				{ text: this.$t("$languageLocal.ru"), value: "ru" },
				{ text: this.$t("$languageLocal.tr"), value: "tr" },
				{ text: this.$t("$languageLocal.vi"), value: "vi" },
				{ text: this.$t("$languageLocal.zh"), value: "zh" },
			],
			rules: {
				name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("name"),
							});
						else if (v && v.length < 3)
							return this.$t("$validation.min_length", {
								item: this.$t("name"),
								length: "3",
							});
						else if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "50",
							});
						return true;
					},
				],
				country: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("country"),
							});
						return true;
					},
				],
				taxNumber: [
					(v) => {
						if (!v) {
							return this.$t("$validation.required", {
								item: this.$t("tax_number"),
							});
						} else if (isNaN(v)) {
							return this.$t("$validation.invalid", {
								item: this.$t("tax_number"),
							});
						} else if (v.length < 10) {
							return this.$t("$validation.min_length", {
								item: this.$t("tax_number"),
								length: "10",
							});
						} else if (v.length > 11) {
							return this.$t("$validation.max_length", {
								item: this.$t("tax_number"),
								length: "11",
							});
						}
						return true;
					},
				],
				address: [
					(v) => {
						if (!v) {
							return this.$t("$validation.required", {
								item: this.$t("address"),
							});
						} else if (v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("address"),
								length: "100",
							});
						}
						return true;
					},
				],
				phoneNumber: [
					(v) => {
						if (v && isNaN(v))
							return this.$t("$validation.invalid", {
								item: this.$t("phone_number"),
							});
						else if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("phone_number"),
								length: "20",
							});
						return true;
					},
				],
				email: [
					(v) => {
						if (v === "") return true;
						else if (v && !/.+@.+\..+/.test(v)) return this.$t("$validation.invalid", { item: this.$t("email") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("email"),
								length: "100",
							});
						return true;
					},
				],
				website: [
					(v) => {
						if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("website"),
								length: "50",
							});
						return true;
					},
				],
			},
		};
	},
	methods: {
		...mapActions("file", ["sendFile"]),
		setInnerCompany() {
			if (this.company) this.innerCompany = cloneDeep(this.company);
			else if (this.customsAgency) this.innerCompany = cloneDeep(this.customsAgency);
			this.handleData();
		},
		handleData() {
			this.setCompanyCountry();
			this.setStatus();
		},
		setCompanyCountry() {
			if (this.innerCompany) {
				var dummy = this.countries.find((country) => country.id === this.innerCompany.countryId);
				this.innerCompany.country = dummy
					? { name: dummy.name, id: dummy.id, code: dummy.code }
					: { name: "", id: 0, code: "" };
			}
		},
		setStatus() {
			if (this.innerCompany) this.innerCompany.statusText = this.statuses[this.innerCompany.status];
		},
		defaultLanguageChangeAction() {
			if (
				this.company &&
				!this.dummyCompany.settings.localization.options.includes(
					this.dummyCompany.settings.localization.defaultLanguageCode
				)
			)
				this.dummyCompany.settings.localization.options.push(
					this.dummyCompany.settings.localization.defaultLanguageCode
				);
		},
		saveAction() {
			if (this.valid) {
				if (this.selectedFile) {
					this.uploading = true;
					remote.files.send(this.selectedFile, "companyLogo", {
						onSuccess: (response) => {
							this.uploading = false;
							this.dummyCompany.logo = {
								key: response.key,
								type: response.type,
							};
							this.saveCompany(this.dummyCompany);
						},
						onFail: () => (this.uploading = false),
					});
				} else {
					this.saveCompany(this.dummyCompany);
				}
			} else this.$refs.form.validate();
		},
		saveCompany(item) {
			this.saving = true;
			remote.companies.update(item, {
				onSuccess: (response) => {
					this.saving = false;
					this.$store.commit("SET_CORPORATION", response);
					this.innerCompany = response;
					this.handleData();
					this.$emit("save");
				},
				onFail: () => (this.saving = false),
			});
		},
		saveCustomsAgency(item) {
			this.saving = true;
			remote.customsAgencies.update(item, {
				onSuccess: (response) => {
					this.saving = false;
					this.$store.commit("SET_CORPORATION", response);
					this.innerCompany = response;
					this.handleData();
					this.$emit("save");
				},
				onFail: () => (this.saving = false),
			});
		},
		emitCancel() {
			this.$emit("cancel");
		},
		getCountryName(countryId) {
			const country = this.countries.find((c) => c.id === countryId);
			return country ? `${this.$t(`$country.${country.name}`)} (${country.code})` : "";
		},
	},
	watch: {
		editMode(val) {
			if (val) {
				this.dummyCompany = cloneDeep(this.innerCompany);
			} else {
				this.dummyCompany = null;
			}
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.setInnerCompany();
	},
};
</script>

<style scoped>
.transparency {
	background-color: rgba(255, 255, 255, 0.1);
}
</style>
