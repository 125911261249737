<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card>
			<v-card-title class="headline">
				{{ $t("send_email") }}
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="email" :label="$t('email')" prepend-icon="mdi-at" :rules="rules.email" />
				</v-form>
			</v-card-text>
			<v-divider />

			<v-card-actions>
				<v-spacer />
				<v-btn text :disabled="loading" @click="closedAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn color="accent" :disabled="loading" :loading="loading" @click="sendAction">
					{{ $t("send") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import { mapState } from "vuex";

export default {
	props: {
		value: Boolean,
		customerPartner: Object,
		shipmentId: String,
	},
	mixins: [uiMixin],
	data() {
		return {
			email: null,
			valid: null,
			loading: false,
			rules: {
				email: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (!/.+@.+\..+/.test(v)) return this.$t("$validation.invalid", { item: this.$t("email") });
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapState(["corporation"]),
	},
	watch: {
		value(val) {
			//TODO: isAbstract kontrolü eklenecek. Ona göre request company ya da partners'a gönderilecek.
			if (val) this.loadPartnerInfo();
			else this.closedAction();
		},
	},
	methods: {
		loadPartnerInfo() {
			this.loading = true;
			if (this.customerPartner.isAbstract)
				remote.corporations.partners.find(this.corporation.id, this.customerPartner.referenceId, {
					onSuccess: (result) => {
						this.email = result.email;
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				});
			else
				remote.corporations.partners.findByCorporationId(this.corporation.id, this.customerPartner.referenceId, {
					onSuccess: (result) => {
						this.email = result.email;
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				});
		},
		sendAction() {
			if (this.valid) {
				this.loading = true;
				remote.shipments.sendEmail(this.shipmentId, this.email, {
					onSuccess: (result) => {
						this.showSnackBar({
							message: this.$t("$message.sent_email", {
								value: this.email,
							}),
						});
						this.closedAction();
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				});
			} else this.$refs.form.validate();
		},
		closedAction() {
			this.email = null;
			//this.$emit("input", false);
			this.$emit("closed");
		},
	},
};
</script>
