<template>
	<v-timeline dense clipped class="pt-4" elevation="">
		<v-timeline-item v-for="item in list" :key="item.id" class="pb-4" small fill-dot :color="getBulletColor(item)">
			<template v-slot:icon>
				<span class="text-caption white--text">{{ item.iconText }}</span>
			</template>
			<v-row justify="space-between">
				<v-col cols="12" xs="12" sm="9">
					{{ item.text }}
					<v-card v-if="item.note" elevation="1">
						<v-card-text class="pa-2 mt-2">
							{{ item.note }}
						</v-card-text>
					</v-card>
				</v-col>
				<v-col class="d-none d-sm-block text-right text-caption" cols="3">
					{{ item.date }}
				</v-col>
				<v-col class="d-sm-none text-caption pt-0" cols="12">
					{{ item.date }}
				</v-col>
			</v-row>
		</v-timeline-item>
	</v-timeline>
</template>

<script>
import { mapState } from "vuex";
import colors from "vuetify/lib/util/colors";

export default {
	props: { value: Object },
	data() {
		return {
			colors,
			list: null,
			message: "",
			savingMessage: false,
		};
	},
	computed: {
		...mapState("auth", ["user"]),
	},
	watch: {
		value(_) {
			this.handleProps();
		},
	},
	methods: {
		handleProps() {
			this.list = [];
			this.value.actions.reverse().forEach((action) => {
				const item = {
					text: null,
					note: null,
					date: action.date.substring(0, 10) + " " + action.date.substring(11, 16),
					iconText: this.generateBulletText(action.createdBy),
					type: action.type,
					id: action.id,
					createdBy: action.createdBy,
				};
				const operationActions = ["send", "revoke", "approve", "demand_revise", "reject", "mark_as_processed"];
				if (action.type == "comment") item.text = `${action.createdBy.firstName} ${action.createdBy.lastName}`;
				else {
					const name = `${action.createdBy.firstName} ${action.createdBy.lastName}`;
					if (this.value.receiver.isAbstract && operationActions.includes(action.type)) {
						item.text = this.$t(`$proformaInvoice.$actionDescription2.${action.type}`, { name });
					} else {
						item.text = this.$t(`$proformaInvoice.$actionDescription.${action.type}`, { name });
					}
				}
				item.note = action.referenceText;
				this.list.push(item);
			});
		},
		generateBulletText(item) {
			return `${item.firstName[0].toUpperCase()}${item.lastName[0].toUpperCase()}`;
		},
		getBulletColor(action) {
			if (action.createdBy.firstName == this.user.firstName && action.createdBy.lastName == this.user.lastName)
				return "info";
			else return colors.grey.darken1;
		},
		// commentSaveAction() {
		// 	if (this.message.trim().length > 0) {
		// 		this.savingMessage = true;
		// 		remote.shipments.comment(this.value, this.message, {
		// 			onSuccess: (shipment) => {
		// 				this.$emit("input", shipment);
		// 				this.savingMessage = false;
		// 				this.message = "";
		// 			},
		// 			onFail: () => (savingMessage = false),
		// 		});
		// 	}
		// },
	},
	created() {
		this.handleProps();
	},
};
</script>
