import { mapActions } from "vuex";

export default {
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		isTablet() {
			return this.$vuetify.breakpoint.mdOnly;
		},
	},
	methods: {
		...mapActions("ui", [
			"showProgressBar",
			"hideProgressBar",
			"showProgressDialog",
			"hideProgressDialog",
			"showAlertDialog",
			"showConfirmDialog",
			"showTextInputDialog",
			"showSnackBar",
		]),
	},
};
