import Model from "../_model";

export default class PaymentModel extends Model {
	constructor(provider) {
		super(provider, "payment");
	}

	find(id, { onSuccess, onFail }) {
		return super.find({ id, onSuccess, onFail });
	}

	generateToken(payment, { onSuccess, onFail }) {
		const body = {
			merchant_oid: payment.merchant_oid,
			product_id: payment.product_id,
		};
		return super.create(body, {
			url: `${this._getUrl()}/generateToken`,
			onSuccess,
			onFail,
		});
	}

	handlePayment(payment, { onSuccess, onFail }) {
		var body = {
			card_number: payment.card_number,
			cc_owner: payment.cc_owner,
			cvv: payment.cvv,
			expiry_month: payment.expiry_month,
			expiry_year: payment.expiry_year,
			//
			merchant_oid: payment.merchant_oid,
			//
			paytr_token: payment.paytr_token,
			//
			product_id: payment.product_id,
			//
			merchant_ok_url: payment.merchant_ok_url,
			merchant_fail_url: payment.merchant_fail_url,
		};

		return super.create(body, {
			url: `${this._getUrl()}/handlePayment`,
			onSuccess,
			onFail,
		});
	}

	delete(id, { onSuccess, onFail }) {
		return super.delete({ id, onSuccess, onFail });
	}

	payback(oid, { onSuccess, onFail }) {
		return super.update(
			{},
			{
				url: `${this._getUrl()}/${oid}/payback`,
				onSuccess,
				onFail,
			}
		);
	}

	getMyCardInfo({ onSuccess, onFail }) {
		return super.find({
			url: "mySavedCardInfo",
			onSuccess,
			onFail,
		});
	}
}
