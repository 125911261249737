import HttpModule from "./_http_module";

export default class Auth extends HttpModule {
	signIn({ identifier, password, appCode, deviceName, onSuccess, onFail }) {
		const body = {
			appCode,
			deviceName,
			identifier,
			password,
		};
		super._post("signIn", body, { requiresToken: false, onSuccess, onFail });
	}

	signUp({ email, password, firstName, lastName, appCode, deviceName, onSuccess, onFail }) {
		const body = {
			appCode,
			deviceName,
			email,
			password,
			firstName,
			lastName,
		};
		super._post("signup", body, { requiresToken: false, onSuccess, onFail });
	}

	signOut({ onSuccess, onFail }) {
		super._delete("signOut", { onSuccess, onFail });
	}

	refresh({ refreshToken, onSuccess, onFail }) {
		super._post(`refresh/${refreshToken}`, {}, { requiresToken: false, onSuccess, onFail });
	}

	resetPasswordEmail(email, { onSuccess, onFail }) {
		super._get(`resetPasswordMail/${email}`, {
			requiresToken: false,
			onSuccess,
			onFail,
		});
	}

	getUserProfile({ onSuccess, onFail }) {
		super._get("me", { onSuccess, onFail });
	}
	updateUserProfile(body, { onSuccess, onFail }) {
		super._put("me", body, { onSuccess, onFail });
	}
	changePassword(body, { onSuccess, onFail }) {
		super._post(`changePassword`, body, { onSuccess, onFail });
	}
}
