<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem" class="overflow-x-hidden rounded-xl">
			<v-row class="pa-3">
				<v-card-title class="font-weight-bold">
					{{ $t(currentItem.new ? "send_invitation" : "invitation") }}
				</v-card-title>
				<v-btn class="mt-4" absolute right text :disabled="discarding || saving" @click="closeAction">
					<v-icon class="icon-cross" size="16"></v-icon>
				</v-btn>
			</v-row>
			<v-divider />
			<v-card-text>
				<v-form ref="form" v-model="valid" class="mt-4">
					<v-select
						v-model="currentItem.type"
						:items="typeOptions"
						:label="$t('type')"
						outlined
						class="rounded-xl"
						:rules="rules.type"
						:disabled="hasInvitationCode || typeOptions.length < 2 || Boolean(currentItem.id)"
						:menu-props="{ offsetY: true }"
					/>
					<span class="ma-0">{{ $t("email") }}</span>
					<v-text-field
						v-model="currentItem.email"
						:disabled="hasInvitationCode || !currentItem.new"
						class="rounded-xl"
						:rules="rules.email"
					/>

					<v-checkbox v-model="hasInvitationCode" :label="$t('i_have_invitation_code')" class="mt-4"></v-checkbox>

					<v-text-field
						v-if="hasInvitationCode"
						v-model="invitationCode"
						:label="$t('invitation_code')"
						:rules="rules.invitationCode"
						class="rounded-xl mt-4"
					></v-text-field>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn
					v-if="!currentItem.new"
					:disabled="discarding || saving"
					:loading="discarding"
					class="text-capitalize mx-2 mb-1"
					text
					@click="discardAction"
				>
					{{ $t("discard") }}
				</v-btn>
				<v-spacer />

				<v-btn
					v-if="!currentItem.new"
					:disabled="discarding || saving"
					:loading="sending"
					class="text-capitalize mx-2 mb-1 rounded-lg"
					color="primary"
					@click="sendEmailAction"
				>
					{{ $t("send_again") }}
				</v-btn>

				<v-btn
					v-if="currentItem.new"
					:disabled="discarding || saving"
					:loading="saving"
					large
					class="mx-1 mb-4"
					color="accent"
					elevation="0"
					@click="saveAction"
				>
					{{ hasInvitationCode ? $t("accept_invitation") : $t("send") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin],
	props: ["value", "item", "isEmployee", "defaultType"],
	data() {
		return {
			currentItem: null,
			valid: null,
			typeOptions: [],
			saving: false,
			sending: false,
			discarding: false,
			hasInvitationCode: false,
			invitationCode: "",
			rules: {
				type: [
					(v) => {
						if (this.hasInvitationCode) return true;
						return Boolean(v) || this.$t("$validation.required", { item: this.$t("type") });
					},
				],
				email: [
					(v) => {
						if (this.hasInvitationCode) return true;
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (!/.+@.+\..+/.test(v)) return this.$t("$validation.invalid", { item: this.$t("email") });
						return true;
					},
				],
				invitationCode: [
					(v) => {
						if (!this.hasInvitationCode) return true;
						return (Boolean(v) && Boolean(v.trim())) || this.$t("$validation.required", { item: this.$t("code") });
					},
				],
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item)
				this.currentItem = Object.assign(
					{},
					{
						new: false,
						id: this.item.id,
						email: this.item.email,
						type: this.item.type,
					}
				);
			else
				this.currentItem = {
					email: "",
					type: this.defaultType || null,
					new: true,
				};
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
			this.hasInvitationCode = false;
			this.invitationCode = "";
		},
		fillTypeOptions() {
			this.typeOptions = [];
			if (this.isEmployee) {
				this.typeOptions.push({
					text: this.$t("$invitationType.employee"),
					value: "employee",
				});
			} else if (this.company) {
				this.typeOptions.push({
					text: this.$t("$invitationType.company_partner"),
					value: "company_partner",
				});
				this.typeOptions.push({
					text: this.$t("$invitationType.customs_partner"),
					value: "customs_partner",
				});
			} else {
				this.typeOptions.push({
					text: this.$t("$invitationType.company_partner"),
					value: "company_partner",
				});
			}
		},
		saveAction() {
			if (this.hasInvitationCode) {
				if (this.$refs.form.validate()) {
					this.saving = true;
					remote.invitations.accept(this.invitationCode.trim(), {
						onSuccess: (result) => {
							this.saving = false;
							this.$emit("saved", result);
							this.showSnackBar({
								message: this.$t("$message.invitation_accepted"),
							});
							this.closeAction();
						},
						onFail: () => (this.saving = false),
					});
				}
			} else {
				if (this.$refs.form.validate()) {
					this.saving = true;
					remote.invitations.create(
						{
							email: this.currentItem.email,
							type: this.currentItem.type,
						},
						{
							onSuccess: (result) => {
								this.saving = false;
								this.$emit("saved", result);
								this.showSnackBar({
									message: this.$t("$message.created", {
										item: this.$t("invitation"),
									}),
								});
							},
							onFail: () => (this.saving = false),
						}
					);
				}
			}
		},
		discardAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.discarding = true;
					remote.invitations.delete(this.currentItem.id, {
						onSuccess: (result) => {
							this.discarding = false;
							this.$emit("deleted", result);
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("invitation"),
								}),
							});
						},
						onFail: () => (this.discarding = false),
					});
				},
			});
		},
		sendEmailAction() {
			this.sending = true;
			remote.invitations.sendMail(this.currentItem.id, {
				onSuccess: () => {
					this.sending = false;
					this.$emit("emailSent");
					this.showSnackBar({
						message: this.$t("invitation_mail_sent", {
							email: this.currentItem.email,
						}),
					});
				},
				onFail: () => (this.sending = false),
			});
		},
		closeAction() {
			this.$emit("input", false);
		},
	},
	created() {
		this.fillTypeOptions();
	},
	mounted() {
		this.handleProps();
	},
};
</script>
