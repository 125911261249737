<template>
	<ext-dialog
		v-model="value"
		:title="$t('shipments_filter')"
		:primaryButtonText="$t('ok')"
		@primaryButtonAction="okButtonAction"
		:secondaryButtonText="$t('clear')"
		@secondaryButtonAction="clearButtonAction"
		@closed="cancelButtonAction"
	>
		<template v-slot:body>
			<v-select
				v-model="currentItem.role"
				:item-text="'text'"
				:item-value="'value'"
				:label="$t('my_role')"
				:items="roleOptions"
				clearable
			/>
			<v-select
				v-model="currentItem.accessibilities"
				:item-text="'text'"
				:item-value="'value'"
				:label="$t('status')"
				:items="accessibilityOptions"
				multiple
				chips
				small-chips
				deletable-chips
				clearable
			/>
			<v-text-field v-model="currentItem.bookingCode" :label="$t('booking_code')" clearable />
			<shipment-method-select v-model="currentItem.method" :prepend-icon="null" />
		</template>
	</ext-dialog>
</template>

<script>
import ExtDialog from '../../../partials/dialogs/ExtDialog.vue';
import ShipmentMethodSelect from "../../../controls/ShipmentMethodSelect";

export default {
	props: {
		value: Boolean,
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		ExtDialog,
		ShipmentMethodSelect,
	},
	data() {
		return {
			currentItem: {
				role: null,
				accessibilities: [],
				bookingCode: "",
				method: null,
			},
		};
	},
	computed: {
		roleOptions() {
			return [
				{
					text: this.$t("sender"),
					value: "sender",
				},
				{
					text: this.$t("receiver"),
					value: "receiver",
				},
			];
		},
		accessibilityOptions() {
			return [
				{
					text: this.$t("$accessibility.present"),
					value: "present",
				},
				{
					text: this.$t("$accessibility.archived"),
					value: "archived",
				},
			];
		},
	},
	watch: {
		value(val) {
			if (val) {
				const item = this.item || {};
				this.currentItem = {
					role: item.role || null,
					accessibilities: item.accessibilities || [],
					bookingCode: item.bookingCode || "",
					method: item.method || null,
				};
			} else {
				this.currentItem = {
					role: null,
					accessibilities: [],
					bookingCode: "",
					method: null,
				};
			}
		},
	},
	methods: {
		okButtonAction() {
			this.$emit("done", {
				role: this.currentItem.role,
				accessibilities: this.currentItem.accessibilities,
				bookingCode: this.currentItem.bookingCode,
				method: this.currentItem.method,
			});
			this.$emit("input", false);
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
		clearButtonAction() {
			this.currentItem = {
				role: null,
				accessibilities: [],
				bookingCode: "",
				method: null,
			};
			this.$emit("done", {
				role: null,
				accessibilities: [],
				bookingCode: "",
				method: null,
			});
			this.$emit("input", false);
		},
	},
};
</script>
