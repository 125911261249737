export default {
	$accessibility: {
		archived: "Diarsipkan",
		present: "Hadir",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Tersembunyi Dari Penerima",
			hidden_from_sender: "Tersembunyi Dari Pengirim",
			private: "Pribadi",
			shared_with_custom: "Dibagikan dengan Kustom",
			shared_with_everyone: "Dibagikan dengan Semua Orang",
			shared_with_receiver: "Dibagikan dengan Penerima",
			shared_with_sender: "Dibagikan dengan Pengirim",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB Penyimpanan",
		limitMBStorage: "{limit}MB Penyimpanan",
		limitUser: "{limit} Pengguna",
		limitUsers: "{limit} Pengguna",
		renewAtPricePerUnit: "Akan diperbarui dengan {currency}{price} per {unit}",
		unlimitedModule: "{module} Tak Terbatas",
		upToLimitModule: "Hingga {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURKEY",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sen!2str!4v1701805110461!5m2!1sen!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "Percepat bisnis Anda dengan Antrego. Kelola proses pengiriman Anda secara digital, aman, dan mudah.",
	},
	$country: {
		afghanistan: "Afghanistan",
		aland: "Åland",
		albania: "Albania",
		algeria: "Aljazair",
		american_samoa: "Samoa Amerika",
		andorra: "Andorra",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Antartika",
		antigua_and_barbuda: "Antigua dan Barbuda",
		argentina: "Argentina",
		armenia: "Armenia",
		aruba: "Aruba",
		australia: "Australia",
		austria: "Austria",
		azerbaijan: "Azerbaijan",
		bahamas: "Bahama",
		bahrain: "Bahrain",
		bangladesh: "Bangladesh",
		barbados: "Barbados",
		belarus: "Belarus",
		belgium: "Belgia",
		belize: "Belize",
		benin: "Benin",
		bermuda: "Bermuda",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius, dan Saba",
		bosnia_and_herzegovina: "Bosnia dan Herzegovina",
		botswana: "Botswana",
		bouvet_island: "Pulau Bouvet",
		brazil: "Brasil",
		british_indian_ocean_territory: "Wilayah Samudra Hindia Britania",
		british_virgin_islands: "Kepulauan Virgin Britania Raya",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Kabo Verde",
		cambodia: "Kamboja",
		cameroon: "Kamerun",
		canada: "Kanada",
		cayman_islands: "Kepulauan Cayman",
		central_african_republic: "Republik Afrika Tengah",
		chad: "Chad",
		chile: "Chili",
		china: "Cina",
		christmas_island: "Pulau Christmas",
		cocos_islands: "Kepulauan Cocos [Keeling]",
		colombia: "Kolombia",
		comoros: "Komoro",
		congo_republic: "Republik Kongo",
		cook_islands: "Kepulauan Cook",
		costa_rica: "Kosta Rika",
		croatia: "Kroasia",
		cuba: "Kuba",
		curacao: "Curaçao",
		cyprus: "Siprus",
		czechia: "Ceko",
		denmark: "Denmark",
		djibouti: "Djibouti",
		dominica: "Dominika",
		dominican_republic: "Republik Dominika",
		dr_congo: "Republik Demokratik Kongo",
		ecuador: "Ekuador",
		egypt: "Mesir",
		el_salvador: "El Salvador",
		equatorial_guinea: "Guinea Khatulistiwa",
		eritrea: "Eritrea",
		estonia: "Estonia",
		eswatini: "Eswatini",
		ethiopia: "Ethiopia",
		falkland_islands: "Kepulauan Falkland",
		faroe_islands: "Kepulauan Faroe",
		fiji: "Fiji",
		finland: "Finlandia",
		france: "Prancis",
		french_guiana: "Guyana Prancis",
		french_polynesia: "Polinesia Prancis",
		french_southern_territories: "Wilayah Selatan Prancis",
		gabon: "Gabon",
		gambia: "Gambia",
		georgia: "Georgia",
		germany: "Jerman",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greece: "Yunani",
		greenland: "Greenland",
		grenada: "Grenada",
		guadeloupe: "Guadeloupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Guinea",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		haiti: "Haiti",
		heard_island_and_mcdonald_islands: "Pulau Heard dan Kepulauan McDonald",
		honduras: "Honduras",
		hong_kong: "Hong Kong",
		hungary: "Hungaria",
		iceland: "Islandia",
		india: "India",
		indonesia: "Indonesia",
		iran: "Iran",
		iraq: "Irak",
		ireland: "Irlandia",
		isle_of_man: "Pulau Man",
		israel: "Israel",
		italy: "Italia",
		ivory_coast: "Pantai Gading",
		jamaica: "Jamaika",
		japan: "Jepang",
		jersey: "Jersey",
		jordan: "Yordania",
		kazakhstan: "Kazakhstan",
		kenya: "Kenya",
		kiribati: "Kiribati",
		kosovo: "Kosovo",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgizstan",
		laos: "Laos",
		latvia: "Latvia",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		liechtenstein: "Liechtenstein",
		lithuania: "Lituania",
		luxembourg: "Luksemburg",
		macao: "Makau",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		malaysia: "Malaysia",
		maldives: "Maladewa",
		mali: "Mali",
		malta: "Malta",
		marshall_islands: "Kepulauan Marshall",
		martinique: "Martinik",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mayotte: "Mayotte",
		mexico: "Meksiko",
		micronesia: "Mikronesia",
		moldova: "Moldova",
		monaco: "Monako",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		montserrat: "Montserrat",
		morocco: "Maroko",
		mozambique: "Mozambik",
		myanmar: "Myanmar",
		namibia: "Namibia",
		nauru: "Nauru",
		nepal: "Nepal",
		netherlands: "Belanda",
		new_caledonia: "Kaledonia Baru",
		new_zealand: "Selandia Baru",
		nicaragua: "Nikaragua",
		niger: "Niger",
		nigeria: "Nigeria",
		niue: "Niue",
		norfolk_island: "Pulau Norfolk",
		north_korea: "Korea Utara",
		north_macedonia: "Makedonia Utara",
		northern_mariana_islands: "Kepulauan Mariana Utara",
		norway: "Norwegia",
		oman: "Oman",
		pakistan: "Pakistan",
		palau: "Palau",
		palestine: "Palestina",
		panama: "Panama",
		papua_new_guinea: "Papua Nugini",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Filipina",
		pitcairn_islands: "Kepulauan Pitcairn",
		poland: "Polandia",
		portugal: "Portugal",
		puerto_rico: "Puerto Riko",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Rumania",
		russia: "Rusia",
		rwanda: "Rwanda",
		saint_barthelemy: "Saint Barthélemy",
		saint_helena: "Saint Helena",
		saint_lucia: "Saint Lucia",
		saint_martin: "Saint Martin",
		saint_pierre_and_miquelon: "Saint Pierre dan Miquelon",
		samoa: "Samoa",
		san_marino: "San Marino",
		sao_tome_and_principe: "São Tomé dan Príncipe",
		saudi_arabia: "Arab Saudi",
		senegal: "Senegal",
		serbia: "Serbia",
		seychelles: "Seychelles",
		sierra_leone: "Sierra Leone",
		singapore: "Singapura",
		sint_maarten: "Sint Maarten",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		solomon_islands: "Kepulauan Solomon",
		somalia: "Somalia",
		south_africa: "Afrika Selatan",
		south_georgia_and_south_sandwich_islands: "Georgia Selatan dan Kepulauan Sandwich Selatan",
		south_korea: "Korea Selatan",
		south_sudan: "Sudan Selatan",
		spain: "Spanyol",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "St Kitts dan Nevis",
		st_vincent_and_grenadines: "Saint Vincent dan Grenadines",
		sudan: "Sudan",
		suriname: "Suriname",
		svalbard_and_jan_mayen: "Svalbard dan Jan Mayen",
		sweden: "Swedia",
		switzerland: "Swiss",
		syria: "Suriah",
		taiwan: "Taiwan",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		timor_leste: "Timor-Leste",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad dan Tobago",
		tunisia: "Tunisia",
		turkiye: "Turkiye",
		turkmenistan: "Turkmenistan",
		turks_and_caicos_islands: "Kepulauan Turks dan Caicos",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ukraina",
		united_arab_emirates: "Uni Emirat Arab",
		united_kingdom: "Inggris Raya",
		united_states: "Amerika Serikat",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "Kepulauan Terluar AS",
		us_virgin_islands: "Kepulauan Virgin AS",
		uzbekistan: "Uzbekistan",
		vanuatu: "Vanuatu",
		vatican_city: "Kota Vatikan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		wallis_and_futuna: "Wallis dan Futuna",
		western_sahara: "Sahara Barat",
		yemen: "Yaman",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Lira Turki",
		us_dollar: "Dolar AS",
	},
	$employeeType: {
		admin: "Admin",
		default: "Default",
		exporter: "Eksportir",
		importer: "Importir",
	},
	$format: {
		date: "DD-MM-YYYY",
		date_time: "DD-MM-YYYY HH:mm:ss",
		date_time_without_second: "DD-MM-YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Buat perusahaan Anda di sini.",
			create_company_title: "Apakah Anda ingin menambahkan perusahaan Anda?",
			description: "Anda telah berhasil masuk. Sekarang Anda perlu bergabung dengan perusahaan atau membuat yang baru.",
			join_company_subtitle: "Masukkan kode Anda dan bergabung dengan perusahaan di sini.",
			join_company_title: "Apakah Anda ingin bergabung dengan perusahaan yang sudah ada?",
			title: "Selamat datang {name}",
		},
		$unauthorized: {
			description: "Kendalikan proses ekspor-impor Anda dengan Antrego",
			forget_password_subtitle: "Masukkan email Anda untuk mengatur ulang kata sandi.",
			sign_in_subtitle: "Masuk di sini",
			sign_in_title: "Apakah Anda sudah memiliki akun?",
			sign_up_subtitle: "Daftar di sini",
			sign_up_title: "Apakah Anda ingin membuat akun?",
			title: "Selamat Datang di Antrego",
		},
	},
	$invitationType: {
		company_partner: "Undangan Kerja Sama Komersial",
		customs_partner: "Undangan Mitra Bea Cukai",
		employee: "Undangan Karyawan",
	},
	$landing: {
		$benefits: {
			benefits_title: "Manfaat",
			business_process: "Mempercepat proses bisnis Anda.",
			control_title: "Kendalikan dengan Antrego.",
			highlight_word: "kendalikan",
			risk_of_error: "Mengurangi risiko kesalahan.",
			text: "Antrego adalah program pelacakan pengiriman yang membantu perusahaan pelayaran, pelanggan mereka, dan agen bea cukai melacak proses pengiriman melalui platform bersama.",
			time_and_cost_savings: "Menghemat waktu dan biaya.",
		},
		$content: {
			$text: {
				0: "Lacak semua pengiriman Anda di satu tempat, dan kendalikan keuangan Anda dengan pelaporan dinamis.",
				1: "Bagikan pembaruan waktu nyata Anda dengan pelanggan untuk meningkatkan kualitas layanan Anda.",
				2: "Lihat dan bagikan semua dokumen terkait pengiriman Anda dalam satu sistem.",
				3: "Kendalikan proses Anda dengan mudah dengan mengakses Antrego dari semua perangkat dan lokasi.",
				4: "Simpan semua data Anda menggunakan teknologi enkripsi SHA-256. Yakinlah tentang keamanan.",
				5: "Ikut serta dalam pelacakan bersama dengan semua pemangku kepentingan dalam proses bisnis. Kelola proses lebih efisien.",
			},
			$title: {
				0: "Manajemen Pengiriman",
				1: "Hubungan Pelanggan",
				2: "Manajemen Dokumen",
				3: "Akses Mudah",
				4: "Keamanan Data",
				5: "Pelacakan Bersama",
			},
		},
		$home: {
			text: "Pilih kami untuk pelacakan digital yang mulus dan kontrol bersama.",
			title: "Kelola Semua Proses Pengiriman Anda Secara Digital dengan Mudah",
		},
		$lifecycle: {
			headline: "Proses",
			info_button: "Dapatkan Informasi",
			lifecycle_texts: {
				0: "Unggah file tambahan untuk membuat faktur proforma Anda dan bagikan segera dengan pelanggan Anda.",
				1: "Masukkan detail pengiriman Anda dan tambahkan dokumen. Beri tahu pelanggan Anda dan kelola semua proses pengiriman bersama melalui satu saluran.",
				2: "Lacak situasi keuangan Anda terkait impor dan ekspor secara waktu nyata.",
			},
			lifecycle_titles: {
				0: "Masuk dan Buat Proforma Invoice",
				1: "Buat Pengiriman",
				2: "Lacak Kegiatan Keuangan",
			},
			productivity_title: "Tingkatkan produktivitas dan kurangi kompleksitas serta kesalahan dalam 3 langkah.",
			tracking_guide_title: "Panduan Pelacakan Mudah",
		},
		$logistics: {
			logistics_title: "Jenis Logistik yang Didukung",
			solutions_title: "Solusi yang tepat untuk setiap muatan",
			text: "Dengan Antrego, mitra digital Anda dalam semua jenis transportasi, kami berdiri di samping Anda untuk semua pergerakan global Anda.",
		},
		$process: {
			digital_future: "Jauhkan diri dari metode tradisional dan melangkah ke masa depan digital",
			digital_shipping: "Pelacakan Pengiriman Digital dengan Antrego",
			digital_shipping_texts: {
				0: "Faktur proforma untuk pesanan yang masuk dibuat dan dilacak dengan mudah melalui satu saluran.",
				1: "Faktur proforma dikirim, pemberitahuan langsung ke pelanggan, dan disetujui melalui Antrego.",
				2: "Proses dokumen dikelola melalui Antrego. Tidak hilang, tidak ada penundaan, dan potensi kesalahan diminimalkan.",
				3: "Pemasok, pelanggan, dan agen bea cukai dapat segera melihat detail pengiriman dan melacaknya bersama-sama.",
				4: "Laporan keuangan dihasilkan secara otomatis bulanan dan tahunan.",
			},
			question_title: "Bagaimana Antrego Akan Mengubah Hidup Anda?",
			tracking_method: "Digitalkan metode pelacakan Anda dan percepat proses Anda.",
			traditional_shipping: "Pelacakan Pengiriman Tradisional",
			traditional_shipping_texts: {
				0: "Pesanan dikumpulkan melalui email, pesan, atau telepon melalui saluran yang berbeda, menyebabkan kesulitan pelacakan internal.",
				1: "Faktur proforma dibuat menggunakan alat seperti Excel, dan proses persetujuannya ditangani secara manual melalui email, WhatsApp, atau telepon.",
				2: "Proses dokumen manual menyebabkan kesalahan dan penundaan, menyebabkan kerugian waktu dan finansial.",
				3: "Tidak ada platform bersama untuk pemasok, pelanggan, dan agen bea cukai untuk melacak detail pengiriman.",
				4: "Data keuangan diperiksa secara individu setelah setiap pengiriman, membuat pelaporan menjadi sulit.",
			},
		},
		$question: {
			$text: {
				0: "<p>Ini adalah program pelacakan pengiriman yang membantu perusahaan dan pelanggan mereka dengan pelacakan bersama, mencegah kerugian akibat keterlambatan dalam fase pelacakan.</p>",
				1: "<p>- Bagikan semua pembaruan waktu nyata Anda dengan pelanggan Anda. Tingkatkan kualitas layanan Anda.<br>\n      - Lihat dan bagikan semua dokumen terkait pengiriman Anda dalam satu sistem.<br>\n      - Lacak semua pengiriman Anda dari satu tempat. Kendalikan keuangan Anda dengan pelaporan dinamis.</p>",
				2: "<p>- Laut<br>\n      - Udara<br>\n      - Darat<br>\n      - Kereta</p>",
				3: "<p>Dengan layanannya, selalu tersedia untuk memudahkan proses pengiriman Anda.<br>\n      <strong>1. Akses Mudah</strong><br>\n      Dapat diakses dari semua perangkat dan lokasi melalui peramban web.<br>\n      <strong>2. Keamanan Data</strong><br>\n      Semua data disimpan menggunakan teknologi enkripsi SHA-256.<br>\n      <strong>3. Pelacakan Bersama</strong><br>\n      Ini memberikan peluang pelacakan kepada semua pemangku kepentingan dalam proses tersebut.</strong>",
				4: "<p>Ya, ini adalah aplikasi berbayar. Dengan mendigitalkan semua proses pengiriman Anda, kami ingin mempermudah pekerjaan Anda dan terus membuat pengembangan yang berguna bagi Anda. Untuk memastikan keberlanjutan dari perbaikan ini dan ruang server yang Anda gunakan, kami mengenakan biaya bulanan.</p>",
				5: "<p>Tidak. Pelanggan Anda tidak perlu membayar biaya apapun untuk melacak pengiriman yang Anda lakukan.</p>",
			},
			$title: {
				0: "Apa itu Antrego?",
				1: "Apa yang akan Antrego kontribusikan kepada perusahaan saya selama proses pengiriman?",
				2: "Jenis logistik apa yang didukung oleh Antrego?",
				3: "Mengapa saya harus menggunakan Antrego?",
				4: "Apakah Antrego adalah aplikasi berbayar?",
				5: "Apakah pelanggan saya perlu membayar untuk Antrego?",
			},
			save_percentage: "Hemat 16% Bulanan",
			text: "Dengan mudah mengakses semua informasi dengan Antrego, dan jangan ragu untuk menghubungi kami jika Anda memiliki pertanyaan lainnya.",
			title: "Cepat Pelajari Apapun yang Anda Ingin Ketahui",
		},
		cancel_anytime: "Batalkan kapan saja",
		customize_package:
			"Anda dapat membeli penyimpanan tambahan dan pengguna dan menyesuaikan paket Anda sesuai dengan kebutuhan Anda",
		free_features_1: "10 Pengiriman",
		free_features_2: "100 MB Penyimpanan",
		free_features_3: "Mitra Komersial Tak Terbatas",
		free_features_4: "1 Pengguna",
		free_plan: "Gratis",
		free_price: "$0",
		free_regeneration: "",
		free_title: "Bulanan",
		premium_annual_features_1: "20 Pengguna",
		premium_annual_features_2: "100 Mitra",
		premium_annual_features_3: "1000 Pengiriman",
		premium_annual_features_4: "1000 Faktur",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "Akan diperbarui setiap tahun seharga $999",
		premium_annual_title: "Tahunan",
		premium_monthly_features_1: "Pengiriman Tak Terbatas",
		premium_monthly_features_2: "Mitra Komersial Tak Terbatas",
		premium_monthly_features_3: "2GB Penyimpanan",
		premium_monthly_features_4: "3 Pengguna",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "Akan diperbarui setiap bulan seharga $99",
		premium_monthly_title: "Bulanan",
		premium_plan: "Paket Premium",
		premium_plus_annual_features_1: "Pengiriman Tak Terbatas",
		premium_plus_annual_features_2: "Mitra Komersial Tak Terbatas",
		premium_plus_annual_features_3: "5GB Penyimpanan",
		premium_plus_annual_features_4: "5 Pengguna",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "Akan diperbarui setiap tahun seharga $999",
		premium_plus_annual_title: "Tahunan",
		premium_plus_monthly_features_1: "Pengiriman Tak Terbatas",
		premium_plus_monthly_features_2: "Mitra Komersial Tak Terbatas",
		premium_plus_monthly_features_3: "5GB Penyimpanan",
		premium_plus_monthly_features_4: "5 Pengguna",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "Akan diperbarui setiap bulan seharga $249",
		premium_plus_monthly_title: "Bulanan",
		premium_plus_plan: "Premium Plus",
		pricing_text: "Kami dapat memenuhi kebutuhan Anda dengan cara terbaik tanpa melebihi anggaran Anda.",
		pricing_title: "Antrego menjamin untuk menawarkan paket dan harga terbaik untuk Anda.",
		save_percentage: "Hemat 16% Bulanan",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Arab",
		de: "Jerman",
		en: "Inggris",
		es: "Spanyol",
		fr: "Prancis",
		hi: "Hindi",
		ind: "Bahasa Indonesia",
		it: "Italia",
		ja: "Jepang",
		ko: "Korea",
		pt: "Portugis",
		ru: "Rusia",
		tr: "Turki",
		vi: "Vietnam",
		zh: "Tiongkok",
	},
	$message: {
		cannot_delete_verified_partner: "Anda tidak dapat menghapus mitra yang terverifikasi.",
		confirm: "Apakah Anda yakin untuk {function}?",
		created: "{item} dibuat.",
		deleted: "{item} dihapus.",
		error_on_view_file: "Terjadi kesalahan saat melihat file.",
		file_oversize: "Ukuran file harus maksimum {size}.",
		invalid: "{item} tidak valid",
		migrated: "{item} dimigrasi. ({value})",
		process_has_been_started: "Proses {item} Anda telah dimulai.",
		sent: "{item} dikirim.",
		sent_email: "Email terkirim. ({value})",
		sign_in_failed: "Email atau kata sandi salah",
		unsupported_file_type: "Jenis file tidak didukung",
		updated: "{item} diperbarui.",
	},
	$pageTitles: {
		accept_invitation: "Terima Undangan",
		company_profile: "Profil Perusahaan",
		home: "Beranda",
		landing_page: "Pelacakan Digital Lancar, Kontrol Bersama",
		partners: "Mitra",
		product_groups: "Grup Produk",
		products: "Produk",
		proforma_invoices: "Faktur Proforma",
		shipment_details: "Rincian Pengiriman",
		shipments: "Pengiriman",
		sign_in: "Masuk",
		sign_up: "Daftar",
		subscription: "Langganan",
		user_profile: "Profil Pengguna",
	},
	$partnershipStatusTooltip: {
		description: "Anda dapat melacak status hubungan Anda dengan mitra Anda. Berikut perbedaannya:",
		verifiedPartnersTitle: "Mitra Terverifikasi:",
		verifiedPartnersDescription:
			"Mitra ini telah menerima undangan Anda dan mendaftar di platform. Informasi mereka hanya dapat dilihat dan tidak dapat diedit.",
		createdHereTitle: "Dibuat di sini:",
		createdHereDescription:
			"Bukan mitra terdaftar, Anda membuat profil mereka di platform kami, sehingga Anda dapat melihat dan mengedit informasi mereka.",
	},
	$productGroup: {
		add_child: "Tambahkan Anak",
		add_new_product_group: "Tambah grup produk baru",
		add_to_root: "Tambahkan ke Akar",
		has_children_fail_message: "Grup produk dengan anak tidak dapat dihapus",
		initial_message: "Gunakan tombol {0} ini untuk membuat grup produk pertama Anda.",
		name: "Nama",
		number_of_product: "Jumlah produk",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} disetujui.",
			create: "Dibuat oleh {name}.",
			create_attachment: "{name} membuat lampiran.",
			delete_attachment: "{name} menghapus lampiran.",
			demand_revise: "{name} menuntut revisi.",
			mark_as_processed: "{name} ditandai sebagai diproses.",
			reject: "{name} ditolak.",
			revoke: "{name} dicabut.",
			send: "{name} dikirim ke penerima untuk penilaian.",
			update: "Diperbarui oleh {name}.",
			update_attachment: "{name} memperbarui lampiran.",
		},
		$actionDescription2: {
			approve: '{name} ditandai sebagai "Disetujui".',
			demand_revise: '{name} ditandai sebagai "Menunggu Revisi".',
			mark_as_processed: '{name} ditandai sebagai "Diproses".',
			reject: "{name} ditolak.",
			revoke: '{name} ditandai sebagai "Dicabut".',
			send: '{name} ditandai sebagai "Terkirim".',
		},
		$actions: {
			approve: "Setujui",
			demand_revise: "Tuntut Revisi",
			mark_as_approved: "Disetujui",
			mark_as_processed: "Diproses",
			mark_as_rejected: "Ditolak",
			mark_as_revise_demanded: "Revisi Dituntut",
			mark_as_revoked: "Dicabut",
			mark_as_sent: "Terkirim",
			reject: "Tolak",
			revoke: "Cabut",
			send: "Kirim",
		},
		$status: {
			approved: "Disetujui",
			pending_approval: "Menunggu Persetujuan",
			pending_revise: "Menunggu Revisi",
			processed: "Diproses",
			rejected: "Ditolak",
			revoked: "Dicabut",
			template: "Template",
		},
		approve_confirm_message: "Apakah Anda yakin untuk menyetujui faktur proforma?",
		approve_success_message: "Faktur proforma telah disetujui.",
		demand_revise_description_message: "Silakan berikan beberapa detail tentang harapan revisi Anda:",
		demand_revise_success_message: "Faktur proforma telah dikirim kembali ke pengirim untuk direvisi.",
		mark_as_processed_confirm_message: "Apakah Anda mengonfirmasi untuk menandai faktur proforma sebagai diproses?",
		mark_as_processed_success_message: "Faktur proforma telah ditandai sebagai diproses.",
		no_attachment_text: "Belum ada lampiran yang ditambahkan.",
		reject_confirm_message: "Apakah Anda yakin untuk menolak faktur proforma?",
		reject_success_message: "Faktur proforma telah ditolak.",
		revoke_confirm_message: "Apakah Anda yakin untuk mencabut faktur proforma?",
		revoke_success_message: "Faktur proforma telah dicabut.",
		send_confirm_message: "Apakah Anda mengonfirmasi untuk mengirim faktur proforma ke penerima?",
		send_success_message: "Faktur proforma telah dikirim.",
	},
	$serviceMessage: {
		already_subs_module: "Sudah ada langganan untuk modul ini.",
		bad_request: "Permintaan Buruk",
		code_already_used: "Kode sudah digunakan.",
		container_status_not_available: "Status kontainer tidak tersedia.",
		existing_email_warning: "Email sudah ada.",
		existing_employee_error: "Pengguna ini sudah ditambahkan ke perusahaan Anda.",
		existing_employee_for_email_warning: "Kode undangan sudah dikirim ke email ini",
		existing_invitation_warning: "Kode undangan sudah dikirim ke email ini.",
		existing_token_already_have_time: "Token yang ada sudah memiliki cukup waktu untuk digunakan.",
		existing_user: "Pengguna sudah ada.",
		five_min_reset_password_mail:
			"Anda harus menunggu setidaknya 5 menit setelah Anda meminta email reset kata sandi sekali.",
		forbidden: "Terlarang",
		have_no_corporation: "Anda tidak memiliki korporasi",
		have_no_custom_agency: "Anda tidak memiliki agen bea cukai",
		have_no_employee: "Anda tidak memiliki karyawan",
		instance_can_no_delete_existing_booking_code: "Instance tidak dapat lagi dihapus karena kode pemesanan yang ada.",
		instance_cannot_delete: "Instance tidak dapat lagi dihapus.",
		instance_not_found: "Instance tidak ditemukan.",
		internal_server_error: "Kesalahan Server Internal",
		invalid_current_password: "Kata sandi saat ini tidak valid.",
		invalid_identifier_or_password: "Pengidentifikasi atau kata sandi tidak valid",
		invalid_invitation_code: "Kode undangan salah.",
		invalid_moving_request: "Permintaan pemindahan tidak valid",
		invalid_operation: "Operasi tidak valid",
		invalid_refresh_token: "Token pembaruan tidak valid.",
		invalid_unit: "Unit tidak valid",
		invitation_accepting_is_due_to_the_admin: "Penerimaan undangan tergantung pada admin.",
		invitation_already_discarded: "Undangan sudah dibuang.",
		invitation_already_used: "Undangan sudah digunakan.",
		invitation_has_been_used: "Undangan telah digunakan.",
		invitation_not_exist_email: "Undangan tidak ada alamat email.",
		invitation_not_for_company: "Undangan ini bukan untuk perusahaan.",
		invitation_not_for_custom_agency: "Undangan ini bukan untuk agen bea cukai.",
		invitation_not_found: "Undangan tidak ditemukan.",
		invitation_not_valid: "Undangan tidak lagi berlaku.",
		link_has_been_expired: "Tautan telah kedaluwarsa.",
		link_not_be_used_anymore: "Tautan ini mungkin tidak dapat digunakan lagi",
		missing_required_fields: "Bidang yang diperlukan hilang.",
		no_employee_found: "Tidak ada karyawan ditemukan",
		no_proper_partner_found: "Tidak ada mitra yang tepat ditemukan.",
		not_acceptable: "Tidak Dapat Diterima",
		not_allowed_action: "Anda tidak diizinkan untuk tindakan ini.",
		not_allowed_use_api: "Anda tidak diizinkan menggunakan api ini.",
		not_found: "Tidak Ditemukan",
		not_have_purchase_subscription:
			"Paket langganan Anda telah mencapai batas maksimum untuk definisi. Untuk menambahkan lebih banyak, silakan pertimbangkan untuk meningkatkan paket langganan Anda.",
		not_permitted_use_api: "Anda tidak diizinkan menggunakan api ini.",
		own_invitation_cannot_accept: "Anda tidak dapat menerima undangan Anda sendiri.",
		parent_group_not_found: "Grup Induk tidak ditemukan",
		partnerId_required: "ID Mitra diperlukan.",
		partnership_already_exists: "Mitra sudah ditambahkan.",
		password_cant_involve_space_char: "Kata sandi tidak boleh mengandung karakter spasi.",
		password_min_chars_least: "Kata sandi harus memiliki setidaknya 6 karakter.",
		payment_required: "Harap berlangganan untuk proses ini.",
		paytr_integration_error: "Kesalahan integrasi PayTR",
		ports_are_required: "Pelabuhan diperlukan.",
		product_group_tree_not_found: "Pohon Grup Produk tidak ditemukan",
		proforma_invoice_action_not_available: "Status faktur proforma tidak tersedia untuk tindakan ini.",
		proforma_invoice_template_update: "Faktur proforma harus template untuk diperbarui.",
		shipment_email_not_exist: "Harap masukkan email yang valid.",
		shipment_method_is_required: "Metode pengiriman diperlukan.",
		shipping_term_is_required: "Ketentuan pengiriman diperlukan.",
		someone_else_uses_this_email: "Orang lain menggunakan alamat email ini.",
		someone_else_uses_this_username: "Orang lain menggunakan nama pengguna ini.",
		something_went_wrong: "Terjadi kesalahan",
		type_can_be_abstract_company: '"tipe" properti hanya bisa "abstract_company"',
		unauthorized: "Tidak Sah",
		undefined_result: "Hasil tidak terdefinisi",
		unexpected_error_occurred: "Terjadi kesalahan yang tidak terduga",
		unit_already_added: "Unit sudah ditambahkan.",
		user_already_employee: "Pengguna sudah menjadi karyawan.",
		user_already_exists: "Pengguna sudah ada di perusahaan.",
		user_does_not_exist: "Pengguna tidak ada.",
		user_not_found: "Pengguna tidak ditemukan.",
		username_min_chars_error: '"username" harus memiliki setidaknya 6 karakter.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} menambahkan kendaraan.",
				delete_container: "{name} menghapus kendaraan.",
				update_container: "{name} memperbarui kendaraan.",
			},
			create: "Dibuat oleh {name}.",
			create_attachment: "{name} menambahkan lampiran.",
			create_container: "{name} menambahkan kontainer.",
			delete_attachment: "{name} menghapus lampiran.",
			delete_container: "{name} menghapus kontainer.",
			update: "Diperbarui oleh {name}.",
			update_attachment: "{name} memperbarui lampiran.",
			update_container: "{name} memperbarui kontainer.",
		},
		$containerStatus: {
			arrived: "Tiba",
			off_load: "Dibongkar",
			on_load: "Sedang Dimuat",
			on_way: "Dalam Perjalanan",
			reserved: "Dipesan",
		},
		$method: {
			aerial: "Udara",
			land: "Darat",
			railway: "Rel",
			sea: "Laut",
		},
		$vehicle: {
			license_number: "Nomor Plat",
		},
		archiving_success_message: "Pengiriman telah diarsipkan.",
		leave_message: "Tinggalkan pesan atau komentar Anda",
		no_booking_info: "Belum ada info pemesanan yang ditetapkan. Gunakan tombol {0} untuk menambahkannya.",
		no_transportation_info: "Belum ada info detail yang ditetapkan. Gunakan tombol {0} untuk menambahkannya.",
		un_archiving_success_message: "Pengiriman telah dibuka dari arsip.",
	},
	$shortening: {
		number: "No",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Langganan Anda tidak dapat diperbarui. Silakan periksa metode pembayaran Anda.",
			fail: "Terjadi kesalahan yang tidak terduga. Silakan coba lagi nanti. Jika masalah berlanjut, harap laporkan dengan mengirimkan email ke info@antrego.net.",
			ok: "Proses pembelian telah dimulai. Hasil dari proses tersebut akan diberitahukan melalui email. Langganan Anda akan dimulai ketika pembayaran berhasil diselesaikan.",
			payment_process_is_ongoing:
				"Proses pembayaran Anda sedang berlangsung. Hasil dari proses tersebut akan diberitahukan melalui email. Langganan Anda akan dimulai ketika pembayaran berhasil diselesaikan.",
			success: "Langganan Anda telah berhasil dibuat. Anda dapat mulai menggunakan Antrego dengan mudah.",
			unsubscribed: "Dibatalkan Langganan",
			you_dont_have_any_subscription:
				"Anda tidak memiliki langganan. Silakan pilih paket di halaman langganan dan mulai menggunakannya dengan mudah.",
			you_dont_have_any_subscription_text: "Pilih paket untuk melanjutkan.",
			you_dont_have_any_subscription_title: "Anda tidak memiliki langganan aktif.",
		},
	},
	$unit: {
		acres: "Hektar",
		boxes: "Kotak",
		cubic_meter: "Meter Kubik",
		dozen: "Lusin",
		feet: "Kaki",
		fluid_ounces: "Ons Cairan",
		gallons: "Galon",
		gram: "Gram",
		hectare: "Hektar",
		inches: "Inci",
		kilogram: "Kilogram",
		kilometer: "Kilometer",
		liter: "Liter",
		meter: "Meter",
		miles: "Mil",
		milligram: "Miligram",
		milliliter: "Mililiter",
		millimeter: "Milimeter",
		ounces: "Ons",
		pallets: "Pallet",
		pieces: "Potongan",
		pounds: "Pound",
		quarts: "Quart",
		square_feet: "Kaki Persegi",
		square_inches: "Inci Persegi",
		square_kilometer: "Kilometer Persegi",
		square_meter: "Meter Persegi",
		square_miles: "Mil Persegi",
		square_yards: "Yard Persegi",
		ton: "Ton",
		yards: "Yard",
	},
	$validation: {
		email: "Harap masukkan email yang valid",
		invalid: "{item} tidak valid",
		max_length: "{item} harus kurang dari {length} karakter",
		min_length: "{item} harus lebih dari {length} karakter",
		must_length: "{item} harus {length} karakter",
		only_number: "{item} harus berupa angka",
		password_repeat_warning: "Pengulangan kata sandi tidak sama dengan kata sandi baru.",
		required: "{item} diperlukan",
		required_for_default_language: "{item} diperlukan untuk bahasa default perusahaan.",
		you_must_approve_distance_sales_agreement: "Anda harus menyetujui Kontrak Penjualan Jarak Jauh",
	},
	about: "Tentang",
	accept_invitation: "Terima Undangan",
	accept_invitation_success_message: "Anda telah berhasil menggunakan undangan.",
	accessibility: "aksesibilitas",
	actions: "Tindakan",
	active: "Aktif",
	add: "Tambah",
	add_item: "Tambahkan {item}",
	add_new_employee: "Tambahkan karyawan baru",
	add_new_product: "Tambahkan produk baru",
	address: "Alamat",
	again: "Lagi",
	alert: "Peringatan",
	already_have_employee_message: "Anda sudah menjadi karyawan perusahaan.",
	amount: "Jumlah",
	approve: "Setujui",
	archive: "Arsipkan",
	attachment: "Lampiran",
	attachments: "Lampiran",
	bank: "Bank",
	benefits: "Manfaat",
	booking: "Pemesanan",
	booking_code: "Kode Pemesanan",
	business_partner: "Mitra Bisnis",
	business_partners: "Mitra Bisnis",
	cancel: "Batalkan",
	change_password: "Ubah Kata Sandi",
	checking_invitation_code_message: "Memeriksa kode undangan",
	choose_image: "Pilih gambar",
	choose_your_plan: "Pilih paket Anda",
	clear: "Bersihkan",
	click_to_play_the_video: "Klik untuk memutar video",
	close: "Tutup",
	code: "Kode",
	commercial: "Komersial",
	commercial_partner: "Mitra Komersial",
	commercial_partners: "Mitra Komersial",
	companies: "Perusahaan",
	company: "Perusahaan",
	company_name: "Nama perusahaan",
	company_profile: "Profil Perusahaan",
	configuration: "Konfigurasi",
	confirm: "Konfirmasi",
	confirm_distance_selling_agreement: "Konfirmasikan Kontrak Penjualan Jarak Jauh.",
	consult_us: "Konsultasikan Kami",
	contact: "Hubungi",
	contact_info: "Informasi Kontak",
	contact_text: "Yakinlah, kami akan segera menghubungi Anda.",
	contact_title: "Konsultasikan Kami, Sederhanakan Proses Anda",
	contact_to_us: "Hubungi Kami",
	container: "Kontainer",
	containers: "Kontainer",
	content: "Konten",
	contract: "Kontrak",
	countries: "Negara",
	country: "Negara",
	create: "Buat",
	create_a_new_account: "Buat Akun Baru",
	create_company: "Buat Perusahaan",
	created_at: "Dibuat Pada",
	created_here: "Dibuat di sini",
	credit_card_info: "Informasi Kartu Kredit",
	credit_card_no: "No. Kartu Kredit",
	currencies: "Mata Uang",
	currency: "Mata Uang",
	current_password: "Kata Sandi Saat Ini",
	current_plan: "Paket saat ini",
	customs_agencies: "Agen Bea Cukai",
	customs_agency: "Agen Bea Cukai",
	cvv: "CVV",
	daily: "Harian",
	dashboard: "Dasbor",
	date: "Tanggal",
	day: "Hari",
	default: "Default",
	default_language: "Bahasa Default",
	delete: "Hapus",
	delete_account: "Hapus akun",
	delete_confirm_message: "Apakah Anda mengonfirmasi penghapusan?",
	description: "Deskripsi",
	detail: "Detail",
	discard: "Buang",
	distance_sales_agreement: "Kontrak Penjualan Jarak Jauh",
	document_number: "No Dokumen",
	download: "Unduh",
	edit: "Edit",
	edit_item: "Edit {item}",
	edit_product: "Edit produk",
	edit_profile: "Edit profil",
	edit_your_profile_picture: "Edit foto profil Anda",
	email: "Email",
	employee: "Karyawan",
	employee_type: "Jenis Karyawan",
	employees: "Karyawan",
	estimated_arrival_day: "Hari Kedatangan Perkiraan",
	expense: "Pengeluaran",
	expenses: "Pengeluaran",
	expires_at: "Kedaluwarsa Pada",
	exported_containers: "Kontainer yang Diekspor",
	fail: "Gagal",
	failed: "Gagal",
	faq: "FAQ",
	feedback: "Masukan",
	file: "File",
	file_view: "Lihat File",
	filter: "Saring",
	first_name: "Nama Depan",
	first_name_last_name: "Nama Depan Nama Belakang",
	forget_password: "Lupa Kata Sandi",
	forget_password_message: "Apakah Anda lupa kata sandi?",
	forget_password_subtitle: "Silakan masukkan alamat email Anda untuk mengatur ulang kata sandi Anda.",
	free: "Gratis",
	from: "Dari",
	give_us_feedback: "Beri Kami Masukan",
	go_to_panel: "Pergi ke Panel",
	hide: "Sembunyikan",
	home_page: "Halaman Utama",
	i_have_invitation_code: "Saya memiliki kode undangan",
	imported_containers: "Kontainer yang Diimpor",
	in_progress: "Sedang Berlangsung",
	incoming: "Masuk",
	incoming_shipments: "Pengiriman Masuk",
	initial: "Awal",
	invitation: "Undangan",
	invitation_code: "Kode Undangan",
	invitation_mail_sent: "Email undangan terkirim",
	invitations: "Undangan",
	join: "Bergabung",
	join_company: "Bergabung dengan Perusahaan",
	language: "Bahasa",
	language_options: "Pilihan Bahasa",
	last_name: "Nama Belakang",
	last_one_year: "Tahun Terakhir",
	latitude: "Lintang",
	loading_country: "Negara Muatan",
	loading_point: "Titik Muatan",
	loading_port: "Pelabuhan Muatan",
	log_in: "masuk",
	logo: "Logo",
	longitude: "Bujur",
	mail_sent: "Email terkirim",
	manage_profile: "Kelola profil",
	message: "Pesan",
	message_company_create: "Perusahaan dibuat.",
	message_company_join: "Silakan masukkan kode undangan.",
	message_company_joined: "Anda bergabung dengan perusahaan",
	message_employee_delete: "Karyawan dihapus.",
	message_employee_save: "Karyawan disimpan.",
	message_join_company: "Masukkan kode Anda untuk bergabung dengan perusahaan yang ada",
	method: "Metode",
	migrate: "Migrasi",
	migrate_partner: "Migrasi Perusahaan",
	mobile_number: "Nomor Ponsel",
	month: "Bulan",
	month_lowercase: "bulan",
	monthly: "Bulanan",
	my_profile: "profil saya",
	my_role: "Peran Saya",
	name: "Nama",
	new: "Baru",
	new_company_package: "gratis",
	new_password: "Kata Sandi Baru",
	new_shipment: "Pengiriman Baru",
	next: "Berikutnya",
	no: "Tidak",
	no_content: "Tidak Ada Konten",
	no_entity: "Tidak ada entitas",
	no_entity_message: "Tidak ada entitas yang ditemukan.",
	no_text: "Tidak Ada Teks",
	not_found_page_message: "Maaf, saya tidak dapat menemukan halaman ini :(",
	note: "Catatan",
	ok: "OK",
	operation_fail: "Operasi Gagal",
	outgoing: "Keluar",
	outgoing_shipments: "Pengiriman Keluar",
	packages: "Paket",
	parent_group: "Grup Induk",
	partner: "Mitra",
	partners: "Mitra",
	partnership_status: "Status kemitraan",
	passive: "Pasif",
	password: "Kata Sandi",
	pay: "Bayar",
	payback: "Pengembalian",
	payback_confirm_message: "Apakah Anda mengonfirmasi pengembalian?",
	payback_warning_message: "Jika Anda melanjutkan, langganan Anda akan dihentikan.",
	payment: "Pembayaran",
	payment_amount: "Jumlah Pembayaran",
	payment_awaited: "Pembayaran Ditunggu",
	payment_history: "Riwayat Pembayaran",
	payment_success: "Pembayaran Berhasil",
	payment_summary: "Ringkasan Pembayaran",
	payment_will_be_made_3d_secure: "*Pembayaran Anda akan dilakukan dengan 3D Secure.",
	pending_invitations: "Undangan tertunda",
	per_month: "Per bulan",
	phone_number: "Nomor Telepon",
	premium: "Premium",
	premium_plus: "Premium Plus",
	previous: "Sebelumnya",
	pricing: "Harga",
	process: "Proses",
	product: "Produk",
	product_group: "Grup Produk",
	product_group_name: "Nama grup produk",
	product_groups: "Grup Produk",
	product_name: "Nama produk",
	products: "Produk",
	profile: "Profil",
	proforma_invoice: "Faktur Proforma",
	proforma_invoices: "Faktur Proforma",
	proforma_number: "No Faktur Proforma",
	purchase: "Pembelian",
	quantity: "Jumlah",
	receiver: "Penerima",
	receiver_company: "Perusahaan Penerima",
	receiver_customs_agency: "Agen Bea Cukai Penerima",
	redirection_to_home_link: "Beranda",
	redirection_to_home_message: "Anda juga bisa pergi ke ",
	redirection_to_sign_in_message: "Sudah punya akun?",
	redirection_to_sign_up_message: "Belum punya akun?",
	reload: "Muat Ulang",
	renewal: "Pembaruan",
	repaid: "Dibayar Kembali",
	reset_password: "Reset Kata Sandi",
	rows_per_page: "Baris per Halaman",
	sales: "Penjualan",
	save: "Simpan",
	save_changes: "Simpan perubahan",
	saved_card_info: "Informasi Kartu yang Disimpan",
	search: "Cari",
	select: "Pilih",
	select_file: "Pilih File",
	select_file_place_holder: "Harap pilih gambar",
	select_language: "Pilih bahasa",
	selected_partner: "Mitra Terpilih",
	send: "Kirim",
	send_again: "Kirim Ulang",
	send_email: "Kirim Email",
	send_feedback: "Kirim Masukan",
	send_invitation: "Kirim Undangan",
	sender: "Pengirim",
	sender_company: "Perusahaan Pengirim",
	sender_customs_agency: "Agen Bea Cukai Pengirim",
	settings: "Pengaturan",
	shipment: "Pengiriman",
	shipment_method: "Metode Pengiriman",
	shipment_type: "Tipe Pengiriman",
	shipments: "Pengiriman",
	shipments_filter: "Filter Pengiriman",
	shipping_term: "Ketentuan Pengiriman",
	shipping_terms: "Ketentuan Pengiriman",
	show: "Tampilkan",
	sign_in: "Masuk",
	sign_out: "Keluar",
	sign_out_confirm_message: "Apakah Anda mengonfirmasi keluar?",
	sign_out_success_message: "Selamat Tinggal.",
	sign_up: "Daftar",
	social_media: "Media Sosial",
	social_media_account: "Akun Media Sosial",
	start_time: "Waktu Mulai",
	status: "Status",
	subscribe: "Berlangganan",
	subscription: "Langganan",
	subscription_info_message: "Anda dapat menikmati semua layanan tanpa batas selama periode langganan.",
	subscription_warning_message:
		"**Informasi kartu Anda akan disimpan dengan aman dan langganan Anda akan diperbarui secara teratur kecuali Anda membatalkannya.",
	success: "Sukses",
	super: "Super",
	supported_files_img: "File yang didukung: JPG, PNG.",
	switch_language: "Ganti Bahasa",
	switch_theme: "Ganti Tema",
	target: "Target",
	target_partner: "Mitra Target",
	tax_number: "Nomor Pajak",
	theme: "Tema",
	this_month: "Bulan Ini",
	this_year: "Tahun Ini",
	title: "Judul",
	to: "Ke",
	toDelete: "Hapus",
	total: "Total",
	total_amount: "Jumlah Total",
	transaction_no: "No Transaksi",
	transactions: "Transaksi",
	try_for_free: "coba gratis",
	type: "Tipe",
	types: "Tipe",
	un_archive: "Buka Arsip",
	unit: "Unit",
	units: "Unit",
	unloading_country: "Negara Pemuatan",
	unloading_point: "Titik Pemuatan",
	unloading_port: "Pelabuhan Pemuatan",
	unsubscribe: "Batalkan Langganan",
	unsubscribe_confirm_message:
		"Jika Anda berhenti berlangganan, Anda tidak akan dapat menggunakan fitur sistem secara efektif. Apakah Anda yakin ingin berhenti berlangganan?",
	upload_new_image: "Unggah gambar baru",
	use_code: "Gunakan Kode",
	use_invitation_code: "Gunakan Kode Undangan",
	use_invitation_code_description: "Ketik kode undangan Anda yang dikirim ke kotak surat Anda.",
	use_invitation_code_success_message: "Kode undangan telah digunakan dengan sukses.",
	user_profile: "Profil Saya",
	vehicle: "Kendaraan",
	vehicles: "Kendaraan",
	verified_partner: "Mitra Terverifikasi",
	verify_password: "Verifikasi kata sandi",
	visibility: "Visibilitas",
	warehouse: "Gudang",
	warehouses: "Gudang",
	website: "Situs Web",
	week: "Minggu",
	welcome: "Selamat Datang",
	what_we_are_doing: "Apa yang Kami Lakukan",
	year: "Tahun",
	year_lowercase: "tahun",
	yearly: "Tahunan",
	yes: "Ya",
	you_dont_have_any_active_subscription: "Anda tidak memiliki langganan aktif.",
};
