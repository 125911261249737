<template>
	<!-- <ext-dialog v-model="value" :title="$t('select_language')" @closed="closeAction">
		<template slot="body">
			<v-row dense>
				<v-col v-for="(lang, index) in languages" :key="index" cols="3">
					<v-btn
						@click="selectLanguage(lang.code)"
						:class="{ 'white--text accent': lang.code === selectedLanguage }"
						color="black"
						text
					>
						{{ lang.name }}
					</v-btn>
				</v-col>
			</v-row>
		</template>
	</ext-dialog> -->
	<v-dialog v-model="value" persistent max-width="680">
		<v-card>
			<v-card-title class="headline">{{ $t("select_language") }}</v-card-title>
			<v-card-text class="mt-2">
				<v-row dense>
					<v-col v-for="(lang, index) in languages" :key="index" cols="3">
						<v-btn
							@click="selectLanguage(lang.code)"
							:class="{ 'white--text accent': lang.code === selectedLanguage }"
							color="black"
							text
						>
							{{ lang.name }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="closeAction">{{ $t("close") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { getSetting, setSetting, Setting } from "../../../data/local/ls";
import ExtDialog from "./ExtDialog.vue";

export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
	},
	components: { ExtDialog },
	data() {
		return {
			selectedLanguage: "",
			languages: [
				{ code: "ar", name: "العربية" },
				{ code: "de", name: "Deutsch" },
				{ code: "en", name: "English" },
				{ code: "es", name: "Español" },
				{ code: "fr", name: "Français" },
				{ code: "hi", name: "हिन्दी" },
				{ code: "ind", name: "Bahasa Indonesia" },
				{ code: "it", name: "Italiano" },
				{ code: "ja", name: "日本語" },
				{ code: "ko", name: "한국어" },
				{ code: "pt", name: "Português" },
				{ code: "ru", name: "Русский" },
				{ code: "tr", name: "Türkçe" },
				{ code: "vi", name: "Tiếng Việt" },
				{ code: "zh", name: "中文" },
			],
		};
	},
	watch: {
		value(val) {
			if (!val) {
				this.$emit("closed");
			}
		},
	},
	methods: {
		selectLanguage(code) {
			this.selectedLanguage = code;
			const newLocale = this.selectedLanguage;
			setSetting(Setting.locale, newLocale);
			location.reload();
		},
		closeAction() {
			this.$emit("closed");
		},
	},
	created() {
		this.selectedLanguage = getSetting(Setting.locale);
	},
};
</script>
