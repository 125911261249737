<template>
	<v-card :dark="dark" elevation="1" :color="backgroundColor">
		<div v-if="title" class="font-subtitle-1 text-center font-weight-light pt-3">
			{{ title }}
		</div>
		<v-row v-if="data">
			<v-col cols="4" class="text-h4 text-center pb-0">
				{{ data.lastOneYearCount }}
			</v-col>
			<v-col cols="4" class="text-h4 text-center pb-0">
				{{ data.currentYearCount }}
			</v-col>
			<v-col cols="4" class="text-h4 text-center pb-0">
				{{ data.currentMonthCount }}
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" class="text-caption text-center pt-0">
				{{ $t("last_one_year") }}
			</v-col>
			<v-col cols="4" class="text-caption text-center pt-0">
				{{ $t("this_year") }}
			</v-col>
			<v-col cols="4" class="text-caption text-center pt-0">
				{{ $t("this_month") }}
			</v-col>
		</v-row>

		<vue-apex-charts
			v-if="showChart"
			height="120"
			:options="chart.options"
			:series="chart.series"
			style="border-radius: 4px"
		/>
	</v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
	props: {
		data: Object,
		title: String,
		dark: Boolean,
		backgroundColor: String,
		chartColor: String,
		chartGradientColors: Array,
	},
	components: { VueApexCharts },
	data() {
		return {
			showChart: false,
			chart: {
				series: [
					{
						name: this.$t("containers"),
						data: [],
					},
				],
				options: {
					chart: {
						type: "area",
						background: "transparent",
						sparkline: { enabled: true },
					},
					dataLabels: { enabled: false },
					theme: { mode: this.dark ? "dark" : "light" },
					stroke: { curve: "smooth", lineCap: "butt", width: 1.5 },
					// colors: this.chartColor ? [this.chartColor] : undefined,
					colors: ["#005D8F"],
					fill: {
						colors: this.chartGradientColors,
						opacity: 0.3,
						type: "gradient",
					},
					xaxis: {
						categories: [],
						// crosshairs: { width: 0.5 },
					},
					yaxis: {
						min: 0,
						max: undefined,
					},
					tooltip: {
						enabled: true,
						x: {
							formatter: function (value, data) {
								return new moment.utc(data.w.config.series[data.seriesIndex].data[data.dataPointIndex].x).format(
									"MMMM"
								);
							},
						},
					},
				},
			},
		};
	},
	watch: {
		data(newValue) {
			this.handleIncomingData();
		},
	},
	created() {
		this.handleIncomingData();
	},
	methods: {
		handleIncomingData() {
			if (this.data) {
				this.chart.series[0].data = [];
				let maxY = 0;
				for (const key in this.data.monthBasedCounts) {
					let val = this.data.monthBasedCounts[key];
					this.chart.series[0].data.push({
						x: key,
						y: val,
					});
					if (val * 1.1 > maxY) maxY = val * 1.1;
				}
				this.chart.options.yaxis.max = maxY;
				this.showChart = true;
			}
		},
	},
};
</script>
