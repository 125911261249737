export default {
	$accessibility: {
		archived: "アーカイブ済み",
		present: "現在",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "受信者から非表示",
			hidden_from_sender: "送信者から非表示",
			private: "非公開",
			shared_with_custom: "カスタムと共有",
			shared_with_everyone: "全員と共有",
			shared_with_receiver: "受信者と共有",
			shared_with_sender: "送信者と共有",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB ストレージ",
		limitMBStorage: "{limit}MB ストレージ",
		limitUser: "{limit} ユーザー",
		limitUsers: "{limit} ユーザー",
		renewAtPricePerUnit: "{currency}{price} で {unit} 毎に更新されます",
		unlimitedModule: "無制限 {module}",
		upToLimitModule: "上限 {limit} {module}",
	},
	$company: {
		address:
			"トルコ、ムーラ、メンテシェ、コテクリ マハ デニズリ ヨル ブル テクノパーク サイティシ ビ ブロック No:4B, 14",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sjp!2str!4v1701805110461!5m2!1sjp!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "Antregoでビジネスを加速。出荷プロセスをデジタル、安全、簡単に管理します。",
	},
	$country: {
		afghanistan: "アフガニスタン",
		aland: "オーランド諸島",
		albania: "アルバニア",
		algeria: "アルジェリア",
		american_samoa: "アメリカ領サモア",
		andorra: "アンドラ",
		angola: "アンゴラ",
		anguilla: "アンギラ",
		antarctica: "南極",
		antigua_and_barbuda: "アンティグア・バーブーダ",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		aruba: "アルバ",
		australia: "オーストラリア",
		austria: "オーストリア",
		azerbaijan: "アゼルバイジャン",
		bahamas: "バハマ",
		bahrain: "バーレーン",
		bangladesh: "バングラデシュ",
		barbados: "バルバドス",
		belarus: "ベラルーシ",
		belgium: "ベルギー",
		belize: "ベリーズ",
		benin: "ベナン",
		bermuda: "バミューダ",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		bonaire_sint_eustatius_and_saba: "ボネール、シント・ユースタティウスおよびサバ",
		bosnia_and_herzegovina: "ボスニア・ヘルツェゴビナ",
		botswana: "ボツワナ",
		bouvet_island: "ブーベ島",
		brazil: "ブラジル",
		british_indian_ocean_territory: "英領インド洋地域",
		british_virgin_islands: "英領ヴァージン諸島",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		burkina_faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cabo_verde: "カーボベルデ",
		cambodia: "カンボジア",
		cameroon: "カメルーン",
		canada: "カナダ",
		cayman_islands: "ケイマン諸島",
		central_african_republic: "中央アフリカ共和国",
		chad: "チャド",
		chile: "チリ",
		china: "中国",
		christmas_island: "クリスマス島",
		cocos_islands: "ココス諸島",
		colombia: "コロンビア",
		comoros: "コモロ",
		congo_republic: "コンゴ共和国",
		cook_islands: "クック諸島",
		costa_rica: "コスタリカ",
		croatia: "クロアチア",
		cuba: "キューバ",
		curacao: "キュラソー",
		cyprus: "キプロス",
		czechia: "チェコ",
		denmark: "デンマーク",
		djibouti: "ジブチ",
		dominica: "ドミニカ",
		dominican_republic: "ドミニカ共和国",
		dr_congo: "コンゴ民主共和国",
		ecuador: "エクアドル",
		egypt: "エジプト",
		el_salvador: "エルサルバドル",
		equatorial_guinea: "赤道ギニア",
		eritrea: "エリトリア",
		estonia: "エストニア",
		eswatini: "エスワティニ",
		ethiopia: "エチオピア",
		falkland_islands: "フォークランド諸島",
		faroe_islands: "フェロー諸島",
		fiji: "フィジー",
		finland: "フィンランド",
		france: "フランス",
		french_guiana: "仏領ギアナ",
		french_polynesia: "仏領ポリネシア",
		french_southern_territories: "仏領南方地域",
		gabon: "ガボン",
		gambia: "ガンビア",
		georgia: "ジョージア",
		germany: "ドイツ",
		ghana: "ガーナ",
		gibraltar: "ジブラルタル",
		greece: "ギリシャ",
		greenland: "グリーンランド",
		grenada: "グレナダ",
		guadeloupe: "グアドループ",
		guam: "グアム",
		guatemala: "グアテマラ",
		guernsey: "ガーンジー",
		guinea: "ギニア",
		guinea_bissau: "ギニアビサウ",
		guyana: "ガイアナ",
		haiti: "ハイチ",
		heard_island_and_mcdonald_islands: "ハード島・マクドナルド諸島",
		honduras: "ホンジュラス",
		hong_kong: "香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		india: "インド",
		indonesia: "インドネシア",
		iran: "イラン",
		iraq: "イラク",
		ireland: "アイルランド",
		isle_of_man: "マン島",
		israel: "イスラエル",
		italy: "イタリア",
		ivory_coast: "コートジボワール",
		jamaica: "ジャマイカ",
		japan: "日本",
		jersey: "ジャージー",
		jordan: "ヨルダン",
		kazakhstan: "カザフスタン",
		kenya: "ケニア",
		kiribati: "キリバス",
		kosovo: "コソボ",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		liechtenstein: "リヒテンシュタイン",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao: "マカオ",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		malaysia: "マレーシア",
		maldives: "モルディブ",
		mali: "マリ",
		malta: "マルタ",
		marshall_islands: "マーシャル諸島",
		martinique: "マルティニーク",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mayotte: "マヨット",
		mexico: "メキシコ",
		micronesia: "ミクロネシア",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ",
		montserrat: "モントセラト",
		morocco: "モロッコ",
		mozambique: "モザンビーク",
		myanmar: "ミャンマー",
		namibia: "ナミビア",
		nauru: "ナウル",
		nepal: "ネパール",
		netherlands: "オランダ",
		new_caledonia: "ニューカレドニア",
		new_zealand: "ニュージーランド",
		nicaragua: "ニカラグア",
		niger: "ニジェール",
		nigeria: "ナイジェリア",
		niue: "ニウエ",
		norfolk_island: "ノーフォーク島",
		north_korea: "北朝鮮",
		north_macedonia: "北マケドニア",
		northern_mariana_islands: "北マリアナ諸島",
		norway: "ノルウェー",
		oman: "オマーン",
		pakistan: "パキスタン",
		palau: "パラオ",
		palestine: "パレスチナ",
		panama: "パナマ",
		papua_new_guinea: "パプアニューギニア",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		pitcairn_islands: "ピトケアン諸島",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_rico: "プエルトリコ",
		qatar: "カタール",
		reunion: "レユニオン",
		romania: "ルーマニア",
		russia: "ロシア",
		rwanda: "ルワンダ",
		saint_barthelemy: "サン・バルテルミー",
		saint_helena: "セントヘレナ",
		saint_lucia: "セントルシア",
		saint_martin: "サン・マルタン",
		saint_pierre_and_miquelon: "サンピエール島・ミクロン島",
		samoa: "サモア",
		san_marino: "サンマリノ",
		sao_tome_and_principe: "サントメ・プリンシペ",
		saudi_arabia: "サウジアラビア",
		senegal: "セネガル",
		serbia: "セルビア",
		seychelles: "セーシェル",
		sierra_leone: "シエラレオネ",
		singapore: "シンガポール",
		sint_maarten: "シント・マールテン",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		solomon_islands: "ソロモン諸島",
		somalia: "ソマリア",
		south_africa: "南アフリカ",
		south_georgia_and_south_sandwich_islands: "南ジョージア・南サンドウィッチ諸島",
		south_korea: "韓国",
		south_sudan: "南スーダン",
		spain: "スペイン",
		sri_lanka: "スリランカ",
		st_kitts_and_nevis: "セントクリストファー・ネーヴィス",
		st_vincent_and_grenadines: "セントビンセント・グレナディーン",
		sudan: "スーダン",
		suriname: "スリナム",
		svalbard_and_jan_mayen: "スバールバル諸島およびヤンマイエン島",
		sweden: "スウェーデン",
		switzerland: "スイス",
		syria: "シリア",
		taiwan: "台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		timor_leste: "東ティモール",
		togo: "トーゴ",
		tokelau: "トケラウ",
		tonga: "トンガ",
		trinidad_and_tobago: "トリニダード・トバゴ",
		tunisia: "チュニジア",
		turkiye: "トルコ",
		turkmenistan: "トルクメニスタン",
		turks_and_caicos_islands: "タークス・カイコス諸島",
		tuvalu: "ツバル",
		uganda: "ウガンダ",
		ukraine: "ウクライナ",
		united_arab_emirates: "アラブ首長国連邦",
		united_kingdom: "イギリス",
		united_states: "アメリカ合衆国",
		uruguay: "ウルグアイ",
		us_minor_outlying_islands: "米領小離島",
		us_virgin_islands: "米領ヴァージン諸島",
		uzbekistan: "ウズベキスタン",
		vanuatu: "バヌアツ",
		vatican_city: "バチカン市国",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		wallis_and_futuna: "ウォリス・フツナ",
		western_sahara: "西サハラ",
		yemen: "イエメン",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
	},
	$currency: {
		euro: "ユーロ",
		turkish_lira: "トルコリラ",
		us_dollar: "米ドル",
	},
	$employeeType: {
		admin: "管理者",
		default: "デフォルト",
		exporter: "輸出者",
		importer: "輸入者",
	},
	$format: {
		date: "YYYY/MM/DD",
		date_time: "YYYY/MM/DD HH:mm:ss",
		date_time_without_second: "YYYY/MM/DD HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "ここで会社を作成してください。",
			create_company_title: "会社を追加しますか？",
			description: "サインインに成功しました。次に、会社に参加するか、新しい会社を作成する必要があります。",
			join_company_subtitle: "コードを入力し、会社に参加してください。",
			join_company_title: "既存の会社に参加しますか？",
			title: "ようこそ {name}",
		},
		$unauthorized: {
			description: "Antregoで輸出入プロセスをコントロールしましょう",
			forget_password_subtitle: "パスワードをリセットするには、メールアドレスを入力してください。",
			sign_in_subtitle: "こちらからサインイン",
			sign_in_title: "すでにアカウントをお持ちですか？",
			sign_up_subtitle: "こちらからサインアップ",
			sign_up_title: "アカウントを作成しますか？",
			title: "Antregoへようこそ",
		},
	},
	$invitationType: {
		company_partner: "商業協力の招待",
		customs_partner: "税関パートナーの招待",
		employee: "従業員招待",
	},
	$landing: {
		$benefits: {
			benefits_title: "利点",
			business_process: "ビジネスプロセスの迅速化",
			control_title: "Antregoでコントロールを取り戻す",
			highlight_word: "コントロール",
			risk_of_error: "エラーのリスクを減少",
			text: "Antregoは、出荷会社、その顧客、および税関ブローカーが出荷プロセスを共有プラットフォームを通じて追跡するのを助ける出荷追跡プログラムです。",
			time_and_cost_savings: "時間とコストの節約",
		},
		$content: {
			$text: {
				0: "すべての出荷を1か所で追跡し、動的なレポートで財務を管理します。",
				1: "顧客にリアルタイムで更新を共有し、サービスの質を向上させます。",
				2: "出荷に関連するすべての書類を1つのシステムで表示および共有できます。",
				3: "すべてのデバイスおよび場所からAntregoにアクセスし、プロセスを簡単に管理します。",
				4: "SHA-256暗号化技術を使用してすべてのデータを保存します。セキュリティは安心してください。",
				5: "ビジネスプロセスのすべての関係者と共同追跡を行い、プロセスをより効率的に管理します。",
			},
			$title: {
				0: "出荷管理",
				1: "顧客関係",
				2: "書類管理",
				3: "簡単なアクセス",
				4: "データセキュリティ",
				5: "共同追跡",
			},
		},
		$home: {
			text: "シームレスなデジタル追跡と共同管理のために私たちを選んでください。",
			title: "すべての配送プロセスをデジタルで簡単に管理",
		},
		$lifecycle: {
			headline: "プロセス",
			info_button: "情報を取得",
			lifecycle_texts: {
				0: "追加ファイルをアップロードして見積書を作成し、顧客とすぐに共有します。",
				1: "出荷の詳細を入力し、ドキュメントを追加します。顧客に通知し、すべての出荷プロセスを1つのチャネルで共同管理します。",
				2: "輸出入に関連する財務状況をリアルタイムで追跡します。",
			},
			lifecycle_titles: {
				0: "ログインして見積書を作成",
				1: "出荷を作成",
				2: "財務活動を追跡",
			},
			productivity_title: "3つのステップで生産性を向上させ、複雑さとエラーを削減",
			tracking_guide_title: "簡単な追跡ガイド",
		},
		$logistics: {
			logistics_title: "サポートされている物流タイプ",
			solutions_title: "すべての荷物に最適なソリューション",
			text: "Antregoと共に、すべての国際的な動きにおいて私たちはあなたの側にいます。",
		},
		$process: {
			digital_future: "従来の方法から脱却し、デジタルの未来へ一歩踏み出す",
			digital_shipping: "Antregoを使用したデジタル出荷追跡",
			digital_shipping_texts: {
				0: "受注の見積書は1つのチャネルで簡単に作成され、追跡されます。",
				1: "見積書が送信され、通知が顧客に即座に送信され、Antregoを通じて承認されます。",
				2: "ドキュメントプロセスはAntregoで管理され、紛失せず、遅延もなく、エラーの可能性が最小限に抑えられます。",
				3: "サプライヤー、顧客、および税関ブローカーは、出荷の詳細をすぐに確認し、共同で追跡できます。",
				4: "財務レポートは毎月および年間で自動的に生成されます。",
			},
			question_title: "Antregoはあなたの生活をどのように変えるでしょうか？",
			tracking_method: "追跡方法をデジタル化し、プロセスを加速",
			traditional_shipping: "従来の出荷追跡",
			traditional_shipping_texts: {
				0: "注文はメール、メッセージ、または電話で異なるチャネルを通じて収集され、内部追跡が困難になります。",
				1: "見積書はExcelなどのツールを使用して作成され、承認プロセスは手動でメール、WhatsApp、または電話で処理されます。",
				2: "手動のドキュメントプロセスはエラーと遅延を引き起こし、時間と財務の損失を招きます。",
				3: "サプライヤー、顧客、税関ブローカーが出荷の詳細を追跡するための共通プラットフォームがありません。",
				4: "財務データは各出荷後に個別に確認され、レポートが難しくなります。",
			},
		},
		$question: {
			$text: {
				0: "<p>Antregoは、企業とその顧客が共同で追跡し、追跡段階での遅延による損失を防ぐための出荷追跡プログラムです。</p>",
				1: "<p>- 顧客とリアルタイムで更新を共有し、サービスの質を向上させます。<br>\n\t\t\t\t- 出荷に関するすべての書類を1つのシステムで表示および共有できます。<br>\n\t\t\t\t- すべての出荷を1つの場所から追跡し、動的なレポートで財務を管理します。</p>",
				2: "<p>- 海運<br>\n\t\t\t\t- 空輸<br>\n\t\t\t\t- 陸送<br>\n\t\t\t\t- 鉄道</p>",
				3: "<p>Antregoのサービスは、出荷プロセスを簡単にするためにいつでも利用可能です。<br>\n\t\t\t\t<strong>1. 簡単なアクセス</strong><br>\n\t\t\t\tウェブブラウザを使用してすべてのデバイスと場所からアクセスできます。<br>\n\t\t\t\t<strong>2. データセキュリティ</strong><br>\n\t\t\t\tSHA-256暗号化技術を使用してすべてのデータを保存します。<br>\n\t\t\t\t<strong>3. 共同追跡</strong><br>\n\t\t\t\tプロセスのすべての関係者に追跡の機会を提供します。</strong>",
				4: "<p>はい、それは有料のアプリケーションです。すべての出荷プロセスをデジタル化することで、私たちはあなたの仕事を簡単にし、あなたのために有用な開発を続けていきたいと考えています。これらの改善の持続可能性と使用するサーバー容量を確保するために、毎月の料金を請求します。</p>",
				5: "<p>いいえ。あなたの顧客は、あなたが行う出荷を追跡するために料金を支払う必要はありません。</p>",
			},
			$title: {
				0: "Antregoとは何ですか？",
				1: "Antregoは出荷プロセスで私の会社にどのような貢献をしますか？",
				2: "Antregoはどのような種類の物流をサポートしていますか？",
				3: "なぜAntregoを使用する必要があるのですか？",
				4: "Antregoは有料のアプリケーションですか？",
				5: "私の顧客はAntregoに料金を支払う必要がありますか？",
			},
			save_percentage: "月額16％節約",
			text: "Antregoで簡単にすべての情報にアクセスし、他の質問があればお気軽にお問い合わせください。",
			title: "あなたの疑問をすぐに解決",
		},
		cancel_anytime: "いつでもキャンセル可能",
		customize_package: "追加のストレージやユーザーを購入し、ニーズに合わせてパッケージをカスタマイズできます",
		free_features_1: "10 出荷",
		free_features_2: "100 MB ストレージ",
		free_features_3: "無制限の商業パートナー",
		free_features_4: "1 ユーザー",
		free_plan: "無料",
		free_price: "¥0",
		free_regeneration: "",
		free_title: "月額",
		premium_annual_features_1: "20 ユーザー",
		premium_annual_features_2: "100 パートナー",
		premium_annual_features_3: "1000 出荷",
		premium_annual_features_4: "1000 請求書",
		premium_annual_price: "¥83.25",
		premium_annual_regeneration: "¥999で毎年更新されます",
		premium_annual_title: "年間",
		premium_monthly_features_1: "無制限の出荷",
		premium_monthly_features_2: "無制限の商業パートナー",
		premium_monthly_features_3: "2GB ストレージ",
		premium_monthly_features_4: "3 ユーザー",
		premium_monthly_price: "¥99",
		premium_monthly_regeneration: "¥99で毎月更新されます",
		premium_monthly_title: "月額",
		premium_plan: "プレミアムプラン",
		premium_plus_annual_features_1: "無制限の出荷",
		premium_plus_annual_features_2: "無制限の商業パートナー",
		premium_plus_annual_features_3: "5GB ストレージ",
		premium_plus_annual_features_4: "5 ユーザー",
		premium_plus_annual_price: "¥83.25",
		premium_plus_annual_regeneration: "¥999で毎年更新されます",
		premium_plus_annual_title: "年間",
		premium_plus_monthly_features_1: "無制限の出荷",
		premium_plus_monthly_features_2: "無制限の商業パートナー",
		premium_plus_monthly_features_3: "5GB ストレージ",
		premium_plus_monthly_features_4: "5 ユーザー",
		premium_plus_monthly_price: "¥249",
		premium_plus_monthly_regeneration: "¥249で毎月更新されます",
		premium_plus_monthly_title: "月額",
		premium_plus_plan: "プレミアムプラス",
		pricing_text: "私たちは、予算を崩すことなく、最適な方法であなたのニーズを満たすことができます。",
		pricing_title: "Antregoは、あなたに最適なパッケージと価格を提供することを保証します。",
		save_percentage: "月額16％節約",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "アラビア語",
		de: "ドイツ語",
		en: "英語",
		es: "スペイン語",
		fr: "フランス語",
		hi: "ヒンディー語",
		ind: "インドネシア語",
		it: "イタリア語",
		ja: "日本語",
		ko: "韓国語",
		pt: "ポルトガル語",
		ru: "ロシア語",
		tr: "トルコ語",
		vi: "ベトナム語",
		zh: "中国語",
	},
	$message: {
		cannot_delete_verified_partner: "確認済みのパートナーは削除できません。",
		confirm: "{function} を確認しますか？",
		created: "{item} を作成しました。",
		deleted: "{item} を削除しました。",
		error_on_view_file: "ファイルの表示中にエラーが発生しました。",
		file_oversize: "ファイルサイズは最大 {size} でなければなりません。",
		invalid: "無効な {item}",
		migrated: "{item} を移行しました。 ({value})",
		process_has_been_started: "あなたの {item} が開始されました。",
		sent: "{item} を送信しました。",
		sent_email: "メールを送信しました。 ({value})",
		sign_in_failed: "メールまたはパスワードが間違っています",
		unsupported_file_type: "サポートされていないファイルタイプ",
		updated: "{item} を更新しました。",
	},
	$pageTitles: {
		accept_invitation: "招待を受け入れる",
		company_profile: "会社のプロフィール",
		home: "ホーム",
		landing_page: "シームレスなデジタルトラッキング、共有コントロール",
		partners: "パートナー",
		product_groups: "製品グループ",
		products: "製品",
		proforma_invoices: "見積書",
		shipment_details: "出荷の詳細",
		shipments: "出荷",
		sign_in: "サインイン",
		sign_up: "サインアップ",
		subscription: "サブスクリプション",
		user_profile: "ユーザープロフィール",
	},
	$partnershipStatusTooltip: {
		description: "パートナーとの関係のステータスを追跡できます。違いは以下の通りです:",
		verifiedPartnersTitle: "確認済みパートナー:",
		verifiedPartnersDescription:
			"これらのパートナーはあなたの招待を受け入れ、プラットフォームに登録しました。情報は表示のみ可能で、編集はできません。",
		createdHereTitle: "ここで作成:",
		createdHereDescription:
			"登録されていないパートナー、あなたがこのプラットフォームでプロフィールを作成しました。彼らの情報を表示および編集することができます。",
	},
	$productGroup: {
		add_child: "子を追加",
		add_new_product_group: "新しい商品グループを追加",
		add_to_root: "ルートに追加",
		has_children_fail_message: "子供がいる製品グループは削除できません",
		initial_message: "この {0} ボタンを使用して最初の製品グループを作成します。",
		name: "名前",
		number_of_product: "商品の数",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} が承認しました。",
			create: "{name} が作成しました。",
			create_attachment: "{name} が添付ファイルを作成しました。",
			delete_attachment: "{name} が添付ファイルを削除しました。",
			demand_revise: "{name} が修正を要求しました。",
			mark_as_processed: "{name} が処理済みとマークしました。",
			reject: "{name} が拒否しました。",
			revoke: "{name} が取り消しました。",
			send: "{name} が受信者に送信しました。",
			update: "{name} が更新しました。",
			update_attachment: "{name} が添付ファイルを更新しました。",
		},
		$actionDescription2: {
			approve: '{name} が "承認済み" としてマークされました。',
			demand_revise: '{name} が "修正待ち" としてマークされました。',
			mark_as_processed: '{name} が "処理済み" としてマークされました。',
			reject: "{name} が拒否されました。",
			revoke: '{name} が "取り消し済み" としてマークされました。',
			send: '{name} が "送信済み" としてマークされました。',
		},
		$actions: {
			approve: "承認",
			demand_revise: "修正を要求",
			mark_as_approved: "承認済み",
			mark_as_processed: "処理済み",
			mark_as_rejected: "拒否済み",
			mark_as_revise_demanded: "修正要求済み",
			mark_as_revoked: "取り消し済み",
			mark_as_sent: "送信済み",
			reject: "拒否",
			revoke: "取り消す",
			send: "送信",
		},
		$status: {
			approved: "承認済み",
			pending_approval: "承認待ち",
			pending_revise: "修正待ち",
			processed: "処理済み",
			rejected: "拒否済み",
			revoked: "取り消し済み",
			template: "テンプレート",
		},
		approve_confirm_message: "見積書を承認しますか？",
		approve_success_message: "見積書が承認されました。",
		demand_revise_description_message: "修正の期待についての詳細を教えてください：",
		demand_revise_success_message: "見積書が送信者に修正のために返送されました。",
		mark_as_processed_confirm_message: "見積書を処理済みとマークしますか？",
		mark_as_processed_success_message: "見積書が処理済みとマークされました。",
		no_attachment_text: "添付ファイルはまだ追加されていません。",
		reject_confirm_message: "見積書を拒否しますか？",
		reject_success_message: "見積書が拒否されました。",
		revoke_confirm_message: "見積書を取り消しますか？",
		revoke_success_message: "見積書が取り消されました。",
		send_confirm_message: "見積書を受信者に送信しますか？",
		send_success_message: "見積書が送信されました。",
	},
	$serviceMessage: {
		already_subs_module: "このモジュールには既にサブスクリプションがあります。",
		bad_request: "不正なリクエスト",
		code_already_used: "コードはすでに使用されています。",
		container_status_not_available: "コンテナのステータスは利用できません。",
		existing_email_warning: "メールは既に存在します。",
		existing_employee_error: "このユーザーは既にあなたの会社に追加されています。",
		existing_employee_for_email_warning: "招待コードはすでにこのメールに送信されています",
		existing_invitation_warning: "招待コードはすでにこのメールに送信されています。",
		existing_token_already_have_time: "既存のトークンはすでに使用できる時間があります。",
		existing_user: "ユーザーは既に存在します。",
		five_min_reset_password_mail: "パスワードリセットメールをリクエストしてから少なくとも5分待つ必要があります。",
		forbidden: "禁止されています",
		have_no_corporation: "あなたには法人がありません",
		have_no_custom_agency: "あなたには税関代理店がありません",
		have_no_employee: "あなたには従業員がいません",
		instance_can_no_delete_existing_booking_code: "既存の予約コードがあるため、インスタンスを削除できません。",
		instance_cannot_delete: "インスタンスは削除できません。",
		instance_not_found: "インスタンスが見つかりません。",
		internal_server_error: "内部サーバーエラー",
		invalid_current_password: "無効な現在のパスワード。",
		invalid_identifier_or_password: "無効な識別子またはパスワード",
		invalid_invitation_code: "招待コードが間違っています。",
		invalid_moving_request: "無効な移動リクエスト",
		invalid_operation: "無効な操作",
		invalid_refresh_token: "無効なリフレッシュトークン。",
		invalid_unit: "無効な単位",
		invitation_accepting_is_due_to_the_admin: "招待の受け入れは管理者によります。",
		invitation_already_discarded: "招待はすでに破棄されています。",
		invitation_already_used: "招待はすでに使用されています。",
		invitation_has_been_used: "招待は使用されました。",
		invitation_not_exist_email: "招待は存在しないメールアドレスです。",
		invitation_not_for_company: "この招待は会社用ではありません。",
		invitation_not_for_custom_agency: "この招待は税関代理店用ではありません。",
		invitation_not_found: "招待が見つかりません。",
		invitation_not_valid: "招待はもう有効ではありません。",
		link_has_been_expired: "リンクが期限切れになりました。",
		link_not_be_used_anymore: "このリンクはもう使用できません",
		missing_required_fields: "必要なフィールドがありません。",
		no_employee_found: "従業員が見つかりません",
		no_proper_partner_found: "適切なパートナーが見つかりません。",
		not_acceptable: "許容できません",
		not_allowed_action: "このアクションは許可されていません。",
		not_allowed_use_api: "このAPIを使用することは許可されていません。",
		not_found: "見つかりません",
		not_have_purchase_subscription:
			"あなたのサブスクリプションプランは定義の最大制限に達しました。さらに追加するには、サブスクリプションプランのアップグレードをご検討ください。",
		not_permitted_use_api: "このAPIを使用することは許可されていません。",
		own_invitation_cannot_accept: "自分の招待を受け入れることはできません。",
		parent_group_not_found: "親グループが見つかりません",
		partnerId_required: "パートナーIDが必要です。",
		partnership_already_exists: "パートナーは既に追加されています。",
		password_cant_involve_space_char: "パスワードにはスペースを含めることはできません。",
		password_min_chars_least: "パスワードは少なくとも6文字必要です。",
		payment_required: "このプロセスにはサブスクリプションが必要です。",
		paytr_integration_error: "PayTR統合エラー",
		ports_are_required: "ポートが必要です。",
		product_group_tree_not_found: "製品グループツリーが見つかりません",
		proforma_invoice_action_not_available: "見積書のステータスはこのアクションに利用できません。",
		proforma_invoice_template_update: "見積書はテンプレートでなければ更新できません。",
		shipment_email_not_exist: "有効なメールアドレスを入力してください。",
		shipment_method_is_required: "配送方法が必要です。",
		shipping_term_is_required: "配送条件が必要です。",
		someone_else_uses_this_email: "他の誰かがこのメールアドレスを使用しています。",
		someone_else_uses_this_username: "他の誰かがこのユーザー名を使用しています。",
		something_went_wrong: "問題が発生しました",
		type_can_be_abstract_company: '"type" プロパティは "abstract_company" のみ使用できます',
		unauthorized: "権限がありません",
		undefined_result: "未定義の結果",
		unexpected_error_occurred: "予期しないエラーが発生しました",
		unit_already_added: "ユニットはすでに追加されています。",
		user_already_employee: "ユーザーは既に従業員です。",
		user_already_exists: "ユーザーはすでに会社に存在します。",
		user_does_not_exist: "ユーザーが存在しません。",
		user_not_found: "ユーザーが見つかりません。",
		username_min_chars_error: '"ユーザー名" は少なくとも6文字必要です。',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} が車両を追加しました。",
				delete_container: "{name} が車両を削除しました。",
				update_container: "{name} が車両を更新しました。",
			},
			create: "{name} が作成しました。",
			create_attachment: "{name} が添付ファイルを追加しました。",
			create_container: "{name} がコンテナを追加しました。",
			delete_attachment: "{name} が添付ファイルを削除しました。",
			delete_container: "{name} がコンテナを削除しました。",
			update: "{name} が更新しました。",
			update_attachment: "{name} が添付ファイルを更新しました。",
			update_container: "{name} がコンテナを更新しました。",
		},
		$containerStatus: {
			arrived: "到着",
			off_load: "荷降ろし中",
			on_load: "積載中",
			on_way: "途中",
			reserved: "予約済み",
		},
		$method: {
			aerial: "航空",
			land: "陸上",
			railway: "鉄道",
			sea: "海運",
		},
		$vehicle: {
			license_number: "ナンバープレート",
		},
		archiving_success_message: "出荷がアーカイブされました。",
		leave_message: "メッセージまたはコメントを残してください",
		no_booking_info: "予約情報はまだ設定されていません。 {0} ボタンを使用して追加します。",
		no_transportation_info: "詳細情報はまだ設定されていません。 {0} ボタンを使用して設定します。",
		un_archiving_success_message: "出荷がアーカイブ解除されました。",
	},
	$shortening: {
		number: "番号",
	},
	$socialMedia: {
		facebook: "フェイスブック",
		instagram: "インスタグラム",
		linked_in: "リンクトイン",
		twitter: "ツイッター",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "サブスクリプションを更新できませんでした。お支払い方法を確認してください。",
			fail: "予期しないエラーが発生しました。後でもう一度お試しください。問題が解決しない場合は、info@antrego.net にメールを送信して報告してください。",
			ok: "購入プロセスが開始されました。プロセスの結果はメールで通知されます。支払いが正常に完了した時点でサブスクリプションが開始されます。",
			payment_process_is_ongoing:
				"支払いプロセスが進行中です。プロセスの結果はメールで通知されます。支払いが正常に完了した時点でサブスクリプションが開始されます。",
			success: "サブスクリプションが正常に作成されました。Antregoを簡単に使用し始めることができます。",
			unsubscribed: "退会しました",
			you_dont_have_any_subscription:
				"サブスクリプションがありません。サブスクリプションページでパッケージを選択し、簡単に利用を開始してください。",
			you_dont_have_any_subscription_text: "続行するにはプランを選択してください。",
			you_dont_have_any_subscription_title: "アクティブなサブスクリプションがありません。",
		},
	},
	$unit: {
		acres: "エーカー",
		boxes: "箱",
		cubic_meter: "立方メートル",
		dozen: "ダース",
		feet: "フィート",
		fluid_ounces: "液量オンス",
		gallons: "ガロン",
		gram: "グラム",
		hectare: "ヘクタール",
		inches: "インチ",
		kilogram: "キログラム",
		kilometer: "キロメートル",
		liter: "リットル",
		meter: "メートル",
		miles: "マイル",
		milligram: "ミリグラム",
		milliliter: "ミリリットル",
		millimeter: "ミリメートル",
		ounces: "オンス",
		pallets: "パレット",
		pieces: "個",
		pounds: "ポンド",
		quarts: "クォート",
		square_feet: "平方フィート",
		square_inches: "平方インチ",
		square_kilometer: "平方キロメートル",
		square_meter: "平方メートル",
		square_miles: "平方マイル",
		square_yards: "平方ヤード",
		ton: "トン",
		yards: "ヤード",
	},
	$validation: {
		email: "有効なメールアドレスを入力してください",
		invalid: "無効な {item}",
		max_length: "{item} は {length} 文字以下でなければなりません",
		min_length: "{item} は {length} 文字以上でなければなりません",
		must_length: "{item} は {length} 文字でなければなりません",
		only_number: "{item} は数字でなければなりません",
		password_repeat_warning: "パスワードの再入力が新しいパスワードと一致しません。",
		required: "{item} は必須です",
		required_for_default_language: "{item} は会社のデフォルト言語に対して必須です。",
		you_must_approve_distance_sales_agreement: "距離販売契約を承認する必要があります",
	},
	about: "約",
	accept_invitation: "招待を受け入れる",
	accept_invitation_success_message: "招待を正常に使用しました。",
	accessibility: "アクセシビリティ",
	actions: "アクション",
	active: "アクティブ",
	add: "追加",
	add_item: "{item} を追加",
	add_new_employee: "新しい従業員を追加",
	add_new_product: "新しい商品を追加",
	address: "住所",
	again: "もう一度",
	alert: "アラート",
	already_have_employee_message: "あなたはすでに会社の従業員です。",
	amount: "量",
	approve: "承認",
	archive: "アーカイブ",
	attachment: "添付ファイル",
	attachments: "添付ファイル",
	bank: "銀行",
	benefits: "利点",
	booking: "予約",
	booking_code: "予約コード",
	business_partner: "ビジネスパートナー",
	business_partners: "ビジネスパートナー",
	cancel: "キャンセル",
	change_password: "パスワードを変更する",
	checking_invitation_code_message: "招待コードを確認中",
	choose_image: "画像を選択",
	choose_your_plan: "プランを選択",
	clear: "クリア",
	click_to_play_the_video: "ビデオを再生するにはクリックしてください",
	close: "閉じる",
	code: "コード",
	commercial: "商業",
	commercial_partner: "商業パートナー",
	commercial_partners: "商業パートナー",
	companies: "会社",
	company: "会社",
	company_name: "会社名",
	company_profile: "会社のプロフィール",
	configuration: "構成",
	confirm: "確認",
	confirm_distance_selling_agreement: "距離販売契約を確認してください。",
	consult_us: "相談する",
	contact: "連絡先",
	contact_info: "連絡先情報",
	contact_text: "ご安心ください、できるだけ早くご連絡いたします。",
	contact_title: "私たちに相談して、プロセスを簡素化しましょう",
	contact_to_us: "お問い合わせ",
	container: "コンテナ",
	containers: "コンテナ",
	content: "コンテンツ",
	contract: "契約",
	countries: "国",
	country: "国",
	create: "作成",
	create_a_new_account: "新しいアカウントを作成する",
	create_company: "会社を作成する",
	created_at: "作成日",
	created_here: "ここで作成されました",
	credit_card_info: "クレジットカード情報",
	credit_card_no: "クレジットカード番号",
	currencies: "通貨",
	currency: "通貨",
	current_password: "現在のパスワード",
	current_plan: "現在のプラン",
	customs_agencies: "税関代理店",
	customs_agency: "税関代理店",
	cvv: "CVV",
	daily: "毎日",
	dashboard: "ダッシュボード",
	date: "日付",
	day: "日",
	default: "デフォルト",
	default_language: "デフォルト言語",
	delete: "削除",
	delete_account: "アカウントを削除",
	delete_confirm_message: "削除を確認しますか？",
	description: "説明",
	detail: "詳細",
	discard: "破棄",
	distance_sales_agreement: "距離販売契約",
	document_number: "文書番号",
	download: "ダウンロード",
	edit: "編集",
	edit_item: "{item} を編集",
	edit_product: "商品を編集",
	edit_profile: "プロフィールを編集",
	edit_your_profile_picture: "プロフィール写真を編集",
	email: "メール",
	employee: "従業員",
	employee_type: "従業員タイプ",
	employees: "従業員",
	estimated_arrival_day: "推定到着日",
	expense: "経費",
	expenses: "経費",
	expires_at: "有効期限",
	exported_containers: "輸出されたコンテナ",
	fail: "失敗",
	failed: "失敗しました",
	faq: "よくある質問",
	feedback: "フィードバック",
	file: "ファイル",
	file_view: "ファイルを見る",
	filter: "フィルタ",
	first_name: "名",
	first_name_last_name: "名と姓",
	forget_password: "パスワードを忘れましたか？",
	forget_password_message: "パスワードを忘れましたか？",
	forget_password_subtitle: "パスワードをリセットするためにメールアドレスを入力してください。",
	free: "無料",
	from: "から",
	give_us_feedback: "フィードバックをお寄せください",
	go_to_panel: "パネルに移動",
	hide: "隠す",
	home_page: "ホームページ",
	i_have_invitation_code: "招待コードを持っています",
	imported_containers: "輸入されたコンテナ",
	in_progress: "進行中",
	incoming: "着信",
	incoming_shipments: "着荷",
	initial: "初期",
	invitation: "招待",
	invitation_code: "招待コード",
	invitation_mail_sent: "招待メールを送信しました",
	invitations: "招待",
	join: "参加する",
	join_company: "会社に参加する",
	language: "言語",
	language_options: "言語オプション",
	last_name: "姓",
	last_one_year: "過去1年",
	latitude: "緯度",
	loading_country: "積載国",
	loading_point: "積載ポイント",
	loading_port: "積載港",
	log_in: "ログイン",
	logo: "ロゴ",
	longitude: "経度",
	mail_sent: "メールを送信しました",
	manage_profile: "プロフィール管理",
	message: "メッセージ",
	message_company_create: "会社が作成されました。",
	message_company_join: "招待コードを入力してください。",
	message_company_joined: "会社に参加しました",
	message_employee_delete: "従業員が削除されました。",
	message_employee_save: "従業員が保存されました。",
	message_join_company: "既存の会社に参加するにはコードを入力してください",
	method: "方法",
	migrate: "移行",
	migrate_partner: "会社を移行する",
	mobile_number: "携帯電話番号",
	month: "月",
	month_lowercase: "月",
	monthly: "月額",
	my_profile: "マイプロフィール",
	my_role: "私の役割",
	name: "名前",
	new: "新しい",
	new_company_package: "無料",
	new_password: "新しいパスワード",
	new_shipment: "新しい配送",
	next: "次",
	no: "いいえ",
	no_content: "コンテンツなし",
	no_entity: "エンティティなし",
	no_entity_message: "エンティティが見つかりませんでした。",
	no_text: "テキストなし",
	not_found_page_message: "申し訳ありませんが、このページは見つかりませんでした :(",
	note: "ノート",
	ok: "OK",
	operation_fail: "操作失敗",
	outgoing: "発信",
	outgoing_shipments: "発送",
	packages: "パッケージ",
	parent_group: "親グループ",
	partner: "パートナー",
	partners: "パートナー",
	partnership_status: "パートナーシップの状況",
	passive: "パッシブ",
	password: "パスワード",
	pay: "支払う",
	payback: "返済",
	payback_confirm_message: "返済を確認しますか？",
	payback_warning_message: "続行すると、サブスクリプションが終了します。",
	payment: "支払い",
	payment_amount: "支払金額",
	payment_awaited: "支払い待ち",
	payment_history: "支払い履歴",
	payment_success: "支払い成功",
	payment_summary: "支払い概要",
	payment_will_be_made_3d_secure: "*支払いは3Dセキュアで行われます。",
	pending_invitations: "保留中の招待",
	per_month: "月あたり",
	phone_number: "電話番号",
	premium: "プレミアム",
	premium_plus: "プレミアムプラス",
	previous: "前",
	pricing: "価格設定",
	process: "プロセス",
	product: "製品",
	product_group: "製品グループ",
	product_group_name: "商品グループ名",
	product_groups: "製品グループ",
	product_name: "商品名",
	products: "製品",
	profile: "プロフィール",
	proforma_invoice: "見積書",
	proforma_invoices: "見積書",
	proforma_number: "見積書番号",
	purchase: "購入",
	quantity: "数量",
	receiver: "受信者",
	receiver_company: "受信会社",
	receiver_customs_agency: "受信税関代理店",
	redirection_to_home_link: "ホーム",
	redirection_to_home_message: "またはこちらに移動できます ",
	redirection_to_sign_in_message: "すでにアカウントをお持ちですか？",
	redirection_to_sign_up_message: "まだアカウントをお持ちではありませんか？",
	reload: "リロード",
	renewal: "更新",
	repaid: "返済済み",
	reset_password: "パスワードをリセットする",
	rows_per_page: "1ページあたりの行数",
	sales: "販売",
	save: "保存",
	save_changes: "変更を保存",
	saved_card_info: "保存されたカード情報",
	search: "検索",
	select: "選択",
	select_file: "ファイルを選択",
	select_file_place_holder: "画像を選択してください",
	select_language: "言語を選択",
	selected_partner: "選択されたパートナー",
	send: "送信",
	send_again: "再送",
	send_email: "メールを送信する",
	send_feedback: "フィードバックを送信する",
	send_invitation: "招待を送信",
	sender: "送信者",
	sender_company: "送信会社",
	sender_customs_agency: "送信税関代理店",
	settings: "設定",
	shipment: "出荷",
	shipment_method: "出荷方法",
	shipment_type: "出荷タイプ",
	shipments: "出荷",
	shipments_filter: "出荷フィルタ",
	shipping_term: "配送条件",
	shipping_terms: "配送条件",
	show: "表示",
	sign_in: "サインイン",
	sign_out: "サインアウト",
	sign_out_confirm_message: "サインアウトを確認しますか？",
	sign_out_success_message: "さようなら。",
	sign_up: "サインアップ",
	social_media: "ソーシャルメディア",
	social_media_account: "ソーシャルメディアアカウント",
	start_time: "開始時間",
	status: "ステータス",
	subscribe: "サブスクライブ",
	subscription: "サブスクリプション",
	subscription_info_message: "サブスクリプション期間中、すべてのサービスを無制限に利用できます。",
	subscription_warning_message:
		"**カード情報は安全に保存され、サブスクリプションをキャンセルしない限り、定期的に更新されます。",
	success: "成功",
	super: "スーパー",
	supported_files_img: "サポートされているファイル形式: JPG, PNG。",
	switch_language: "言語を切り替える",
	switch_theme: "テーマを切り替える",
	target: "ターゲット",
	target_partner: "ターゲットパートナー",
	tax_number: "税番号",
	theme: "テーマ",
	this_month: "今月",
	this_year: "今年",
	title: "タイトル",
	to: "に",
	toDelete: "削除",
	total: "合計",
	total_amount: "総額",
	transaction_no: "取引番号",
	transactions: "取引",
	try_for_free: "無料で試す",
	type: "タイプ",
	types: "タイプ",
	un_archive: "アーカイブ解除",
	unit: "単位",
	units: "単位",
	unloading_country: "積み降ろし国",
	unloading_point: "積み降ろしポイント",
	unloading_port: "積み降ろし港",
	unsubscribe: "退会",
	unsubscribe_confirm_message: "退会すると、システムの機能を効果的に使用できなくなります。本当に退会しますか？",
	upload_new_image: "新しい画像をアップロード",
	use_code: "コードを使用",
	use_invitation_code: "招待コードを使用",
	use_invitation_code_description: "メールボックスに送信された招待コードを入力してください。",
	use_invitation_code_success_message: "招待コードが正常に使用されました。",
	user_profile: "マイプロフィール",
	vehicle: "車両",
	vehicles: "車両",
	verified_partner: "認定パートナー",
	verify_password: "パスワードを確認",
	visibility: "可視性",
	warehouse: "倉庫",
	warehouses: "倉庫",
	website: "ウェブサイト",
	week: "週",
	welcome: "ようこそ",
	what_we_are_doing: "私たちがしていること",
	year: "年",
	year_lowercase: "年",
	yearly: "年額",
	yes: "はい",
	you_dont_have_any_active_subscription: "アクティブなサブスクリプションがありません。",
};
