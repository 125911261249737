import Vue from "vue";
import App from "./components/App.vue";
import store from "./store";
import router from "./plugins/router";
import vuetify from "./plugins/vuetify";
import dotenv from "dotenv";
import remoteData from "./data/remote";
import axios from "axios";
import FirebasePlugin from "./plugins/firebase.plugin";
import { firebaseConfig } from "./firebase.config";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import i18n from "./plugins/i18n";
import moment from "moment";
import { DeviceUUID } from "device-uuid";
import { Setting, getSetting, setSetting } from "./data/local/ls";
import "../styles/global.css";
import "./assets/icon-font/icons.css";

dotenv.config();

Vue.use(FirebasePlugin, firebaseConfig);

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

var device = new DeviceUUID().parse();
Object.defineProperty(Vue.prototype, "$device", {
	value: device,
});

const axiosInstance = axios.create();
Object.defineProperty(Vue.prototype, "axios", {
	value: axiosInstance,
});

remoteData.setProvider({
	getHttpInstance: () => axiosInstance,
	getAccessToken: () => `Bearer ${store.state.auth.accessToken}`,
	getAuthUrlRoot: () => process.env.VUE_APP_AUTH_URL_ROOT,
	getWsUrlRoot: () => process.env.VUE_APP_WS_URL_ROOT,
	getFsUrlRoot: () => process.env.VUE_APP_FS_URL_ROOT,
});

// Localization settings
i18n.languageOptions = ["ar", "de", "en", "es", "fr", "hi", "ind", "it", "ja", "ko", "pt", "ru", "tr", "vi", "zh"];
let lang = getSetting(Setting.locale) || device.language.substring(0, 2);
if (!getSetting(Setting.locale)) {
	setSetting(Setting.locale, lang);
}
if (!i18n.languageOptions.includes(lang)) {
	lang = "en";
}
i18n.locale = lang;
moment.locale(lang);
document.getElementsByTagName("html")[0].setAttribute("lang", lang);
vuetify.framework.lang.current = lang;
Vue.prototype.$lang = lang;

const theme = vuetify.framework.theme.dark ? vuetify.framework.theme.themes.dark : vuetify.framework.theme.themes.light;
Vue.prototype.$color = theme;

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
