import moment from "moment";

export default class HttpModule {
	/**
	 * @param { getAccessToken, getHttpInstance, getAuthUrlRoot, getWsUrlRoot } provider
	 */
	constructor(provider) {
		this.provider = provider;
	}

	setProvider(value) {
		this.provider = value;
	}

	_parse(object) {
		return object;
	}

	_parseList(list) {
		for (let i = 0; i < list.length; i++) list[i] = this._parse(list[i]);
		return list;
	}

	_makeRequest({ requestMethod, url, params, headers, body, requiresToken, responseType, parse, onSuccess, onFail }) {
		var httpResult;
		if (requiresToken == null) requiresToken = true;
		const _params = {};
		for (const key in params) {
			const value = params[key];
			if (value != null) {
				_params[key] = Array.isArray(value) ? value.join() : value;
				if (_params[key] === "") delete _params[key];
			}
		}
		const config = {
			headers: {},
			params: _params,
		};

		if (requiresToken && this.provider.getAccessToken) config.headers.authorization = this.provider.getAccessToken();

		if (responseType) config.responseType = responseType;

		if (headers) for (const key in headers) if (typeof headers[key] === "string") config.headers[key] = headers[key];
		if (requestMethod === "get")
			httpResult = this.provider.getHttpInstance().get(`${this.provider.getUrlRoot()}${url}`, config);
		else if (requestMethod === "post")
			httpResult = this.provider.getHttpInstance().post(`${this.provider.getUrlRoot()}${url}`, body, config);
		else if (requestMethod === "put")
			httpResult = this.provider.getHttpInstance().put(`${this.provider.getUrlRoot()}${url}`, body, config);
		else if (requestMethod === "delete")
			httpResult = this.provider.getHttpInstance().delete(`${this.provider.getUrlRoot()}${url}`, config);
		if (httpResult) {
			httpResult.then(
				(response) => {
					if (onSuccess) {
						let data = response.data;
						if (parse) {
							if (Array.isArray(data)) for (const item of data) parse(item);
							else parse(data);
						} else {
							if (Array.isArray(data)) for (const item of data) this._parse(item);
							if (Array.isArray(data.items)) for (const item of data.items) this._parse(item);
							else this._parse(data);
						}
						onSuccess(data);
					}
				},
				(error) => {
					if (error) onFail(error);
				}
			);
		} else if (error) onFail(new Error("Unsupported request method"));
	}

	_post(url, body, { headers, requiresToken, parse, onSuccess, onFail }) {
		return this._makeRequest({
			requestMethod: "post",
			url,
			body,
			headers,
			requiresToken,
			parse,
			onSuccess,
			onFail,
		});
	}

	_put(url, body, { headers, requiresToken, parse, onSuccess, onFail }) {
		return this._makeRequest({
			requestMethod: "put",
			url,
			body,
			headers,
			requiresToken,
			parse,
			onSuccess,
			onFail,
		});
	}

	_get(url, { params, headers, requiresToken, responseType, parse, onSuccess, onFail }) {
		return this._makeRequest({
			requestMethod: "get",
			url,
			params,
			headers,
			requiresToken,
			responseType,
			parse,
			onSuccess,
			onFail,
		});
	}

	_delete(url, { params, headers, requiresToken, parse, onSuccess, onFail }) {
		return this._makeRequest({
			requestMethod: "delete",
			url,
			params,
			headers,
			requiresToken,
			parse,
			onSuccess,
			onFail,
		});
	}

	parseDateTime(string) {
		return moment.utc(string).local();
	}
}
