<template>
	<v-app id="inspire">
		<router-view v-if="isReady" />
		<v-container v-else fill-height align-center justify-center>
			<v-progress-circular :size="100" :width="7" indeterminate color="accent" />
		</v-container>
		<ext-progress-bar />
		<ext-progress-dialog />
		<ext-snackbar />
		<ext-alert-dialog />
		<ext-confirm-dialog />
		<ext-text-input-dialog />
	</v-app>
</template>

<script>
import ExtProgressBar from "./partials/ExtProgressBar";
import ExtProgressDialog from "./partials/dialogs/ExtProgressDialog";
import ExtSnackbar from "./partials/ExtSnackbar";
import ExtAlertDialog from "./partials/dialogs/ExtAlertDialog";
import ExtConfirmDialog from "./partials/dialogs/ExtConfirmDialog";
import ExtTextInputDialog from "./partials/dialogs/ExtTextInputDialog";
import { mapState, mapGetters, mapActions } from "vuex";
import authMixin from "../store/interfaces/auth.mixin";
import uiMixin from "../store/interfaces/ui.mixin";
import { necessity, requiresAuthorization, requiresCompany } from "../plugins/router";

export default {
	components: {
		ExtProgressBar,
		ExtProgressDialog,
		ExtSnackbar,
		ExtAlertDialog,
		ExtConfirmDialog,
		ExtTextInputDialog,
	},
	mixins: [authMixin, uiMixin],
	data() {
		return {};
	},
	computed: {
		...mapGetters(["isReady", "company"]),
		...mapState("auth", ["accessToken"]),
		...mapGetters("auth", ["isAuthenticated"]),
	},
	methods: {
		...mapActions({
			prepare: "prepare",
			pullUpStakes: "pullUpStakes",
		}),
		...mapActions("auth", ["signOut", "clearTokenData"]),
	},
	watch: {
		isReady(newValue) {
			if (newValue) {
				var targetPath = this.$router.history.pending?.name || this.$router.history.current.name;
				if (!this.isAuthenticated && requiresAuthorization(targetPath) === necessity.required) {
					this.$router.replace({ name: "signin" });
				} else if (this.isAuthenticated && requiresAuthorization(targetPath) === necessity.notAllowed) {
					this.$router.replace({ name: "home" });
				} else if (requiresCompany(targetPath) === necessity.required && !this.company) {
					this.$router.replace({ name: "home" });
				} else if (targetPath != this.$router.history.current.name) {
					this.$router.replace(targetPath);
				}
			}
		},
		accessToken(newValue, oldValue) {
			this.prepare();
		},
	},
	beforeDestroy() {
		this.pullUpStakes();
	},
	beforeCreate() {
		this.axios.interceptors.response.use(null, (error) => {
			console.debug("AXIOS INTERCEPTOR", error);
			const result = error.response
				? error.response.data || new Error(String(error))
				: new Error("something_went_wrong");
			if (!result.code) {
				if (error.response) result.code = error.response.status;
				else result.code = 408;
			}
			if (error.config.url.includes("invitations") && error.config.url.includes("accept")) {
				if (this.isReady)
					this.showAlertDialog({
						message: result.message ? this.$t(`$serviceMessage.${result.message}`) : JSON.stringify(result),
					});
				return Promise.reject(result);
			}

			if (result.code === 401 && !error.config.url.includes("signin")) {
				this.clearTokenData();
				this.$router.push({ name: "signin" });
			} else if (this.isReady) {
				if (result.code === 402) {
					this.showAlertDialog({
						message: result.message ? this.$t(`$serviceMessage.${result.message}`) : JSON.stringify(result),
						buttonText: this.$t("subscribe"),
						onClick: () => {
							this.$router.push({ name: "home" });
						},
						alternateButtonText: this.$t("cancel"),
						onAlternateClick: () => {},
					});
				} else
					this.showAlertDialog({
						message: result.message ? this.$t(`$serviceMessage.${result.message}`) : JSON.stringify(result),
					});
			} else if (
				!(result.code === 403 && (error.config.url.includes("corporation") || error.config.url.includes("employee")))
			) {
				const alternateButtonText = this.isAuthenticated ? this.$t("sign_out") : undefined;
				const onAlternateClick = this.isAuthenticated
					? () => {
							//alternative button action
							this.signOut(() => {
								// on sign out success
								this.prepare();
							});
						}
					: undefined;
				this.showAlertDialog({
					message: result.message ? this.$t(`$serviceMessage.${result.message}`) : JSON.stringify(result),
					buttonText: this.$t("reload"),
					onClick: () => {
						location.reload();
					},
					alternateButtonText,
					onAlternateClick,
				});
			}

			return Promise.reject(result);
		});
	},
	created() {
		this.prepare();
	},
};
</script>

<style>
.v-progress-linear {
	z-index: 1000;
}
html {
	overflow-y: auto !important;
}
.v-toolbar--dense .v-toolbar__title {
	font-size: 1rem !important;
}
</style>
