<template>
	<v-container>
		<v-row class="d-flex justify-center align-center">
			<v-card-subtitle
				:class="isMobile ? 'v-typography--title1' : 'v-typography--title2'"
				class="accent--text title-with-line pa-0 text-center"
			>
				{{ $t("pricing") }}
			</v-card-subtitle>
		</v-row>
		<v-row class="d-flex justify-center align-center">
			<v-card-title :class="isMobile ? 'v-typography--title' : 'v-typography--title3'" class="text-center">
				{{ $t(`$landing.pricing_title`) }}
			</v-card-title>
		</v-row>
		<v-row class="d-flex justify-center align-center">
			<v-card-subtitle :class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'" class="pa-0 text-center">
				{{ $t(`$landing.pricing_text`) }}
			</v-card-subtitle>
		</v-row>

		<v-row class="d-flex flex-column align-center justify-center">
			<v-card-text>
				<v-tabs v-model="currentIndex" centered hide-slider background-color="transparent">
					<v-tab
						v-for="(tab, i) in tabs"
						:key="i"
						@click="changeTab(tab)"
						:class="['v-typography-title1 tab-item', 'rounded-lg', currentIndex === i ? 'selected-tab' : '']"
						background-color="white"
					>
						{{ $t(tab) }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="currentIndex" class="bg_transparent">
					<v-tab-item v-for="(tab, j) in tabs" :key="j">
						<v-row v-if="filteredProducts.length" class="align-center justify-center">
							<v-col
								v-for="(product, k) in filteredProducts"
								:key="k"
								cols="12"
								sm="6"
								md="5"
								lg="4"
								xl="3"
								class="mt-8"
							>
								<v-card class="py-8 px-8 card" align="start" justify="start">
									<div v-if="product.name === 'yearly'" class="red-banner">
										{{ $t("$landing.save_percentage") }}
									</div>
									<v-card-text class="v-typography--paragraph">
										{{ $t(product.name) }}
									</v-card-text>

									<v-card-text class="v-typography--title5 mb-2 justify-start price-section">
										<span class="price-value">${{ getPrice(product.price) }}</span>
									</v-card-text>

									<v-card-text
										v-for="(content, l) in product.contents"
										:key="l"
										class="v-typography--body d-flex align-center py-2"
									>
										<v-icon class="mr-2" color="primary">mdi-check</v-icon>
										{{ getContentDetail(content) }}
									</v-card-text>

									<v-card-actions class="justify-center">
										<v-spacer />
										<v-btn large class="py-6 flex-grow-1 px-4 px-md-16 mt-4" elevation="0" @click="goToSubscription">
											{{ $t("subscribe") }}
										</v-btn>
										<v-spacer />
									</v-card-actions>

									<v-card-subtitle v-if="product.name !== 'new_company_package'" class="text-center">
										{{
											$t("$antProduct.renewAtPricePerUnit", {
												currency: "$",
												price: product.price,
												unit: $t(product.name === "yearly" ? "year_lowercase" : "month_lowercase"),
											})
										}}
									</v-card-subtitle>
								</v-card>
							</v-col>
						</v-row>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-row>

		<v-row justify="center" align="center" class="py-1">
			<v-col cols="12" md="6" class="py-0">
				<v-card-text class="py-2">
					<img src="~@/assets/red_check.png" class="mr-2" alt="Check Icon" />
					<span class="v-typography--body">
						{{ $t("$landing.cancel_anytime") }}
					</span>
				</v-card-text>
			</v-col>
		</v-row>

		<v-row justify="center" align="center" class="py-1">
			<v-col cols="12" md="6" class="py-0">
				<v-card-text class="py-2">
					<img src="~@/assets/red_check.png" class="mr-2" alt="Check Icon" />
					<span class="v-typography--body">
						{{ $t("$landing.customize_package") }}
					</span>
				</v-card-text>
			</v-col>
		</v-row>
	</v-container>
</template>

<!-- <v-row justify="center" align="center" class="mt-4">
			<v-col v-if="isMobile" cols="12" class="d-flex justify-center">
				<v-carousel v-model="slides[tab]" hide-delimiters :show-arrows="false" :cycle="false">
					<v-carousel-item v-for="(pricing, index) in currentCard" :key="`${index}-${tab}`">
						<v-container class="card ma-4" elevation="8">
							<div v-if="pricing.primary" class="red-text-banner">
								{{ $t("$landing.save_percentage") }}
							</div>
							<v-card-title class="justify-center">{{ pricing.title }}</v-card-title>
							<v-card-title class="text-left">
								{{ pricing.price }}
							</v-card-title>
							<v-card-text
								v-for="(feature, i) in pricing.features"
								:key="i"
								class="d-flex align-center"
							>
								<img src="~@/assets/red_check.png" class="mr-2 colored-image" alt="Check Icon" />
								{{ feature }}
							</v-card-text>
							<v-card-actions class="justify-center items-center">
								<v-btn text class="bg-white w-100 text-capitalize">{{ $t("subscribe") }}</v-btn>
							</v-card-actions>
							<v-card-subtitle class="text-center">{{ pricing.regeneration }}</v-card-subtitle>

							<v-card-text v-if="pricing.texts">
								<div v-for="(text, i) in pricing.texts" :key="i" class="text-center">
									{{ text }}
								</div>
							</v-card-text>
						</v-container>
						<div class="carousel-indicators">
							<span
								v-for="(item, i) in currentCard"
								:key="i"
								:class="['indicator', { active: i === slides[tab] }]"
							></span>
						</div>
					</v-carousel-item>
				</v-carousel>
			</v-col>
		</v-row> -->

<!-- <v-row v-if="!isMobile" justify="center" align="center" class="mt-4">
			<v-col v-if="currentCard.length === 1" cols="auto">
				<v-container class="card ma-4" elevation="8">
					<div v-if="currentCard[0].primary" class="red-text-banner">
						{{ $t("$landing.save_percentage") }}
					</div>
					<v-card-title class="justify-center">{{ currentCard[0].title }}</v-card-title>
					<v-card-title class="text-h4 text-left font-weight-black black-text">
						{{ currentCard[0].price }}
					</v-card-title>
					<v-card-text
						v-for="(feature, i) in currentCard[0].features"
						:key="i"
						class="d-flex align-center"
					>
						<img src="~@/assets/red_check.png" class="mr-2 colored-image" alt="Check Icon" />
						{{ feature }}
					</v-card-text>
					<v-card-actions class="justify-center">
						<v-btn text class="bg-white w-100 text-capitalize">{{ $t("subscribe") }}</v-btn>
					</v-card-actions>
					<v-card-subtitle class="text-center">{{ currentCard[0].regeneration }}</v-card-subtitle>

					<v-card-text v-if="currentCard[0].texts">
						<div v-for="(text, i) in currentCard[0].texts" :key="i" class="text-center">
							{{ text }}
						</div>
					</v-card-text>
				</v-container>
			</v-col>

			<v-col v-else cols="12" class="d-flex flex-wrap justify-center">
				<v-col
					v-for="(pricing, index) in currentCard"
					:key="index"
					cols="auto"
					class="d-flex justify-center"
				>
					<v-container class="card ma-4" elevation="8">
						<div v-if="pricing.primary" class="red-text-banner">
							{{ $t("$landing.save_percentage") }}
						</div>
						<v-card-title class="justify-center">{{ pricing.title }}</v-card-title>
						<v-card-title class="text-h4 text-left font-weight-black black-text">
							{{ pricing.price }}
						</v-card-title>
						<v-card-text
							v-for="(feature, i) in pricing.features"
							:key="i"
							class="d-flex align-center"
						>
							<img src="~@/assets/red_check.png" class="mr-2 colored-image" alt="Check Icon" />
							{{ feature }}
						</v-card-text>
						<v-card-actions class="justify-center">
							<v-btn class="bg-white w-100 text-capitalize" text>{{ $t("subscribe") }}</v-btn>
						</v-card-actions>
						<v-card-subtitle class="text-center">{{ pricing.regeneration }}</v-card-subtitle>

						<v-card-text v-if="pricing.texts">
							<div v-for="(text, i) in pricing.texts" :key="i" class="text-center">
								{{ text }}
							</div>
						</v-card-text>
					</v-container>
				</v-col>
			</v-col>
		</v-row> -->

<script>
import { mapGetters, mapState } from "vuex";
import remote from "../../../../data/remote/index";
import { antModule, antProductCategoryTypes } from "../../../../utils/enum";

export default {
	props: { isMobile: Boolean },
	data() {
		return {
			loading: false,
			currentIndex: 0,
			tabs: [antProductCategoryTypes.Free, antProductCategoryTypes.Premium, antProductCategoryTypes.PremiumPlus],
			antProducts: null,
			filteredProducts: [],
		};
	},
	computed: {
		...mapGetters(["countryCode"]),
		...mapState(["corporation", "countries"]),
	},
	methods: {
		getPrice(price) {
			return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		goToSubscription() {
			this.$router.push({ path: "/subscription" });
		},
		getAntProducts() {
			this.loading = true;
			remote.antProduct.filter({
				onSuccess: (result) => {
					this.antProducts = result.items;
					this.changeTab(this.tabs[this.currentIndex]);
					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},
		getContentDetail(content) {
			switch (content.module) {
				case antModule.Shipment:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("shipment"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("shipment"),
						});
				case antModule.Partner:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("commercial_partner"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("commercial_partner"),
						});
				case antModule.Storage:
					return this.$t("$antProduct.limitGBStorage", {
						limit: content.limit,
					});
				case antModule.User:
					return this.$t(content.limit <= 1 ? "$antProduct.limitUser" : "$antProduct.limitUsers", {
						limit: content.limit,
					});
				default:
					return "";
			}
		},
		changeTab(category) {
			this.filteredProducts = this.antProducts.filter((p) => p.category === category);
		},
	},
	watch: {
		currentIndex(newVal) {
			this.changeTab(this.tabs[newVal]);
		},
	},
	created() {
		this.getAntProducts();
	},
};
</script>

<style scoped>
.tab-item {
	background-color: #ffffff;
	text-transform: none;
	margin: 0 4px;
	padding: 8px 30px;
}

.selected-tab {
	background-color: #005d8f;
	color: white;
}

.card {
	max-height: 100%;
	width: 90%;
	background-color: #ffffff;
	border: rounded;
	border-radius: 20px;
	transition: background-color 0.3s ease, color 0.3s ease;
	position: relative;
	margin: 0 auto;
	overflow: visible;
}

.card .v-card__text,
.card .v-card__title,
.card .v-card__subtitle {
	color: black !important;
}

.card:hover {
	background-color: #005d8f;
}

.card:hover .v-card__text,
.card:hover .v-card__title,
.card:hover .v-card__subtitle {
	color: white !important;
}

.card:hover .v__btn {
	color: white !important;
}

.card:hover .v-icon {
	color: white !important;
}
.red-banner {
	position: absolute;
	top: -10px;
	right: 20px;
	border-radius: 20px !important;
	background-color: #cc2a21;
	color: white;
	padding: 4px 12px;
	font-size: 0.8rem;
	z-index: 1;
}

.price-section {
	font-size: 44px;
	font-weight: 800;
	line-height: 1;
	display: inline-flex;
	align-items: baseline;
}

.price-value {
	font-size: 44px;
	font-weight: 800;
}

.v-card {
	height: 100%;
}

.colored-image {
	width: 20px;
	height: 20px;
	filter: grayscale(100%) brightness(0.7) contrast(100%);
	transition: filter 0.3s ease;
}

.red-text-banner {
	z-index: 10;
	position: absolute;
	top: -10px;
	left: 40%;
	transform: translateX(-50%);
	background-color: #cc2a21;
	color: white;
	padding: 4px 8px;
	border-radius: 8px;
	font-size: 0.8rem;
}

.title-with-line {
	position: relative;
	display: inline-block;
}

.title-with-line::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transform: scaleX(0.3);
	transform-origin: left;
	margin-left: 3px;
}

.mobile-text-h5 {
	font-size: 1.2rem;
}

.mobile-text-h8 {
	font-size: 0.8rem;
}

.card:hover {
	background-color: #005d8f;
	color: white;
}
.carousel-indicators {
	display: flex;
	justify-content: center;
	margin-top: 5px;
}

.indicator {
	width: 10px;
	height: 10px;
	background-color: #d9d9d9;
	border-radius: 10px;
	margin: 0 5px;
	display: inline-block;
	transition: background-color 0.3s;
}

.carousel-indicators .indicator.active {
	background-color: #00466a !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
	box-shadow: none;
}
@media (max-width: 600px) {
	.v-container {
		max-width: 100%;
		overflow-x: hidden;
		overflow-y: hidden;
		padding-left: 0;
		padding-right: 0;
	}
	.title-with-line::after {
		height: 0;
	}
	.tab-item {
		text-transform: none;
		margin: 0 4px;
		padding: 8px 0;
		flex-grow: 1;
		text-align: center;
	}
}
</style>
