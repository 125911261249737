import { render, staticRenderFns } from "./Products.vue?vue&type=template&id=b7235bbc&scoped=true"
import script from "./Products.vue?vue&type=script&lang=js"
export * from "./Products.vue?vue&type=script&lang=js"
import style0 from "./Products.vue?vue&type=style&index=0&id=b7235bbc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7235bbc",
  null
  
)

export default component.exports