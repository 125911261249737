export default {
	$accessibility: {
		archived: "보관됨",
		present: "현재",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "수신자에게 숨김",
			hidden_from_sender: "발신자에게 숨김",
			private: "비공개",
			shared_with_custom: "맞춤 설정 공유",
			shared_with_everyone: "모두와 공유",
			shared_with_receiver: "수신자와 공유",
			shared_with_sender: "발신자와 공유",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB 저장소",
		limitMBStorage: "{limit}MB 저장소",
		limitUser: "{limit} 사용자",
		limitUsers: "{limit} 사용자들",
		renewAtPricePerUnit: "{currency}{price}마다 {unit}에서 갱신됨",
		unlimitedModule: "무제한 {module}",
		upToLimitModule: "{limit} {module}까지",
	},
	$company: {
		address: "코텍리 마할. 데니즐리 요루 불. 테크노파크 사이트 B 블록 No:4B Ic kapi No:14 멘테세 / 무글라, 터키",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sko!2str!4v1701805110461!5m2!1sko!2str",
		name: "슬러시 소프트웨어 주식회사",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "Antrego로 비즈니스를 가속화하세요. 배송 프로세스를 디지털 방식으로 안전하고 쉽게 관리하세요.",
	},
	$country: {
		afghanistan: "아프가니스탄",
		aland: "올란드",
		albania: "알바니아",
		algeria: "알제리",
		american_samoa: "미국령 사모아",
		andorra: "안도라",
		angola: "앙골라",
		anguilla: "앵귈라",
		antarctica: "남극",
		antigua_and_barbuda: "앤티가 바부다",
		argentina: "아르헨티나",
		armenia: "아르메니아",
		aruba: "아루바",
		australia: "호주",
		austria: "오스트리아",
		azerbaijan: "아제르바이잔",
		bahamas: "바하마",
		bahrain: "바레인",
		bangladesh: "방글라데시",
		barbados: "바베이도스",
		belarus: "벨라루스",
		belgium: "벨기에",
		belize: "벨리즈",
		benin: "베냉",
		bermuda: "버뮤다",
		bhutan: "부탄",
		bolivia: "볼리비아",
		bonaire_sint_eustatius_and_saba: "보네르, 신트외스타티우스, 사바",
		bosnia_and_herzegovina: "보스니아 헤르체고비나",
		botswana: "보츠와나",
		bouvet_island: "부베섬",
		brazil: "브라질",
		british_indian_ocean_territory: "영국령 인도양 지역",
		british_virgin_islands: "영국령 버진 아일랜드",
		brunei: "브루나이",
		bulgaria: "불가리아",
		burkina_faso: "부르키나파소",
		burundi: "부룬디",
		cabo_verde: "카보베르데",
		cambodia: "캄보디아",
		cameroon: "카메룬",
		canada: "캐나다",
		cayman_islands: "케이맨 제도",
		central_african_republic: "중앙아프리카공화국",
		chad: "차드",
		chile: "칠레",
		china: "중국",
		christmas_island: "크리스마스섬",
		cocos_islands: "코코스 제도",
		colombia: "콜롬비아",
		comoros: "코모로스",
		congo_republic: "콩고공화국",
		cook_islands: "쿡 제도",
		costa_rica: "코스타리카",
		croatia: "크로아티아",
		cuba: "쿠바",
		curacao: "퀴라소",
		cyprus: "키프로스",
		czechia: "체코",
		denmark: "덴마크",
		djibouti: "지부티",
		dominica: "도미니카",
		dominican_republic: "도미니카 공화국",
		dr_congo: "콩고민주공화국",
		ecuador: "에콰도르",
		egypt: "이집트",
		el_salvador: "엘살바도르",
		equatorial_guinea: "적도 기니",
		eritrea: "에리트레아",
		estonia: "에스토니아",
		eswatini: "에스와티니",
		ethiopia: "에티오피아",
		falkland_islands: "포클랜드 제도",
		faroe_islands: "페로 제도",
		fiji: "피지",
		finland: "핀란드",
		france: "프랑스",
		french_guiana: "프랑스령 기아나",
		french_polynesia: "프랑스령 폴리네시아",
		french_southern_territories: "프랑스령 남방 및 남극 지역",
		gabon: "가봉",
		gambia: "감비아",
		georgia: "조지아",
		germany: "독일",
		ghana: "가나",
		gibraltar: "지브롤터",
		greece: "그리스",
		greenland: "그린란드",
		grenada: "그레나다",
		guadeloupe: "과들루프",
		guam: "괌",
		guatemala: "과테말라",
		guernsey: "건지섬",
		guinea: "기니",
		guinea_bissau: "기니비사우",
		guyana: "가이아나",
		haiti: "아이티",
		heard_island_and_mcdonald_islands: "허드섬 및 맥도널드 제도",
		honduras: "온두라스",
		hong_kong: "홍콩",
		hungary: "헝가리",
		iceland: "아이슬란드",
		india: "인도",
		indonesia: "인도네시아",
		iran: "이란",
		iraq: "이라크",
		ireland: "아일랜드",
		isle_of_man: "맨섬",
		israel: "이스라엘",
		italy: "이탈리아",
		ivory_coast: "코트디부아르",
		jamaica: "자메이카",
		japan: "일본",
		jersey: "저지섬",
		jordan: "요르단",
		kazakhstan: "카자흐스탄",
		kenya: "케냐",
		kiribati: "키리바시",
		kosovo: "코소보",
		kuwait: "쿠웨이트",
		kyrgyzstan: "키르기스스탄",
		laos: "라오스",
		latvia: "라트비아",
		lebanon: "레바논",
		lesotho: "레소토",
		liberia: "라이베리아",
		libya: "리비아",
		liechtenstein: "리히텐슈타인",
		lithuania: "리투아니아",
		luxembourg: "룩셈부르크",
		macao: "마카오",
		madagascar: "마다가스카르",
		malawi: "말라위",
		malaysia: "말레이시아",
		maldives: "몰디브",
		mali: "말리",
		malta: "몰타",
		marshall_islands: "마셜 제도",
		martinique: "마르티니크",
		mauritania: "모리타니",
		mauritius: "모리셔스",
		mayotte: "마요트",
		mexico: "멕시코",
		micronesia: "미크로네시아",
		moldova: "몰도바",
		monaco: "모나코",
		mongolia: "몽골",
		montenegro: "몬테네그로",
		montserrat: "몬트세랫",
		morocco: "모로코",
		mozambique: "모잠비크",
		myanmar: "미얀마",
		namibia: "나미비아",
		nauru: "나우루",
		nepal: "네팔",
		netherlands: "네덜란드",
		new_caledonia: "뉴칼레도니아",
		new_zealand: "뉴질랜드",
		nicaragua: "니카라과",
		niger: "니제르",
		nigeria: "나이지리아",
		niue: "니우에",
		norfolk_island: "노퍽섬",
		north_korea: "북한",
		north_macedonia: "북마케도니아",
		northern_mariana_islands: "북마리아나 제도",
		norway: "노르웨이",
		oman: "오만",
		pakistan: "파키스탄",
		palau: "팔라우",
		palestine: "팔레스타인",
		panama: "파나마",
		papua_new_guinea: "파푸아뉴기니",
		paraguay: "파라과이",
		peru: "페루",
		philippines: "필리핀",
		pitcairn_islands: "핏케언 제도",
		poland: "폴란드",
		portugal: "포르투갈",
		puerto_rico: "푸에르토리코",
		qatar: "카타르",
		reunion: "레위니옹",
		romania: "루마니아",
		russia: "러시아",
		rwanda: "르완다",
		saint_barthelemy: "생바르텔레미",
		saint_helena: "세인트헬레나",
		saint_lucia: "세인트루시아",
		saint_martin: "생마르탱",
		saint_pierre_and_miquelon: "생피에르 미클롱",
		samoa: "사모아",
		san_marino: "산마리노",
		sao_tome_and_principe: "상투메 프린시페",
		saudi_arabia: "사우디아라비아",
		senegal: "세네갈",
		serbia: "세르비아",
		seychelles: "세이셸",
		sierra_leone: "시에라리온",
		singapore: "싱가포르",
		sint_maarten: "신트마르턴",
		slovakia: "슬로바키아",
		slovenia: "슬로베니아",
		solomon_islands: "솔로몬 제도",
		somalia: "소말리아",
		south_africa: "남아프리카 공화국",
		south_georgia_and_south_sandwich_islands: "사우스조지아 및 사우스샌드위치 제도",
		south_korea: "대한민국",
		south_sudan: "남수단",
		spain: "스페인",
		sri_lanka: "스리랑카",
		st_kitts_and_nevis: "세인트키츠 네비스",
		st_vincent_and_grenadines: "세인트빈센트 그레나딘",
		sudan: "수단",
		suriname: "수리남",
		svalbard_and_jan_mayen: "스발바르 제도 및 얀마옌 제도",
		sweden: "스웨덴",
		switzerland: "스위스",
		syria: "시리아",
		taiwan: "대만",
		tajikistan: "타지키스탄",
		tanzania: "탄자니아",
		thailand: "태국",
		timor_leste: "동티모르",
		togo: "토고",
		tokelau: "토켈라우",
		tonga: "통가",
		trinidad_and_tobago: "트리니다드 토바고",
		tunisia: "튀니지",
		turkiye: "튀르키예",
		turkmenistan: "투르크메니스탄",
		turks_and_caicos_islands: "터크스 케이커스 제도",
		tuvalu: "투발루",
		uganda: "우간다",
		ukraine: "우크라이나",
		united_arab_emirates: "아랍에미리트",
		united_kingdom: "영국",
		united_states: "미국",
		uruguay: "우루과이",
		us_minor_outlying_islands: "미국령 군소 제도",
		us_virgin_islands: "미국령 버진 아일랜드",
		uzbekistan: "우즈베키스탄",
		vanuatu: "바누아투",
		vatican_city: "바티칸 시국",
		venezuela: "베네수엘라",
		vietnam: "베트남",
		wallis_and_futuna: "왈리스 푸투나",
		western_sahara: "서사하라",
		yemen: "예멘",
		zambia: "잠비아",
		zimbabwe: "짐바브웨",
	},
	$currency: {
		euro: "유로",
		turkish_lira: "터키 리라",
		us_dollar: "미국 달러",
	},
	$employeeType: {
		admin: "관리자",
		default: "기본",
		exporter: "수출자",
		importer: "수입자",
	},
	$format: {
		date: "YYYY.MM.DD",
		date_time: "YYYY.MM.DD HH:mm:ss",
		date_time_without_second: "YYYY.MM.DD HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "여기에서 회사를 만드세요.",
			create_company_title: "회사를 추가하시겠습니까?",
			description: "성공적으로 로그인되었습니다. 이제 회사를 가입하거나 새 회사를 만드세요.",
			join_company_subtitle: "코드를 입력하고 여기에서 회사를 가입하세요.",
			join_company_title: "기존 회사에 가입하시겠습니까?",
			title: "환영합니다 {name}",
		},
		$unauthorized: {
			description: "Antrego를 사용하여 수출입 프로세스를 관리하세요.",
			forget_password_subtitle: "비밀번호를 재설정하려면 이메일을 입력하세요.",
			sign_in_subtitle: "여기에서 로그인하세요",
			sign_in_title: "이미 계정이 있으신가요?",
			sign_up_subtitle: "여기에서 가입하세요",
			sign_up_title: "계정을 만들고 싶으신가요?",
			title: "Antrego에 오신 것을 환영합니다",
		},
	},
	$invitationType: {
		company_partner: "상업적 협력 초대",
		customs_partner: "세관 협력 초대",
		employee: "직원 초대",
	},
	$landing: {
		$benefits: {
			benefits_title: "혜택",
			business_process: "비즈니스 프로세스를 가속화합니다.",
			control_title: "Antrego로 제어를 확보하세요.",
			highlight_word: "제어",
			risk_of_error: "오류의 위험을 줄입니다.",
			text: "Antrego는 배송 회사, 고객, 세관 중개인이 하나의 플랫폼을 통해 배송 프로세스를 추적할 수 있도록 돕는 배송 추적 프로그램입니다.",
			time_and_cost_savings: "시간과 비용을 절감합니다.",
		},
		$content: {
			$text: {
				0: "모든 배송을 한 곳에서 추적하고, 동적 보고로 재정을 관리하세요.",
				1: "실시간 업데이트를 고객과 공유하여 서비스 품질을 향상시키세요.",
				2: "배송과 관련된 모든 문서를 하나의 시스템에서 보고 공유하세요.",
				3: "모든 장치 및 위치에서 Antrego에 액세스하여 프로세스를 쉽게 관리하세요.",
				4: "SHA-256 암호화 기술을 사용하여 모든 데이터를 저장하세요. 보안에 대한 걱정은 없습니다.",
				5: "비즈니스 프로세스의 모든 이해관계자와 공동으로 추적에 참여하세요. 더 효율적으로 프로세스를 관리하세요.",
			},
			$title: {
				0: "배송 관리",
				1: "고객 관계",
				2: "문서 관리",
				3: "쉬운 액세스",
				4: "데이터 보안",
				5: "공동 추적",
			},
		},
		$home: {
			text: "원활한 디지털 추적 및 공동 관리를 위해 우리를 선택하세요.",
			title: "모든 배송 프로세스를 디지털 방식으로 쉽게 관리하세요",
		},
		$lifecycle: {
			headline: "프로세스",
			info_button: "정보 얻기",
			lifecycle_texts: {
				0: "추가 파일을 업로드하여 견적서를 작성하고 고객과 즉시 공유하세요.",
				1: "배송 세부 정보를 입력하고 문서를 추가하세요. 고객에게 알리고 하나의 채널을 통해 공동으로 모든 배송 프로세스를 관리하세요.",
				2: "수입 및 수출과 관련된 재정 상황을 실시간으로 추적하세요.",
			},
			lifecycle_titles: {
				0: "로그인 및 견적서 작성",
				1: "배송 생성",
				2: "재정 활동 추적",
			},
			productivity_title: "3단계로 생산성 향상 및 복잡성 및 오류 감소",
			tracking_guide_title: "쉬운 추적 가이드",
		},
		$logistics: {
			logistics_title: "지원되는 물류 유형",
			solutions_title: "모든 화물에 맞는 솔루션",
			text: "모든 유형의 운송에서 디지털 파트너 Antrego와 함께 모든 글로벌 이동에서 여러분의 곁에 있습니다.",
		},
		$process: {
			digital_future: "전통적인 방법에서 벗어나 디지털 미래로 나아가세요",
			digital_shipping: "Antrego로 디지털 배송 추적",
			digital_shipping_texts: {
				0: "들어오는 주문에 대한 견적서는 하나의 채널을 통해 쉽게 생성 및 추적됩니다.",
				1: "견적서가 전송되면 고객에게 즉시 알림이 전송되며, Antrego에서 승인됩니다.",
				2: "문서 프로세스는 Antrego를 통해 관리됩니다. 분실되지 않고, 지연이 발생하지 않으며 오류 가능성이 최소화됩니다.",
				3: "공급업체, 고객 및 세관 중개인은 배송 세부 정보를 즉시 보고 공동으로 추적할 수 있습니다.",
				4: "재정 보고서는 매월 및 매년 자동으로 생성됩니다.",
			},
			question_title: "Antrego가 당신의 삶을 어떻게 바꿀까요?",
			tracking_method: "추적 방법을 디지털화하여 프로세스를 가속화하세요.",
			traditional_shipping: "전통적인 배송 추적",
			traditional_shipping_texts: {
				0: "주문은 이메일, 메시지 또는 전화를 통해 다양한 채널로 수집되어 내부 추적에 어려움이 발생합니다.",
				1: "견적서는 Excel과 같은 도구를 사용하여 작성되고, 승인 과정은 이메일, WhatsApp 또는 전화를 통해 수동으로 처리됩니다.",
				2: "수동 문서 프로세스는 오류와 지연을 초래하여 시간과 재정적 손실을 발생시킵니다.",
				3: "공급업체, 고객, 세관 중개인이 배송 세부 정보를 추적할 수 있는 공통 플랫폼이 없습니다.",
				4: "배송 후 재정 데이터를 개별적으로 확인하여 보고서 작성이 어렵습니다.",
			},
		},
		$question: {
			$text: {
				0: "<p>이것은 기업과 고객이 공동으로 추적할 수 있는 배송 추적 프로그램으로, 추적 단계에서 지연으로 인한 손실을 방지할 수 있습니다.</p>",
				1: "<p>- 고객과 모든 실시간 업데이트를 공유하세요. 서비스 품질을 향상시키세요.<br>\n      - 배송과 관련된 모든 문서를 하나의 시스템에서 보고 공유하세요.<br>\n      - 모든 배송을 한 곳에서 추적하세요. 동적 보고를 통해 재정을 관리하세요.</p>",
				2: "<p>- 해상<br>\n      - 항공<br>\n      - 육상<br>\n      - 철도</p>",
				3: "<p>당사의 서비스로 배송 프로세스를 더 쉽게 만들 수 있습니다.<br>\n      <strong>1. 쉬운 액세스</strong><br>\n      웹 브라우저를 통해 모든 장치와 위치에서 액세스할 수 있습니다.<br>\n      <strong>2. 데이터 보안</strong><br>\n      모든 데이터는 SHA-256 암호화 기술을 사용하여 저장됩니다.<br>\n      <strong>3. 공동 추적</strong><br>\n      프로세스의 모든 이해관계자가 추적할 수 있는 기회를 제공합니다.</strong>",
				4: "<p>네, 유료 애플리케이션입니다. 모든 배송 프로세스를 디지털화하여 작업을 더 쉽게 만들고 유용한 개발을 지속하고자 합니다. 이러한 개선의 지속 가능성과 사용 중인 서버 공간을 보장하기 위해 월간 요금을 부과합니다.</p>",
				5: "<p>아니요. 고객이 추적하려면 별도의 비용을 지불하지 않아도 됩니다.</p>",
			},
			$title: {
				0: "Antrego란 무엇인가요?",
				1: "Antrego는 배송 과정에서 내 회사에 어떤 기여를 할 수 있나요?",
				2: "Antrego는 어떤 종류의 물류를 지원하나요?",
				3: "왜 Antrego를 사용해야 하나요?",
				4: "Antrego는 유료 애플리케이션인가요?",
				5: "내 고객이 Antrego에 요금을 지불해야 하나요?",
			},
			save_percentage: "매월 16% 절약",
			text: "Antrego를 사용하여 모든 정보를 쉽게 액세스하고, 추가 문의가 있는 경우 언제든지 문의하세요.",
			title: "궁금한 모든 것을 신속하게 배워보세요",
		},
		cancel_anytime: "언제든지 취소 가능",
		customize_package: "추가 스토리지 및 사용자를 구매하여 패키지를 필요에 맞게 사용자 정의할 수 있습니다",
		free_features_1: "10회 배송",
		free_features_2: "100MB 저장소",
		free_features_3: "무제한 상업 파트너",
		free_features_4: "1 사용자",
		free_plan: "무료",
		free_price: "$0",
		free_regeneration: "",
		free_title: "월간",
		premium_annual_features_1: "20 사용자",
		premium_annual_features_2: "100 파트너",
		premium_annual_features_3: "1000회 배송",
		premium_annual_features_4: "1000 인보이스",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "$999에서 매년 갱신됩니다.",
		premium_annual_title: "연간",
		premium_monthly_features_1: "무제한 배송",
		premium_monthly_features_2: "무제한 상업 파트너",
		premium_monthly_features_3: "2GB 저장소",
		premium_monthly_features_4: "3 사용자",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "$99에서 매월 갱신됩니다.",
		premium_monthly_title: "월간",
		premium_plan: "프리미엄 플랜",
		premium_plus_annual_features_1: "무제한 배송",
		premium_plus_annual_features_2: "무제한 상업 파트너",
		premium_plus_annual_features_3: "5GB 저장소",
		premium_plus_annual_features_4: "5 사용자",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "$999에서 매년 갱신됩니다.",
		premium_plus_annual_title: "연간",
		premium_plus_monthly_features_1: "무제한 배송",
		premium_plus_monthly_features_2: "무제한 상업 파트너",
		premium_plus_monthly_features_3: "5GB 저장소",
		premium_plus_monthly_features_4: "5 사용자",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "$249에서 매월 갱신됩니다.",
		premium_plus_monthly_title: "월간",
		premium_plus_plan: "프리미엄 플러스",
		pricing_text: "예산을 초과하지 않으면서 가장 적합한 솔루션을 제공합니다.",
		pricing_title: "Antrego는 고객에게 최고의 패키지와 가격을 보장합니다.",
		save_percentage: "매월 16% 절약",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "아랍어",
		de: "독일어",
		en: "영어",
		es: "스페인어",
		fr: "프랑스어",
		hi: "힌디어",
		ind: "인도네시아어",
		it: "이탈리아어",
		ja: "일본어",
		ko: "한국어",
		pt: "포르투갈어",
		ru: "러시아어",
		tr: "터키어",
		vi: "베트남어",
		zh: "중국어",
	},
	$message: {
		cannot_delete_verified_partner: "인증된 파트너는 삭제할 수 없습니다.",
		confirm: "{function}을(를) 확실히 하시겠습니까?",
		created: "{item} 생성됨.",
		deleted: "{item} 삭제됨.",
		error_on_view_file: "파일을 보는 동안 오류가 발생했습니다.",
		file_oversize: "파일 크기는 최대 {size}여야 합니다.",
		invalid: "유효하지 않은 {item}",
		migrated: "{item} 이동됨. ({value})",
		process_has_been_started: "{item}이(가) 시작되었습니다.",
		sent: "{item} 전송됨.",
		sent_email: "이메일 전송됨. ({value})",
		sign_in_failed: "이메일 또는 비밀번호가 잘못되었습니다.",
		unsupported_file_type: "지원되지 않는 파일 형식",
		updated: "{item} 업데이트됨.",
	},
	$pageTitles: {
		accept_invitation: "초대 수락",
		company_profile: "회사 프로필",
		home: "홈",
		landing_page: "원활한 디지털 추적, 공유 제어",
		partners: "파트너",
		product_groups: "제품 그룹",
		products: "제품",
		proforma_invoices: "견적서",
		shipment_details: "배송 세부 정보",
		shipments: "배송",
		sign_in: "로그인",
		sign_up: "가입",
		subscription: "구독",
		user_profile: "사용자 프로필",
	},
	$partnershipStatusTooltip: {
		description: "파트너와의 관계 상태를 추적할 수 있습니다. 차이점은 다음과 같습니다:",
		verifiedPartnersTitle: "검증된 파트너:",
		verifiedPartnersDescription:
			"이 파트너들은 초대를 수락하고 플랫폼에 등록했습니다. 정보는 읽기 전용이며 편집할 수 없습니다.",
		createdHereTitle: "여기에서 생성됨:",
		createdHereDescription:
			"등록되지 않은 파트너로, 이 플랫폼에서 그들의 프로필을 만들었습니다. 정보를 보고 편집할 수 있습니다.",
	},
	$productGroup: {
		add_child: "자식 추가",
		add_new_product_group: "새 제품 그룹 추가",
		add_to_root: "루트에 추가",
		has_children_fail_message: "자식을 가진 제품 그룹은 삭제할 수 없습니다.",
		initial_message: "첫 번째 제품 그룹을 만들려면 이 {0} 버튼을 사용하세요.",
		name: "이름",
		number_of_product: "제품 수",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name}이(가) 승인됨.",
			create: "{name}에 의해 생성됨.",
			create_attachment: "{name}이(가) 첨부 파일을 생성함.",
			delete_attachment: "{name}이(가) 첨부 파일을 삭제함.",
			demand_revise: "{name}이(가) 수정 요청함.",
			mark_as_processed: "{name}이(가) 처리됨으로 표시됨.",
			reject: "{name}이(가) 거부됨.",
			revoke: "{name}이(가) 철회됨.",
			send: "{name}이(가) 수신자에게 평가를 위해 전송됨.",
			update: "{name}에 의해 업데이트됨.",
			update_attachment: "{name}이(가) 첨부 파일을 업데이트함.",
		},
		$actionDescription2: {
			approve: '{name}이(가) "승인됨"으로 표시됨.',
			demand_revise: '{name}이(가) "수정 대기 중"으로 표시됨.',
			mark_as_processed: '{name}이(가) "처리됨"으로 표시됨.',
			reject: "{name}이(가) 거부됨.",
			revoke: '{name}이(가) "철회됨"으로 표시됨.',
			send: '{name}이(가) "전송됨"으로 표시됨.',
		},
		$actions: {
			approve: "승인",
			demand_revise: "수정 요구",
			mark_as_approved: "승인됨",
			mark_as_processed: "처리됨",
			mark_as_rejected: "거부됨",
			mark_as_revise_demanded: "수정 요구됨",
			mark_as_revoked: "철회됨",
			mark_as_sent: "전송됨",
			reject: "거부",
			revoke: "철회",
			send: "전송",
		},
		$status: {
			approved: "승인됨",
			pending_approval: "승인 대기 중",
			pending_revise: "수정 대기 중",
			processed: "처리됨",
			rejected: "거부됨",
			revoked: "철회됨",
			template: "템플릿",
		},
		approve_confirm_message: "견적서를 승인하시겠습니까?",
		approve_success_message: "견적서가 승인되었습니다.",
		demand_revise_description_message: "수정 기대사항에 대해 상세히 설명해 주세요:",
		demand_revise_success_message: "견적서가 발신자에게 수정 요청됨.",
		mark_as_processed_confirm_message: "견적서를 처리됨으로 표시하시겠습니까?",
		mark_as_processed_success_message: "견적서가 처리됨으로 표시되었습니다.",
		no_attachment_text: "첨부 파일이 아직 추가되지 않았습니다.",
		reject_confirm_message: "견적서를 거부하시겠습니까?",
		reject_success_message: "견적서가 거부되었습니다.",
		revoke_confirm_message: "견적서를 철회하시겠습니까?",
		revoke_success_message: "견적서가 철회되었습니다.",
		send_confirm_message: "견적서를 수신자에게 전송하시겠습니까?",
		send_success_message: "견적서가 전송되었습니다.",
	},
	$serviceMessage: {
		already_subs_module: "이 모듈에 대한 구독이 이미 있습니다.",
		bad_request: "잘못된 요청",
		code_already_used: "코드가 이미 사용되었습니다.",
		container_status_not_available: "컨테이너 상태를 사용할 수 없습니다.",
		existing_email_warning: "이미 존재하는 이메일입니다.",
		existing_employee_error: "이 사용자는 이미 회사에 추가되었습니다.",
		existing_employee_for_email_warning: "이 이메일로 초대 코드가 이미 전송되었습니다.",
		existing_invitation_warning: "이 이메일로 초대 코드가 이미 전송되었습니다.",
		existing_token_already_have_time: "기존 토큰에 이미 사용할 시간이 충분합니다.",
		existing_user: "사용자가 이미 존재합니다.",
		five_min_reset_password_mail: "비밀번호 재설정 이메일을 요청한 후 최소 5분이 지나야 합니다.",
		forbidden: "금지됨",
		have_no_corporation: "회사가 없습니다.",
		have_no_custom_agency: "세관 기관이 없습니다.",
		have_no_employee: "직원이 없습니다.",
		instance_can_no_delete_existing_booking_code: "기존 예약 코드로 인해 인스턴스를 삭제할 수 없습니다.",
		instance_cannot_delete: "인스턴스를 삭제할 수 없습니다.",
		instance_not_found: "인스턴스를 찾을 수 없습니다.",
		internal_server_error: "내부 서버 오류",
		invalid_current_password: "현재 비밀번호가 잘못되었습니다.",
		invalid_identifier_or_password: "식별자 또는 비밀번호가 잘못되었습니다.",
		invalid_invitation_code: "초대 코드가 잘못되었습니다.",
		invalid_moving_request: "잘못된 이동 요청",
		invalid_operation: "잘못된 작업",
		invalid_refresh_token: "잘못된 새로 고침 토큰입니다.",
		invalid_unit: "잘못된 단위",
		invitation_accepting_is_due_to_the_admin: "초대 수락은 관리자에게 달려 있습니다.",
		invitation_already_discarded: "초대가 이미 폐기되었습니다.",
		invitation_already_used: "초대가 이미 사용되었습니다.",
		invitation_has_been_used: "초대가 사용되었습니다.",
		invitation_not_exist_email: "이메일 주소에 초대가 없습니다.",
		invitation_not_for_company: "이 초대는 회사를 위한 것이 아닙니다.",
		invitation_not_for_custom_agency: "이 초대는 세관 기관을 위한 것이 아닙니다.",
		invitation_not_found: "초대를 찾을 수 없습니다.",
		invitation_not_valid: "초대가 더 이상 유효하지 않습니다.",
		link_has_been_expired: "링크가 만료되었습니다.",
		link_not_be_used_anymore: "이 링크는 더 이상 사용할 수 없습니다.",
		missing_required_fields: "필수 필드가 누락되었습니다.",
		no_employee_found: "직원이 없습니다.",
		no_proper_partner_found: "적절한 파트너를 찾을 수 없습니다.",
		not_acceptable: "허용되지 않음",
		not_allowed_action: "이 작업을 수행할 권한이 없습니다.",
		not_allowed_use_api: "이 API를 사용할 권한이 없습니다.",
		not_found: "찾을 수 없음",
		not_have_purchase_subscription:
			"구독 플랜이 정의의 최대 한도에 도달했습니다. 더 추가하려면 구독 플랜 업그레이드를 고려해 주세요.",
		not_permitted_use_api: "이 API를 사용할 수 없습니다.",
		own_invitation_cannot_accept: "자신의 초대를 수락할 수 없습니다.",
		parent_group_not_found: "상위 그룹을 찾을 수 없습니다.",
		partnerId_required: "파트너 ID가 필요합니다.",
		partnership_already_exists: "파트너십이 이미 추가되었습니다.",
		password_cant_involve_space_char: "비밀번호에 공백 문자가 포함될 수 없습니다.",
		password_min_chars_least: "비밀번호는 최소 6자 이상이어야 합니다.",
		payment_required: "이 작업을 위해 구독이 필요합니다.",
		paytr_integration_error: "PayTR 통합 오류",
		ports_are_required: "포트가 필요합니다.",
		product_group_tree_not_found: "제품 그룹 트리를 찾을 수 없습니다.",
		proforma_invoice_action_not_available: "견적서 상태가 이 작업을 수행할 수 없습니다.",
		proforma_invoice_template_update: "견적서가 템플릿이어야 업데이트할 수 있습니다.",
		shipment_email_not_exist: "유효한 이메일을 입력하세요.",
		shipment_method_is_required: "배송 방법이 필요합니다.",
		shipping_term_is_required: "배송 조건이 필요합니다.",
		someone_else_uses_this_email: "다른 사람이 이 이메일 주소를 사용 중입니다.",
		someone_else_uses_this_username: "다른 사람이 이 사용자 이름을 사용 중입니다.",
		something_went_wrong: "문제가 발생했습니다.",
		type_can_be_abstract_company: '"유형" 속성은 "추상 회사"만 가능합니다.',
		unauthorized: "권한이 없음",
		undefined_result: "정의되지 않은 결과",
		unexpected_error_occurred: "예기치 않은 오류가 발생했습니다.",
		unit_already_added: "단위가 이미 추가되었습니다.",
		user_already_employee: "사용자가 이미 직원입니다.",
		user_already_exists: "사용자가 이미 회사에 있습니다.",
		user_does_not_exist: "사용자가 존재하지 않습니다.",
		user_not_found: "사용자를 찾을 수 없습니다.",
		username_min_chars_error: '"사용자 이름"은 최소 6자 이상이어야 합니다.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name}이(가) 차량을 추가했습니다.",
				delete_container: "{name}이(가) 차량을 삭제했습니다.",
				update_container: "{name}이(가) 차량을 업데이트했습니다.",
			},
			create: "{name}에 의해 생성됨.",
			create_attachment: "{name}이(가) 첨부 파일을 추가했습니다.",
			create_container: "{name}이(가) 컨테이너를 추가했습니다.",
			delete_attachment: "{name}이(가) 첨부 파일을 삭제했습니다.",
			delete_container: "{name}이(가) 컨테이너를 삭제했습니다.",
			update: "{name}에 의해 업데이트됨.",
			update_attachment: "{name}이(가) 첨부 파일을 업데이트했습니다.",
			update_container: "{name}이(가) 컨테이너를 업데이트했습니다.",
		},
		$containerStatus: {
			arrived: "도착",
			off_load: "하역 중",
			on_load: "적재 중",
			on_way: "이동 중",
			reserved: "예약됨",
		},
		$method: {
			aerial: "항공",
			land: "육상",
			railway: "철도",
			sea: "해상",
		},
		$vehicle: {
			license_number: "차량 번호판",
		},
		archiving_success_message: "배송이 보관되었습니다.",
		leave_message: "메시지 또는 의견을 남겨주세요",
		no_booking_info: "예약 정보가 아직 설정되지 않았습니다. {0} 버튼을 사용하세요.",
		no_transportation_info: "세부 정보가 아직 설정되지 않았습니다. {0} 버튼을 사용하세요.",
		un_archiving_success_message: "배송이 보관 해제되었습니다.",
	},
	$shortening: {
		number: "번호",
	},
	$socialMedia: {
		facebook: "페이스북",
		instagram: "인스타그램",
		linked_in: "링크드인",
		twitter: "트위터",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "구독을 갱신할 수 없습니다. 결제 방법을 확인하세요.",
			fail: "예기치 않은 오류가 발생했습니다. 나중에 다시 시도하세요. 문제가 지속되면 info@antrego.net으로 이메일을 보내 문제를 보고해 주세요.",
			ok: "구매 프로세스가 시작되었습니다. 프로세스 결과는 이메일로 알림을 받게 됩니다. 결제가 성공적으로 완료되면 구독이 시작됩니다.",
			payment_process_is_ongoing:
				"결제 프로세스가 진행 중입니다. 프로세스 결과는 이메일로 알림을 받게 됩니다. 결제가 성공적으로 완료되면 구독이 시작됩니다.",
			success: "구독이 성공적으로 생성되었습니다. Antrego를 쉽게 사용할 수 있습니다.",
			unsubscribed: "구독이 취소되었습니다.",
			you_dont_have_any_subscription: "구독이 없습니다. 구독 페이지에서 패키지를 선택하고 쉽게 사용을 시작하세요.",
			you_dont_have_any_subscription_text: "계속하려면 요금제를 선택하세요.",
			you_dont_have_any_subscription_title: "활성 구독이 없습니다.",
		},
	},
	$unit: {
		acres: "에이커",
		boxes: "상자",
		cubic_meter: "세제곱미터",
		dozen: "다스",
		feet: "피트",
		fluid_ounces: "액량 온스",
		gallons: "갤런",
		gram: "그램",
		hectare: "헥타르",
		inches: "인치",
		kilogram: "킬로그램",
		kilometer: "킬로미터",
		liter: "리터",
		meter: "미터",
		miles: "마일",
		milligram: "밀리그램",
		milliliter: "밀리리터",
		millimeter: "밀리미터",
		ounces: "온스",
		pallets: "팔레트",
		pieces: "조각",
		pounds: "파운드",
		quarts: "쿼트",
		square_feet: "제곱피트",
		square_inches: "제곱인치",
		square_kilometer: "제곱킬로미터",
		square_meter: "제곱미터",
		square_miles: "제곱마일",
		square_yards: "제곱야드",
		ton: "톤",
		yards: "야드",
	},
	$validation: {
		email: "유효한 이메일을 입력하세요.",
		invalid: "유효하지 않은 {item}",
		max_length: "{item}은(는) {length}자 이하여야 합니다.",
		min_length: "{item}은(는) {length}자 이상이어야 합니다.",
		must_length: "{item}은(는) {length}자여야 합니다.",
		only_number: "{item}은(는) 숫자여야 합니다.",
		password_repeat_warning: "비밀번호 확인이 새 비밀번호와 일치하지 않습니다.",
		required: "{item}이(가) 필요합니다.",
		required_for_default_language: "{item}은(는) 회사 기본 언어로 필요합니다.",
		you_must_approve_distance_sales_agreement: "원격 판매 계약을 승인해야 합니다.",
	},
	about: "정보",
	accept_invitation: "초대 수락",
	accept_invitation_success_message: "초대가 성공적으로 사용되었습니다.",
	accessibility: "접근성",
	actions: "작업",
	active: "활성화됨",
	add: "추가",
	add_item: "{item} 추가",
	add_new_employee: "새 직원 추가",
	add_new_product: "새 제품 추가",
	address: "주소",
	again: "다시",
	alert: "경고",
	already_have_employee_message: "이미 회사의 직원입니다.",
	amount: "금액",
	approve: "승인",
	archive: "보관",
	attachment: "첨부 파일",
	attachments: "첨부 파일들",
	bank: "은행",
	benefits: "혜택",
	booking: "예약",
	booking_code: "예약 코드",
	business_partner: "사업 파트너",
	business_partners: "사업 파트너들",
	cancel: "취소",
	change_password: "비밀번호 변경",
	checking_invitation_code_message: "초대 코드를 확인하는 중",
	choose_image: "이미지 선택",
	choose_your_plan: "요금제 선택",
	clear: "지우기",
	click_to_play_the_video: "비디오 재생을 클릭하세요",
	close: "닫기",
	code: "코드",
	commercial: "상업",
	commercial_partner: "상업적 파트너",
	commercial_partners: "상업적 파트너들",
	companies: "회사들",
	company: "회사",
	company_name: "회사 이름",
	company_profile: "회사 프로필",
	configuration: "설정",
	confirm: "확인",
	confirm_distance_selling_agreement: "원격 판매 계약을 확인하세요.",
	consult_us: "문의하기",
	contact: "연락처",
	contact_info: "연락처 정보",
	contact_text: "안심하세요. 최대한 빨리 연락드리겠습니다.",
	contact_title: "문의하세요, 프로세스를 간소화하세요.",
	contact_to_us: "문의하기",
	container: "컨테이너",
	containers: "컨테이너들",
	content: "내용",
	contract: "계약",
	countries: "국가들",
	country: "국가",
	create: "생성",
	create_a_new_account: "새 계정 생성",
	create_company: "회사 생성",
	created_at: "생성됨",
	created_here: "여기에서 생성됨",
	credit_card_info: "신용 카드 정보",
	credit_card_no: "신용 카드 번호",
	currencies: "통화들",
	currency: "통화",
	current_password: "현재 비밀번호",
	current_plan: "현재 요금제",
	customs_agencies: "세관 기관들",
	customs_agency: "세관 기관",
	cvv: "CVV",
	daily: "일일",
	dashboard: "대시보드",
	date: "날짜",
	day: "일",
	default: "기본",
	default_language: "기본 언어",
	delete: "삭제",
	delete_account: "계정 삭제",
	delete_confirm_message: "삭제를 확인하시겠습니까?",
	description: "설명",
	detail: "세부사항",
	discard: "폐기",
	distance_sales_agreement: "원격 판매 계약",
	document_number: "문서 번호",
	download: "다운로드",
	edit: "편집",
	edit_item: "{item} 편집",
	edit_product: "제품 수정",
	edit_profile: "프로필 수정",
	edit_your_profile_picture: "프로필 사진 수정",
	email: "이메일",
	employee: "직원",
	employee_type: "직원 유형",
	employees: "직원들",
	estimated_arrival_day: "예상 도착일",
	expense: "비용",
	expenses: "비용들",
	expires_at: "만료됨",
	exported_containers: "수출된 컨테이너들",
	fail: "실패",
	failed: "실패함",
	faq: "자주 묻는 질문",
	feedback: "피드백",
	file: "파일",
	file_view: "파일 보기",
	filter: "필터",
	first_name: "이름",
	first_name_last_name: "이름 성",
	forget_password: "비밀번호 잊음",
	forget_password_message: "비밀번호를 잊으셨습니까?",
	forget_password_subtitle: "비밀번호를 재설정하려면 이메일 주소를 입력하세요.",
	free: "무료",
	from: "에서",
	give_us_feedback: "피드백 제공",
	go_to_panel: "패널로 이동",
	hide: "숨기기",
	home_page: "홈페이지",
	i_have_invitation_code: "초대 코드가 있습니다.",
	imported_containers: "수입된 컨테이너들",
	in_progress: "진행 중",
	incoming: "수신됨",
	incoming_shipments: "수신된 배송들",
	initial: "초기",
	invitation: "초대",
	invitation_code: "초대 코드",
	invitation_mail_sent: "초대 이메일 전송됨",
	invitations: "초대들",
	join: "가입",
	join_company: "회사에 가입",
	language: "언어",
	language_options: "언어 옵션",
	last_name: "성",
	last_one_year: "지난 1년",
	latitude: "위도",
	loading_country: "출발 국가",
	loading_point: "출발 지점",
	loading_port: "출발 항구",
	log_in: "로그인",
	logo: "로고",
	longitude: "경도",
	mail_sent: "이메일 전송됨",
	manage_profile: "프로필 관리",
	message: "메시지",
	message_company_create: "회사 생성됨.",
	message_company_join: "초대 코드를 입력하세요.",
	message_company_joined: "회사에 가입됨",
	message_employee_delete: "직원 삭제됨.",
	message_employee_save: "직원 저장됨.",
	message_join_company: "기존 회사에 가입하려면 코드를 입력하세요",
	method: "방법",
	migrate: "이동",
	migrate_partner: "파트너 이동",
	mobile_number: "휴대폰 번호",
	month: "월",
	month_lowercase: "월",
	monthly: "월간",
	my_profile: "내 프로필",
	my_role: "내 역할",
	name: "이름",
	new: "새로운",
	new_company_package: "무료",
	new_password: "새 비밀번호",
	new_shipment: "새로운 배송",
	next: "다음",
	no: "아니요",
	no_content: "내용 없음",
	no_entity: "엔터티 없음",
	no_entity_message: "엔터티를 찾을 수 없습니다.",
	no_text: "텍스트 없음",
	not_found_page_message: "죄송합니다, 이 페이지를 찾을 수 없습니다 :(",
	note: "노트",
	ok: "확인",
	operation_fail: "작업 실패",
	outgoing: "발신됨",
	outgoing_shipments: "발신된 배송들",
	packages: "패키지들",
	parent_group: "상위 그룹",
	partner: "파트너",
	partners: "파트너들",
	partnership_status: "파트너십 상태",
	passive: "비활성화됨",
	password: "비밀번호",
	pay: "지불",
	payback: "환불",
	payback_confirm_message: "환불을 확인하시겠습니까?",
	payback_warning_message: "계속하면 구독이 종료됩니다.",
	payment: "결제",
	payment_amount: "결제 금액",
	payment_awaited: "결제 대기 중",
	payment_history: "결제 내역",
	payment_success: "결제 성공",
	payment_summary: "결제 요약",
	payment_will_be_made_3d_secure: "*결제는 3d 보안으로 이루어집니다.",
	pending_invitations: "보류 중인 초대",
	per_month: "월별",
	phone_number: "전화 번호",
	premium: "프리미엄",
	premium_plus: "프리미엄 플러스",
	previous: "이전",
	pricing: "가격",
	process: "프로세스",
	product: "제품",
	product_group: "제품 그룹",
	product_group_name: "제품 그룹 이름",
	product_groups: "제품 그룹들",
	product_name: "제품 이름",
	products: "제품들",
	profile: "프로필",
	proforma_invoice: "견적서",
	proforma_invoices: "견적서들",
	proforma_number: "견적서 번호",
	purchase: "구매",
	quantity: "수량",
	receiver: "수신자",
	receiver_company: "수신 회사",
	receiver_customs_agency: "수신 세관 기관",
	redirection_to_home_link: "홈",
	redirection_to_home_message: "여기로 갈 수도 있습니다 ",
	redirection_to_sign_in_message: "계정이 있으신가요?",
	redirection_to_sign_up_message: "아직 계정이 없으신가요?",
	reload: "새로 고침",
	renewal: "갱신",
	repaid: "환불됨",
	reset_password: "비밀번호 재설정",
	rows_per_page: "페이지당 행 수",
	sales: "판매",
	save: "저장",
	save_changes: "변경 사항 저장",
	saved_card_info: "저장된 카드 정보",
	search: "검색",
	select: "선택",
	select_file: "파일 선택",
	select_file_place_holder: "이미지를 선택하세요",
	select_language: "언어 선택",
	selected_partner: "선택한 파트너",
	send: "전송",
	send_again: "다시 전송",
	send_email: "이메일 전송",
	send_feedback: "피드백 전송",
	send_invitation: "초대 전송",
	sender: "발신자",
	sender_company: "발신 회사",
	sender_customs_agency: "발신 세관 기관",
	settings: "설정",
	shipment: "배송",
	shipment_method: "배송 방법",
	shipment_type: "배송 유형",
	shipments: "배송들",
	shipments_filter: "배송 필터",
	shipping_term: "배송 조건",
	shipping_terms: "배송 조건들",
	show: "표시",
	sign_in: "로그인",
	sign_out: "로그아웃",
	sign_out_confirm_message: "로그아웃을 확인하시겠습니까?",
	sign_out_success_message: "안녕히 가세요.",
	sign_up: "가입",
	social_media: "소셜 미디어",
	social_media_account: "소셜 미디어 계정",
	start_time: "시작 시간",
	status: "상태",
	subscribe: "구독",
	subscription: "구독",
	subscription_info_message: "구독 기간 동안 모든 서비스를 무제한으로 사용할 수 있습니다.",
	subscription_warning_message: "**카드 정보는 안전하게 저장되며 구독을 취소하지 않으면 정기적으로 갱신됩니다.",
	success: "성공",
	super: "슈퍼",
	supported_files_img: "지원되는 파일: JPG, PNG.",
	switch_language: "언어 전환",
	switch_theme: "테마 전환",
	target: "목표",
	target_partner: "목표 파트너",
	tax_number: "세금 번호",
	theme: "테마",
	this_month: "이번 달",
	this_year: "올해",
	title: "제목",
	to: "에게",
	toDelete: "삭제",
	total: "합계",
	total_amount: "총 금액",
	transaction_no: "거래 번호",
	transactions: "거래",
	try_for_free: "무료로 시도",
	type: "유형",
	types: "유형들",
	un_archive: "보관 해제",
	unit: "단위",
	units: "단위들",
	unloading_country: "도착 국가",
	unloading_point: "도착 지점",
	unloading_port: "도착 항구",
	unsubscribe: "구독 취소",
	unsubscribe_confirm_message: "구독을 취소하면 시스템 기능을 효과적으로 사용할 수 없습니다. 구독을 취소하시겠습니까?",
	upload_new_image: "새 이미지 업로드",
	use_code: "코드 사용",
	use_invitation_code: "초대 코드 사용",
	use_invitation_code_description: "메일함에 전송된 초대 코드를 입력하세요.",
	use_invitation_code_success_message: "초대 코드가 성공적으로 사용되었습니다.",
	user_profile: "내 프로필",
	vehicle: "차량",
	vehicles: "차량들",
	verified_partner: "인증된 파트너",
	verify_password: "비밀번호 확인",
	visibility: "가시성",
	warehouse: "창고",
	warehouses: "창고들",
	website: "웹사이트",
	week: "주",
	welcome: "환영합니다",
	what_we_are_doing: "우리가 하고 있는 일",
	year: "년",
	year_lowercase: "년",
	yearly: "연간",
	yes: "예",
	you_dont_have_any_active_subscription: "활성 구독이 없습니다.",
};
