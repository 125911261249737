<template>
	<ext-dialog
		v-model="value"
		:title="dialogTitle"
		:primaryButtonText="$t('save')"
		:secondaryButtonText="$t('cancel')"
		@primaryButtonAction="saveAction"
		@secondaryButtonAction="closeButtonAction"
		@closed="closeButtonAction"
	>
		<template slot="body">
			<v-form ref="form" v-model="valid" v-if="currentItem">
				<v-col>
					<localized-text-field
						v-model="currentItem.name"
						:defaultLanguage="companyDefaultLanguage"
						:rules="rules.name"
						:options="companyLanguageOptions"
						:label="$t('name')"
					/>
					<product-group-select
						v-if="productGroupOptions"
						v-model="currentItem.parentGroupId"
						clearable
						eager="true"
						:label="$t('parent_group')"
						:options="productGroupOptions"
						:disabled="!productGroupOptions.length"
					/>
				</v-col>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import ProductGroupSelect from "../../controls/ProductGroupSelect.vue";
import { mapGetters } from "vuex";
import LocalizedTextField from "../../controls/LocalizedTextField";
import ExtDialog from "./ExtDialog.vue";

export default {
	mixins: [uiMixin],

	props: ["value", "item", "productGroupTree", "maxParentCount"],

	components: { ProductGroupSelect, LocalizedTextField, ExtDialog },

	data() {
		return {
			rootGroupId: null,
			initialParentGroupId: null,
			itemTemplate: {
				parentGroupId: null,
				name: {},
			},
			currentItem: null,
			saving: false,
			deleting: false,
			productGroupOptions: null,
			rules: {
				name: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "100",
							});
						else return true;
					},
				],
			},
			valid: true,
		};
	},
	computed: {
		...mapGetters(["companyDefaultLanguage", "companyLanguageOptions"]),
		dialogTitle() {
			return this.currentItem?.id
				? `${this.$t("edit_item", { item: this.$t("product_group") })}`
				: `${this.$t("add_item", { item: this.$t("product_group") })}`;
		},
	},
	watch: {
		value(val) {
			if (val) {
				const res = this.item || this.itemTemplate;
				if (!res.name[this.companyDefaultLanguage]) res.name[this.companyDefaultLanguage] = "";
				for (const language of this.companyLanguageOptions) {
					if (!res.name[language]) res.name[language] = "";
				}
				this.currentItem = Object.assign({}, res);
				this.currentItem.name = Object.assign({}, res.name);
				if (typeof this.item === "object") {
					const parent = this.findParentGroupOfCurrentItem(this.productGroupTree);
					this.currentItem.parentGroupId = parent ? parent.id : null;
					this.initialParentGroupId = parent ? parent.id : null;
				} else if (typeof this.item === "string") {
					this.currentItem.parentGroupId = this.item;
					this.initialParentGroupId = this.item;
				} else {
					this.currentItem.parentGroupId = null;
					this.initialParentGroupId = null;
				}

				this.productGroupOptions = [];
				this.handleProductGroupTree(this.productGroupTree);
			} else {
				this.$refs.form.reset();
				this.currentItem = null;
				this.initialParentGroupId = null;
			}
		},
	},
	methods: {
		handleProductGroupTree(productGroupTree) {
			if (productGroupTree && productGroupTree.children) {
				if ((this.item ? this.item.id !== productGroupTree.id : true) && productGroupTree.level < this.maxParentCount) {
					if (productGroupTree.name === undefined) this.rootGroupId = productGroupTree.id;
					else
						this.productGroupOptions.push({
							id: productGroupTree.id || "",
							name: productGroupTree.name || {
								_default: this.$t("main_group"),
							},
							level: productGroupTree.level,
						});
					for (const child of productGroupTree.children) {
						this.handleProductGroupTree(child);
					}
				}
			}
		},
		findParentGroupOfCurrentItem(productGroup) {
			if (!this.currentItem.id) {
				return null;
			}

			for (const child of productGroup.children) {
				if (child.id === this.currentItem.id) {
					return productGroup;
				} else {
					let result = this.findParentGroupOfCurrentItem(child);
					if (result) {
						return result;
					}
				}
			}

			return null;
		},
		saveAction() {
			if (this.valid) {
				if (this.currentItem.name && !this.currentItem.name[this.companyDefaultLanguage]) {
					this.showAlertDialog({
						message: `${this.$t("$validation.required_for_default_language", {
							item: this.$t("name"),
						})} (${this.$t(`$language.${this.companyDefaultLanguage}`)})`,
					});
				} else {
					this.saving = true;
					const onSuccess = () => {
						this.saving = false;
						if (this.currentItem.id) this.$editEvent("product_group");
						else this.$addEvent("product_group");
						this.$emit("saved");
						this.showSnackBar({
							message: this.$t("$message.updated", {
								item: this.$t("product_group"),
							}),
						});
					};
					const onFail = (error) => {
						this.saving = false;
						if (error) console.error(error);
					};
					if (this.currentItem.id) {
						if (this.initialParentGroupId === this.currentItem.parentGroupId) {
							remote.productGroups.update(this.currentItem, null, {
								onSuccess: onSuccess,
								onFail: onFail,
							});
						} else {
							remote.productGroups.update(this.currentItem, this.currentItem.parentGroupId || this.rootGroupId, {
								onSuccess: onSuccess,
								onFail: onFail,
							});
						}
					} else {
						if (this.currentItem.parentGroupId) {
							remote.productGroups.create(this.currentItem, this.currentItem.parentGroupId, {
								onSuccess: onSuccess,
								onFail: onFail,
							});
						} else {
							remote.productGroups.create(this.currentItem, null, {
								onSuccess: onSuccess,
								onFail: onFail,
							});
						}
					}
				}
			} else this.$refs.form.validate();
		},

		closeButtonAction() {
			this.$emit("closed");
		},
	},
};
</script>
