<template>
	<v-dialog v-model="value" max-width="500px" persistent>
		<v-form ref="invitationCodeForm" v-model="valid">
			<v-card class="ant-card px-4 py-8">
				<v-card-title class="v-typography--title1">
					{{ $t("join_company") }}
					<v-spacer />
					<v-btn icon plain @click="closeAction">
						<v-icon large>mdi-close</v-icon>
						<!-- <v-icon>icon-cross</v-icon> -->
					</v-btn>
				</v-card-title>
				<v-card-subtitle class="v-typography--body">
					{{ $t("message_company_join") }}
				</v-card-subtitle>
				<v-card-text>
					<v-text-field
						:label="$t('invitation_code')"
						v-model="code"
						:rules="rules.code"
						outlined
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						class="py-7"
						block
						color="secondary"
						:loading="sending"
						:disabled="sending"
						@click="sendAction"
					>
						{{ $t("join") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import remote from "../../../../data/remote";

export default {
	props: { value: Boolean },
	data() {
		return {
			code: null,
			valid: null,
			sending: null,
			rules: {
				code: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("invitation_code"),
							});
						return true;
					},
				],
			},
		};
	},
	watch: {
		value(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	methods: {
		reset() {
			this.code = null;
			this.$refs.invitationCodeForm.reset();
		},
		sendAction() {
			if (this.valid) {
				this.sending = true;
				remote.invitations.accept(this.code, {
					onSuccess: () => {
						this.$emit("succeeded");
						this.sending = false;
					},
					onFail: (_) => {
						this.sending = false;
					},
				});
			} else this.$refs.invitationCodeForm.validate();
		},
		closeAction() {
			this.$emit("input", false);
			this.$emit("closed");
		},
	},
};
</script>
