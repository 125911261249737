<template>
	<v-container>
		<v-card-text>
			<v-progress-linear v-if="loading" color="accent" />

			<v-tabs v-model="currentTab" centered color="anchor">
				<v-tab
					v-for="(tab, i) in tabs"
					:key="i"
					@click="changeTab(tab)"
					:class="['v-typography-title1 tab-item', 'rounded-lg', currentTab === tab ? 'selected-tab' : '']"
				>
					{{ $t(tab) }}
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="currentTab" class="py-4">
				<v-tab-item v-for="(tab, j) in tabs" :key="j">
					<v-row v-if="filteredProducts" align="center" justify="center">
						<v-col v-for="(product, k) in filteredProducts" :key="k" cols="12" sm="6" md="5" lg="4" xl="4">
							<v-card
								class="py-8 px-8 card"
								elevation="0"
								:color="product === selectedProduct ? 'accent' : 'background'"
								@click="selectAction(product)"
								align="start"
								justify="start"
							>
								<div v-if="product.name === 'yearly'" class="red-banner">
									{{ $t("$landing.save_percentage") }}
								</div>
								<v-card-text
									:class="[product === selectedProduct ? 'white--text' : 'black--text', 'v-typography--paragraph']"
								>
									{{ $t(product.name) }}
								</v-card-text>

								<v-card-text
									:class="[product === selectedProduct ? 'white--text' : 'black--text', 'v-typography--title5 my-4 ']"
								>
									{{ "$" }}{{ getPrice(product.price) }}
								</v-card-text>

								<v-card-text
									v-for="(content, l) in product.contents"
									:key="l"
									:class="[product === selectedProduct ? 'white--text' : 'black--text', 'v-typography--body  py-2']"
								>
									<v-icon class="mr-2" :color="product === selectedProduct ? 'white' : 'black'">mdi-check</v-icon>
									{{ getContentDetail(content) }}
								</v-card-text>

								<v-card-actions>
									<v-spacer />
									<v-btn large class="py-6 flex-grow-1 px-4 px-md-16 mt-4" elevation="0">
										{{ product === selectedProduct ? $t("selected") : $t("select") }}
									</v-btn>
									<v-spacer />
								</v-card-actions>

								<v-card-subtitle :class="[product === selectedProduct ? 'white--text' : 'black--text']">
									{{
										$t("$antProduct.renewAtPricePerUnit", {
											currency: "$",
											price: product.price,
											unit: product.name,
										})
									}}
								</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>

		<v-card-subtitle class="py-2 v-typography--body">
			<v-icon class="mr-2" color="error">mdi-check</v-icon>
			{{ $t("$landing.cancel_anytime") }}
		</v-card-subtitle>

		<v-card-subtitle class="py-2 v-typography--body">
			<v-icon class="mr-2" color="error">mdi-check</v-icon>
			{{ $t("$landing.customize_package") }}
		</v-card-subtitle>

		<v-card-actions>
			<v-spacer />
			<v-btn color="accent" append @click="nextAction" :disabled="!selectedProduct">
				{{ $t("select") }}
				<v-icon dark> mdi-chevron-right </v-icon>
			</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import remote from "../../../../data/remote/index";
import { antModule, antProductCategoryTypes } from "../../../../utils/enum";

export default {
	data() {
		return {
			selectedProduct: null,
			currentTab: 0,
			tabs: [
				// antProductCategoryTypes.Free,
				antProductCategoryTypes.Premium,
				antProductCategoryTypes.PremiumPlus,
			],
			loading: false,
			antProducts: null,
			filteredProducts: null,
		};
	},
	computed: {
		...mapGetters(["countryCode"]),
		...mapState(["corporation", "countries"]),
	},

	methods: {
		getAntProducts() {
			this.loading = true;
			remote.antProduct.filter({
				onSuccess: (result) => {
					this.antProducts = result.items;
					this.changeTab(this.tabs[this.currentTab]);
					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},
		changeTab(category) {
			this.filteredProducts = [];
			for (const p of this.antProducts) if (p.category === category) this.filteredProducts.push(p);
		},
		selectAction(product) {
			this.selectedProduct = product;
		},
		nextAction() {
			this.$emit("nextAction", this.selectedProduct);
		},
		getPrice(price) {
			return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},

		getContentDetail(content) {
			switch (content.module) {
				case antModule.Shipment:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("shipment"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("shipment"),
						});
				case antModule.Partner:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("commercial_partner"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("commercial_partner"),
						});
				case antModule.Storage:
					return this.$t("$antProduct.limitGBStorage", {
						limit: content.limit,
					});
				case antModule.User:
					return this.$t(content.limit <= 1 ? "$antProduct.limitUser" : "$antProduct.limitUsers", {
						limit: content.limit,
					});
				default:
					return "";
			}
		},
	},
	created() {
		this.getAntProducts();
	},
};
</script>

<style scoped>
.tab-item {
	text-transform: none;
	margin: 0 4px;
	padding: 8px 30px;
}

.selected-tab {
	background-color: #005d8f;
	color: white;
}

.card {
	border: rounded;
	border-radius: 20px;
	transition:
		background-color 0.3s ease,
		color 0.3s ease;
	position: relative;
}

.red-banner {
	position: absolute;
	top: -10px;
	right: 20px;
	border-radius: 20px !important;
	background-color: #cc2a21;
	color: white;
	padding: 4px 12px;
	font-size: 0.8rem;
	z-index: 1;
}
</style>
