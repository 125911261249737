<template>
	<page-template>
		<template slot="title">
			{{ $t("shipment") }}
			<v-btn icon @click="backAction">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
		</template>
		<template slot="buttons">
			<v-btn v-if="editMode" class="d-none d-sm-inline-flex" @click="cancelAction" text plain>
				<v-icon left>mdi-close</v-icon>
				{{ $t("cancel") }}
			</v-btn>
			<v-btn v-if="editMode" class="d-sm-none" icon @click="cancelAction">
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<v-btn v-if="editMode" class="d-none d-sm-inline-flex" color="accent" @click="saveAction" text plain>
				<v-icon left>mdi-content-save</v-icon>
				{{ $t("save") }}
			</v-btn>
			<v-btn v-if="editMode" class="d-sm-none" color="accent" icon @click="saveAction">
				<v-icon>mdi-content-save</v-icon>
			</v-btn>

			<v-btn v-if="!isMobile & !editMode" @click="editAction" text plain>
				<v-icon>mdi-file-edit</v-icon>
				{{ $t("edit") }}
			</v-btn>
			<v-btn v-if="!isMobile & !editMode" @click="archiveAction" text plain>
				<v-icon>{{ isArchived ? "mdi-archive-arrow-up" : "mdi-archive-arrow-down" }}</v-icon>
				{{ $t(isArchived ? "un_archive" : "archive") }}
			</v-btn>
			<v-btn v-if="!isMobile & !editMode" @click="sendEmailDialog.show" text plain>
				<v-icon>{{ "mdi-email" }}</v-icon>
				{{ $t("send_email") }}
			</v-btn>

			<v-menu v-if="isMobile && shipment && !editMode" :bottom="isMobile" offset-y :disabled="deleting || saving">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" plain>
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-list v-if="isMobile">
					<v-list-item @click="editAction">
						<v-icon left>mdi-file-edit</v-icon>
						{{ $t("edit") }}
					</v-list-item>
					<v-list-item v-if="!shipment.bookingCode" @click="deleteAction">
						<v-icon left color="error">mdi-file-remove</v-icon>
						{{ $t("delete") }}
					</v-list-item>
					<v-list-item @click="archiveAction">
						<v-icon left>
							{{ isArchived ? "mdi-archive-arrow-up" : "mdi-archive-arrow-down" }}
						</v-icon>
						{{ $t(isArchived ? "un_archive" : "archive") }}
					</v-list-item>
					<v-list-item @click="sendEmailDialog.show">
						<v-icon left>{{ "mdi-email" }}</v-icon>
						{{ $t("send_email") }}
					</v-list-item>
				</v-list>
			</v-menu>
		</template>

		<template slot="content">
			<v-container class="pt-0" v-if="shipment">
				<v-form v-model="valid" ref="form">
					<v-row>
						<v-col cols="12" lg="6" class="pb-0">
							<title-value-list-item
								v-if="iAmAnyReceiver"
								:title="$t('sender')"
								:value="shipment.sender.company.name"
								icon="mdi-office-building"
							/>
							<title-value-list-item
								v-else-if="iAmAnySender"
								:title="$t('receiver')"
								:value="shipment.receiver.company.name"
								icon="mdi-office-building"
							/>
						</v-col>
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<title-value-list-item
								:title="$t('method')"
								:value="$t(`$shipment.$method.${shipment.method}`)"
								:icon="getShipmentMethodIcon(shipment.method)"
							/>
						</v-col>
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<currency-select
								v-if="editMode"
								v-model="editItem.currency.code"
								:eager="true"
								:label="$t('currency')"
								:rules="rules.currency"
								prepend-icon="mdi-cash"
								@change="currencyChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('currency')"
								:value="$t(`$currency.${shipment.currency.name}`)"
								icon="mdi-cash"
							/>
						</v-col>
						<v-col
							cols="12"
							lg="6"
							sm="6"
							class="pb-0"
							v-if="(editMode && iAmSenderCompany) || shipment.sender.customsAgency"
						>
							<partner-select
								v-if="editMode && iAmSenderCompany"
								v-model="editItem.sender.customsAgency.partnerId"
								:label="$t('sender_customs_agency')"
								:eager="true"
								:types="[partnerTypes.CustomsAgency]"
								clearable
								prepend-icon="mdi-office-building-outline"
								@change="customsPartnerChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('sender_customs_agency')"
								:value="shipment.sender.customsAgency ? shipment.sender.customsAgency.name : ''"
								icon="mdi-office-building-outline"
							/>
						</v-col>
						<v-col
							cols="12"
							lg="6"
							sm="6"
							class="pb-0"
							v-if="(editMode && iAmReceiverCompany) || shipment.receiver.customsAgency"
						>
							<partner-select
								v-if="editMode && iAmReceiverCompany"
								v-model="editItem.receiver.customsAgency.partnerId"
								:label="$t('receiver_customs_agency')"
								:eager="true"
								:types="[partnerTypes.CustomsAgency]"
								clearable
								prepend-icon="mdi-office-building-outline"
								@change="customsPartnerChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('receiver_customs_agency')"
								:value="shipment.receiver.customsAgency ? shipment.receiver.customsAgency.name : ''"
								icon="mdi-office-building-outline"
							/>
						</v-col>
					</v-row>

					<v-divider />

					<div>
						<v-subheader>
							{{ $t("booking") }}
						</v-subheader>
						<v-row v-if="showBookingArea">
							<v-col cols="12" sm="6" lg="3" class="pb-0" v-if="editMode || shipment.bookingCode">
								<v-text-field
									v-if="editMode"
									v-model="editItem.bookingCode"
									:label="$t('$shortening.number')"
									:rules="rules.bookingCode"
									prepend-icon="mdi-numeric"
								/>
								<title-value-list-item
									v-else-if="shipment.bookingCode"
									:title="$t('$shortening.number')"
									:value="shipment.bookingCode"
									icon="mdi-numeric"
								/>
							</v-col>

							<v-col cols="12" sm="6" lg="3" class="pb-0" v-if="editMode || shipment.shippingTerm">
								<shipping-term-select
									v-if="editMode"
									v-model="editItem.shippingTerm.code"
									:label="$t('shipping_term')"
									:clearable="true"
									:eager="true"
									:rules="rules.shippingTerm"
									prepend-icon="mdi-arrow-decision-outline"
									@change="shippingTermChangeAction"
								/>
								<title-value-list-item
									v-else-if="shipment.shippingTerm"
									:title="$t('shipping_term')"
									:value="`${shipment.shippingTerm.name} (${shipment.shippingTerm.code})`"
									icon="mdi-arrow-decision-outline"
								/>
							</v-col>

							<v-col cols="0" lg="6" class="py-0" />

							<v-col cols="12" sm="6" lg="3" class="pb-0" v-if="editMode || shipment.loadingCountry.code">
								<country-select
									v-if="editMode"
									v-model="editItem.loadingCountry.id"
									:options="countries"
									:label="$t('loading_country')"
									:rules="rules.loadingCountry"
									prepend-icon="mdi-earth-box"
									@change="loadingCountryChangeAction"
								/>
								<title-value-list-item
									v-else-if="shipment.loadingCountry"
									:title="$t('loading_country')"
									:value="shipment.loadingCountry.name ? $t(`$country.${shipment.loadingCountry.name}`) : ''"
									icon="mdi-earth-box"
								/>
							</v-col>

							<v-col cols="12" sm="6" lg="3" class="pb-0" v-if="isSeaShipment && (editMode || shipment.loadingPort)">
								<port-select
									v-if="editMode"
									v-model="editItem.loadingPort.code"
									:label="$t('loading_port')"
									:countryId="editItem.loadingCountry.id"
									:disabled="!editItem.loadingCountry.id"
									:eager="Boolean(editItem.loadingCountry.id)"
									:rules="rules.loadingPort"
									prepend-icon="mdi-location-exit"
									@change="loadingPortChangeAction"
								/>
								<title-value-list-item
									v-else-if="shipment.loadingPort"
									:title="$t('loading_port')"
									:value="shipment.loadingPort.name || ``"
									icon="mdi-location-exit"
								/>
							</v-col>

							<v-col cols="12" sm="6" lg="3" class="pb-0" v-if="!isSeaShipment && (editMode || shipment.loadingPoint)">
								<v-text-field
									v-if="editMode"
									v-model="editItem.loadingPoint"
									:label="$t('loading_point')"
									:rules="rules.loadingPoint"
									prepend-icon="mdi-arrow-down-bold-box-outline"
								/>
								<title-value-list-item
									v-else-if="shipment.loadingPoint"
									:title="$t('loading_point')"
									:value="shipment.loadingPoint"
									icon="mdi-arrow-down-bold-box-outline"
								/>
							</v-col>

							<v-col cols="12" sm="6" lg="3" class="pb-0" v-if="editMode || shipment.unloadingCountry.code">
								<country-select
									v-if="editMode"
									v-model="editItem.unloadingCountry.id"
									:options="countries"
									:label="$t('unloading_country')"
									:rules="rules.unloadingCountry"
									prepend-icon="mdi-earth-box"
									@change="unloadingCountryChangeAction"
								/>
								<title-value-list-item
									v-else-if="shipment.unloadingCountry"
									:title="$t('unloading_country')"
									:value="shipment.unloadingCountry.name ? $t(`$country.${shipment.unloadingCountry.name}`) : ''"
									icon="mdi-earth-box"
								/>
							</v-col>

							<v-col cols="12" sm="6" lg="3" class="pb-0" v-if="isSeaShipment && (editMode || shipment.unloadingPort)">
								<port-select
									v-if="editMode"
									v-model="editItem.unloadingPort.code"
									:label="$t('unloading_port')"
									:countryId="editItem.unloadingCountry.id"
									:disabled="!editItem.unloadingCountry.id"
									:eager="Boolean(editItem.unloadingCountry.id)"
									:rules="rules.unloadingPort"
									prepend-icon="mdi-location-enter"
									@change="unloadingPortChangeAction"
								/>
								<title-value-list-item
									v-else-if="shipment.unloadingPort"
									:title="$t('unloading_port')"
									:value="shipment.unloadingPort.name || ``"
									icon="mdi-location-enter"
								/>
							</v-col>

							<v-col
								cols="12"
								sm="6"
								lg="3"
								class="pb-0"
								v-if="!isSeaShipment && (editMode || shipment.unloadingPoint)"
							>
								<v-text-field
									v-if="editMode"
									v-model="editItem.unloadingPoint"
									:label="$t('unloading_point')"
									:rules="rules.unloadingPoint"
									prepend-icon="mdi-arrow-up-bold-box-outline"
								/>
								<title-value-list-item
									v-else-if="shipment.unloadingPoint"
									:title="$t('unloading_point')"
									:value="shipment.unloadingPoint"
									icon="mdi-arrow-up-bold-box-outline"
								/>
							</v-col>
						</v-row>
						<v-container v-else>
							<i18n path="$shipment.no_transportation_info" tag="p" for="edit" v-if="isLandShipment">
								<v-btn class="mx-2" @click="editAction">
									<v-icon left>mdi-file-edit</v-icon>
									{{ $t("edit") }}
								</v-btn>
							</i18n>
							<i18n path="$shipment.no_booking_info" tag="p" for="edit" v-else>
								<v-btn class="mx-2" @click="editAction">
									<v-icon left>mdi-file-edit</v-icon>
									{{ $t("edit") }}
								</v-btn>
							</i18n>
						</v-container>
					</div>
				</v-form>

				<v-divider v-if="!editMode && totalAmount != null" />

				<v-row v-if="!editMode && totalAmount != null">
					<v-col cols="12" class="pb-0">
						<title-value-list-item
							:value="
								totalAmount.toLocaleString($lang, {
									style: 'currency',
									currency: shipment.currency.code,
								})
							"
							:title="$t('total_amount')"
							icon="mdi-cash"
						/>
					</v-col>
				</v-row>

				<v-divider />

				<!-- Containers -->
				<div v-if="!editMode">
					<v-card elevation="1" class="mt-4">
						<v-toolbar flat dense>
							<v-toolbar-title>
								{{ $t(isLandShipment ? "vehicles" : "containers") }}
							</v-toolbar-title>
							<v-spacer />
							<v-btn v-if="!customsAgency" rounded small color="primary" elevation="0" @click="containerDialog.show()">
								{{ $t("add") }}
								<v-icon right> mdi-plus-circle </v-icon>
							</v-btn>
						</v-toolbar>
						<v-data-table
							:headers="containerListHeaders"
							:items="shipment.containers"
							:items-per-page="-1"
							:hide-default-header="!shipment.containers.length"
							hide-default-footer
							disable-sort
						>
							<template v-slot:item.content="{ item }">
								{{ getContainerContent(item) }}
							</template>
							<template v-slot:item.status="{ item }">
								{{ $t(`$shipment.$containerStatus.${item.status}`) }}
							</template>
							<template v-slot:item.estimatedArrivalDay="{ item }">
								{{ item.estimatedArrivalDay ? item.estimatedArrivalDay.format($t("$format.date")) : "" }}
							</template>
							<template v-slot:item.totalAmount="{ item }">
								{{
									item.totalAmount
										? item.totalAmount.toLocaleString($lang, {
												style: "currency",
												currency: shipment.currency.code,
											})
										: ""
								}}
							</template>
							<template v-slot:item.buttons="{ item }">
								<v-tooltip bottom open-delay="500" v-if="item.note">
									<template v-slot:activator="{ on }">
										<v-btn icon @click="containerNoteAction(item)" v-on="on">
											<v-icon small>mdi-notebook-outline</v-icon>
										</v-btn>
									</template>
									<span>{{ $t("note") }}</span>
								</v-tooltip>
								<v-tooltip bottom open-delay="500">
									<template v-slot:activator="{ on }">
										<v-btn icon @click="containerDetailDialog.show(item)" v-on="on">
											<v-icon small>mdi-magnify-expand</v-icon>
										</v-btn>
									</template>
									<span>{{ $t("detail") }}</span>
								</v-tooltip>
								<v-tooltip bottom open-delay="500">
									<template v-slot:activator="{ on }">
										<v-btn icon @click="containerDialog.show(item)" v-on="on">
											<v-icon small>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<span>{{ $t("edit") }}</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-card>
					<v-divider class="mt-4" />
				</div>

				<!-- Attachments -->
				<v-card elevation="1" class="mt-4" v-if="!editMode">
					<v-toolbar flat dense>
						<v-toolbar-title>
							{{ $t("attachments") }}
						</v-toolbar-title>
						<v-spacer />
						<v-btn rounded small color="primary" elevation="0" @click="attachmentDialog.show()">
							{{ $t("add") }}
							<v-icon right> mdi-plus-circle </v-icon>
						</v-btn>
					</v-toolbar>
					<v-data-table
						:headers="attachmentListHeaders"
						:items="shipment.attachments"
						:items-per-page="-1"
						:hide-default-header="!shipment.attachments.length"
						hide-default-footer
						disable-sort
					>
						<template v-slot:item.accessModifier="{ value }">
							{{ getVisibilityValue(value) }}
						</template>
						<template v-slot:item.buttons="{ item }">
							<div class="d-flex align-end">
								<v-tooltip bottom open-delay="500" v-if="item.note">
									<template v-slot:activator="{ on }">
										<v-btn icon @click="attachmentNoteAction(item)" v-on="on">
											<v-icon small>mdi-notebook-outline</v-icon>
										</v-btn>
									</template>
									<span>{{ $t("note") }}</span>
								</v-tooltip>

								<v-tooltip bottom open-delay="500">
									<template v-slot:activator="{ on }">
										<v-btn icon @click="handlePreviewFileAction(item)" v-on="on">
											<v-icon small>mdi-magnify-expand</v-icon>
										</v-btn>
									</template>
									<span>{{ $t("file_view") }}</span>
								</v-tooltip>

								<v-tooltip bottom open-delay="500">
									<template v-slot:activator="{ on }">
										<v-btn
											icon
											:loading="item.id == downloadingAttachmentId"
											:disabled="item.id == downloadingAttachmentId"
											@click="attachmentDownloadAction(item)"
											v-on="on"
										>
											<v-icon small>mdi-download-outline</v-icon>
										</v-btn>
									</template>
									<span>{{ $t("download") }}</span>
								</v-tooltip>

								<v-tooltip bottom open-delay="500" v-if="getEditableStatus(item)">
									<template v-slot:activator="{ on }">
										<v-btn icon @click="attachmentDialog.show(item)" v-on="on">
											<v-icon small>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<span>{{ $t("edit") }}</span>
								</v-tooltip>
							</div>
						</template>
					</v-data-table>
				</v-card>
				<file-view-dialog
					v-model="fileViewDialog.visibility"
					:item="fileViewDialog.item"
					@closed="fileViewDialog.closed"
				/>

				<v-divider v-if="!editMode" class="mt-4" />

				<v-subheader v-if="!editMode">{{ $t("actions") }}</v-subheader>

				<v-card class="pr-4" elevation="1">
					<shipment-action-timeline v-model="shipment" v-if="!editMode" />
				</v-card>

				<container-dialog
					v-model="containerDialog.visibility"
					:item="containerDialog.item"
					:shipment="shipment"
					@saved="containerDialog.saved"
					@deleted="containerDialog.deleted"
				/>

				<shipment-attachment-dialog
					v-model="attachmentDialog.visibility"
					:item="attachmentDialog.item"
					:shipmentId="shipment.id"
					:myRole="myRole"
					@saved="attachmentDialog.saved"
					@deleted="attachmentDialog.deleted"
				/>

				<container-detail-dialog
					v-model="containerDetailDialog.visibility"
					:item="containerDetailDialog.item"
					:shipment="shipment"
					:iAmOwner="iAmOwner"
					@changed="containerDetailDialog.changed"
					@closed="containerDetailDialog.closed"
				/>
				<send-email-dialog
					v-model="sendEmailDialog.visibility"
					:customerPartner="customerPartner"
					:shipmentId="shipment.id"
					@closed="sendEmailDialog.hide"
				/>
			</v-container>
		</template>
	</page-template>
</template>

<script>
import remote from "../../../data/remote";
import uiMixin from "../../../store/interfaces/ui.mixin";
import cloneDeep from "lodash.clonedeep";
import PageTemplate from "../../templates/PageTemplate";
import TitleValueListItem from "../../partials/TitleValueListItem";
import PartnerSelect from "../../controls/PartnerSelect";
import { mapState, mapGetters } from "vuex";
import ContainerDialog from "./dialog/ContainerDialog";
import ShipmentAttachmentDialog from "./dialog/ShipmentAttachmentDialog";
import CountrySelect from "../../controls/CountrySelect";
import PortSelect from "../../controls/PortSelect";
import CurrencySelect from "../../controls/CurrencySelect";
import ShippingTermSelect from "../../controls/ShippingTermSelect";
import ContainerDetailDialog from "./dialog/ContainerDetailDialog";
import SendEmailDialog from "./dialog/SendEmailDialog";
import ShipmentActionTimeline from "./partial/ShipmentActionTimeline";
import FileViewDialog from "../../partials/dialogs/FileViewDialog.vue";
import { iconHelper as iconHelperMixin } from "../../mixins/utils.mixin";
import { partnerTypes } from "../../../../src/utils/enum";

export default {
	components: {
		PageTemplate,
		TitleValueListItem,
		PartnerSelect,
		ContainerDialog,
		ShipmentAttachmentDialog,
		CountrySelect,
		PortSelect,
		CurrencySelect,
		ShippingTermSelect,
		ContainerDetailDialog,
		SendEmailDialog,
		ShipmentActionTimeline,
		FileViewDialog,
	},
	mixins: [uiMixin, iconHelperMixin],
	data() {
		return {
			shipment: null,
			editItem: null,
			editMode: false,
			valid: true,
			deleting: false,
			saving: false,
			downloadingAttachmentId: null,
			rules: {
				currency: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("currency") })],
				bookingCode: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", { item: this.$t("code") });
						} else if (v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("booking_code"),
								length: "25",
							});
						}
						return true;
					},
				],

				shippingTerm: [
					(v) =>
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", { item: this.$t("shipping_term") }),
				],
				loadingCountry: [
					(v) =>
						this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("loading_country"),
						}),
				],
				loadingPort: [
					(v) =>
						!this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", { item: this.$t("loading_port") }),
				],
				loadingPoint: [
					(v) => {
						if (!this.isSeaShipment && this.editItem.bookingCode && !Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("loading_point"),
							});
						} else if (v && v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("loading_point"),
								length: "100",
							});
						}
						return true;
					},
				],

				unloadingCountry: [
					(v) =>
						this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_country"),
						}),
				],
				unloadingPort: [
					(v) =>
						!this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_port"),
						}),
				],
				unloadingPoint: [
					(v) => {
						if (!this.isSeaShipment && this.editItem.bookingCode && !Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("unloading_point"),
							});
						} else if (v && v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("unloading_point"),
								length: "100",
							});
						}
						return true;
					},
				],
			},
			containerListHeaders: [
				{
					text: this.$t("$shortening.number"),
					value: "code",
					sortable: false,
				},
				{
					text: this.$t("content"),
					value: "content",
					sortable: false,
				},
				{
					text: this.$t("status"),
					value: "status",
					sortable: false,
				},
				{
					text: this.$t("estimated_arrival_day"),
					value: "estimatedArrivalDay",
					sortable: false,
				},
				{
					text: this.$t("total_amount"),
					value: "totalAmount",
					sortable: false,
				},
				{
					value: "buttons",
					align: "right",
					sortable: false,
					width: 145,
				},
			],
			containerDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.containerDialog.item = item;
					this.containerDialog.visibility = true;
				},
				hide: () => {
					this.containerDialog.item = null;
					this.containerDialog.visibility = false;
				},
				saved: () => {
					this.containerDialog.hide();
					this.showSnackBar({
						message: this.$t(this.containerDialog.item ? "$message.created" : "$message.updated", {
							item: this.$t("container"),
						}),
					});
					this.loadShipment(this.shipment.id);
				},
				deleted: () => {
					this.containerDialog.hide();
					this.showSnackBar({
						message: this.$t("$message.confirm", {
							function: this.$t("toDelete"),
						}),
					});
					this.loadShipment(this.shipment.id);
				},
			},
			containerDetailDialog: {
				visibility: false,
				item: null,
				hasChange: false,
				show: (item) => {
					this.containerDetailDialog.item = item;
					this.containerDetailDialog.visibility = true;
				},
				hide: () => {
					this.containerDetailDialog.item = null;
					this.containerDetailDialog.visibility = false;
					this.containerDetailDialog.hasChange = false;
				},
				changed: () => {
					this.containerDetailDialog.hasChange = true;
				},
				closed: () => {
					if (this.containerDetailDialog.hasChange) this.loadShipment(this.shipment.id);
				},
			},
			sendEmailDialog: {
				visibility: false,
				show: () => {
					this.sendEmailDialog.visibility = true;
				},
				hide: () => {
					this.sendEmailDialog.visibility = false;
				},
			},
			attachmentListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("visibility"),
					value: "accessModifier",
					sortable: false,
				},
				{
					value: "buttons",
					align: "right",
					sortable: false,
					width: 145,
				},
			],
			attachmentDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.attachmentDialog.item = item;
					this.attachmentDialog.visibility = true;
				},
				hide: () => {
					this.attachmentDialog.item = null;
					this.attachmentDialog.visibility = false;
				},
				saved: () => {
					this.attachmentDialog.hide();
					this.showSnackBar({
						message: this.$t(this.attachmentDialog.item ? "$message.created" : "$message.updated", {
							item: this.$t("attachment"),
						}),
					});
					this.loadAttachments();
				},
				deleted: () => {
					this.loadAttachments();
					this.attachmentDialog.hide();
					this.showSnackBar({
						message: this.$t("$message.deleted", {
							item: this.$t("attachment"),
						}),
					});
				},
			},
			fileViewDialog: {
				visibility: false,
				item: null,
				show(file) {
					this.item = file;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				closed: () => {
					this.fileViewDialog.hide();
				},
			},
		};
	},
	computed: {
		...mapState(["countries", "corporation"]),
		...mapGetters(["company", "customsAgency"]),
		partnerTypes: () => partnerTypes,
		isLandShipment() {
			return this.shipment.method === "land";
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		isSeaShipment() {
			return this.shipment.method === "sea";
		},
		iAmSenderCompany() {
			return this.shipment && this.company ? this.company.id === this.shipment.sender.company.referenceId : undefined;
		},
		iAmReceiverCompany() {
			return this.shipment && this.company ? this.company.id === this.shipment.receiver.company.referenceId : undefined;
		},
		iAmOwner() {
			if (this.iAmSenderCompany) {
				return this.shipment.sender.isOwner;
			} else if (this.iAmReceiverCompany) {
				return this.shipment.receiver.isOwner;
			}
			if (this.shipment && this.shipment.sender && this.shipment.receiver && this.company) {
				const isSenderOwner = this.shipment.sender.isOwner;
				const isReceiverOwner = this.shipment.receiver.isOwner;
				return isSenderOwner || isReceiverOwner;
			}
			return false;
		},
		iAmSenderCustomsAgency() {
			return this.shipment && this.customsAgency && this.shipment.sender.customsAgency
				? this.customsAgency.id === this.shipment.sender.customsAgency.customsAgencyId
				: undefined;
		},
		iAmReceiverCustomsAgency() {
			return this.shipment && this.customsAgency && this.shipment.receiver.customsAgency
				? this.customsAgency.id === this.shipment.receiver.customsAgency.customsAgencyId
				: undefined;
		},
		iAmAnySender() {
			return this.iAmSenderCompany || this.iAmSenderCustomsAgency;
		},
		iAmAnyReceiver() {
			return this.iAmReceiverCompany || this.iAmReceiverCustomsAgency;
		},
		isArchived() {
			if (this.iAmAnySender) return this.shipment.sender.accessibility == "archived";
			else if (this.iAmAnyReceiver) return this.shipment.receiver.accessibility == "archived";
		},
		totalAmount() {
			let result = 0;
			if (this.shipment.containers.length)
				for (const container of this.shipment.containers) {
					result += container.totalAmount ? container.totalAmount : 0;
				}
			return result;
		},
		showBookingArea() {
			return (
				this.editMode ||
				this.shipment.bookingCode ||
				(this.shipment.shippingTerm && this.shipment.shippingTerm.code) ||
				(this.shipment.loadingPort && this.shipment.loadingPort.code) ||
				(this.shipment.unloadingPort && this.shipment.unloadingPort.code) ||
				this.shipment.loadingPoint ||
				this.shipment.unloadingPoint ||
				(this.shipment.loadingCountry && this.shipment.loadingCountry.id) ||
				(this.shipment.unloadingCountry && this.shipment.unloadingCountry.id)
			);
		},
		myRole() {
			if (this.iAmSenderCompany) return "sender";
			if (this.iAmReceiverCompany) return "receiver";
			if (this.iAmSenderCustomsAgency) return "sender_customs_agency";
			if (this.iAmReceiverCustomsAgency) return "receiver_customs_agency";
		},
		customerPartner() {
			if (this.iAmSenderCompany) return this.shipment.receiver.company;
			else return this.shipment.sender.company;
		},
	},
	watch: {
		shipment(newValue) {
			// this.handleShipment(newValue);
			this.handleShipment();
		},
		"editItem.bookingCode"(val) {
			// if (!val) this.$refs.form.reset();
		},
		"editItem.loadingCountry.id"(val) {
			// console.log("EDIT ITEM LOADING COUNTRY ID", val);
		},
		"editItem.unloadingCountry.id"(val) {
			// console.log("EDIT ITEM UNLOADING COUNTRY ID", val);
		},
	},
	methods: {
		getContainerContent(item) {
			if (!item.products || !item.products.length) return "";
			let names = item.products.map((product) => product.name[this.$i18n.locale] || product.name._default);
			return names.join(" / ");
		},

		handlePreviewFileAction(item) {
			this.previewFileAction(item);
			// this.fileViewDialog.show(item);
		},
		previewFileAction(item) {
			if (!item || !item.file || !item.file.key) {
				console.error("Invalid file object passed:", item);
				return;
			}

			remote.files.get(item.file.key, {
				onSuccess: (result) => {
					const fileBlob = new Blob([result]);
					const fileUrl = window.URL.createObjectURL(fileBlob);
					this.fileViewDialog.show({
						url: fileUrl,
						type: item.file.type,
						name: item.name,
					});
				},
			});
		},
		loadShipment(id) {
			this.showProgressBar();
			remote.shipments.find(id, {
				onSuccess: (result) => {
					// this.handleShipment(result);
					this.shipment = result;
					this.hideProgressBar();
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},
		loadContainers() {
			remote.shipments.containers.filter(this.shipment.id, {
				onSuccess: (result) => (this.shipment.containers = result),
			});
		},
		containerTotalAmount(container) {
			let result = undefined;
			if ((container.products.length || container.expenses.length) && result == null) result = 0;
			for (const product of container.products) result += product.quantity * product.amount;
			for (const expense of container.expenses) result += expense.quantity * expense.amount;
			return result;
		},
		loadAttachments() {
			remote.shipments.attachments.filter(this.shipment.id, {
				onSuccess: (result) => (this.shipment.attachments = result),
			});
		},
		handleShipment() {
			// this.shipment = item;
			if (this.isLandShipment) this.containerListHeaders[0].text = this.$t("$shipment.$vehicle.license_number");
			let loadingCountry;
			if (this.shipment.loadingCountryId) {
				loadingCountry = this.countries.find((c) => c.id === this.shipment.loadingCountryId);
			} else if (this.shipment.loadingPort) {
				loadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.shipment.loadingPort.code));
			}
			this.shipment.loadingCountry = {
				id: loadingCountry ? loadingCountry.id : undefined,
				name: loadingCountry ? loadingCountry.name : undefined,
				code: loadingCountry ? loadingCountry.code : undefined,
			};

			let unloadingCountry;
			if (this.shipment.unloadingCountryId)
				unloadingCountry = this.countries.find((c) => c.id === this.shipment.unloadingCountryId);
			else if (this.shipment.unloadingPort)
				unloadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.shipment.unloadingPort.code));
			this.shipment.unloadingCountry = {
				id: unloadingCountry ? unloadingCountry.id : undefined,
				name: unloadingCountry ? unloadingCountry.name : undefined,
				code: unloadingCountry ? unloadingCountry.code : undefined,
			};

			if (this.shipment.containers.length)
				this.shipment.containers.forEach((c) => (c.totalAmount = this.containerTotalAmount(c)));
		},
		editAction() {
			this.editItem = cloneDeep(this.shipment);
			// console.log("this.editItem :>> ", this.editItem);

			if (this.iAmSenderCompany) {
				if (!this.shipment.sender.customsAgency) this.editItem.sender.customsAgency = { partnerId: null };
				// this.editItem.receiver.customsAgency = undefined;
				delete this.editItem.receiver.customsAgency;
			}
			if (this.iAmReceiverCompany) {
				if (!this.shipment.receiver.customsAgency) this.editItem.receiver.customsAgency = { partnerId: null };
				// this.editItem.sender.customsAgency = undefined;
				delete this.editItem.sender.customsAgency;
			}

			if (!this.editItem.shippingTerm) this.editItem.shippingTerm = { code: null };

			let loadingCountry;
			if (this.editItem.loadingPort && this.editItem.loadingPort.code) {
				loadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.editItem.loadingPort.code));
			} else {
				this.editItem.loadingPort = { code: null };
				loadingCountry = this.countries.find((c) => c.id === this.shipment.sender.company.countryId);
			}
			this.editItem.loadingCountry = cloneDeep(loadingCountry);

			let unloadingCountry;
			if (this.editItem.unloadingPort && this.editItem.unloadingPort.code) {
				unloadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.editItem.unloadingPort.code));
			} else {
				this.editItem.unloadingPort = { code: null };
				unloadingCountry = this.countries.find((c) => c.id === this.shipment.receiver.company.countryId);
			}
			this.editItem.unloadingCountry = cloneDeep(unloadingCountry);
			// console.log("this.editItem :>> ", this.editItem);
			this.editMode = true;
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.deleting = true;
					remote.shipments.delete(this.shipment.id, {
						onSuccess: (_) => {
							this.deleting = false;
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("shipment"),
								}),
							});
							if (this.$route.fullPath != this.$router.history._startLocation) this.$router.go(-1);
							else this.$router.replace({ name: "shipments" });
						},
					});
				},
			});
		},
		saveAction() {
			if (this.valid) {
				this.saving = true;
				remote.shipments.update(this.editItem, {
					onSuccess: (result) => {
						// this.handleShipment(result);
						this.shipment = result;
						this.saving = false;
						this.editMode = false;
						this.$editEvent("shipment");
						this.showSnackBar({
							message: this.$t("$message.updated", {
								item: this.$t("shipment"),
							}),
						});
					},
				});
			} else this.$refs.form.validate();
		},
		cancelAction() {
			this.editMode = false;
		},
		backAction() {
			this.$router.go(-1);
		},
		archiveAction() {
			if (this.isArchived) {
				remote.shipments.unarchive(this.shipment, {
					onSuccess: (result) => {
						// this.handleShipment(result);
						this.shipment = result;
						this.showSnackBar({
							message: this.$t("$shipment.un_archiving_success_message"),
						});
					},
				});
			} else {
				remote.shipments.archive(this.shipment, {
					onSuccess: (result) => {
						// this.handleShipment(result);
						this.shipment = result;
						this.showSnackBar({
							message: this.$t("$shipment.archiving_success_message"),
						});
					},
				});
			}
		},
		currencyChangeAction(val) {
			this.editItem.currency.name = val.name;
			this.editItem.currency.symbol = val.symbol;
		},
		customsPartnerChangeAction(val) {
			if (this.iAmSenderCompany)
				this.editItem.sender.customsAgency = {
					customsAgencyId: val ? val.corporationId : "",
					partnerId: val ? val.id : "",
					name: val ? val.name : "",
				};
			else if (this.iAmReceiverCompany)
				this.editItem.receiver.customsAgency = {
					customsAgencyId: val ? val.corporationId : "",
					partnerId: val ? val.id : "",
					name: val ? val.name : "",
				};
		},
		shippingTermChangeAction(val) {
			this.editItem.shippingTerm.name = val?.name ?? null;
		},
		loadingCountryChangeAction(_val) {
			this.editItem.loadingPort.code = null;
		},
		unloadingCountryChangeAction(_val) {
			this.editItem.unloadingPort.code = null;
		},
		loadingPortChangeAction(val) {
			this.editItem.loadingPort.portId = val.id;
			this.editItem.loadingPort.name = val.name;
			this.editItem.loadingPort.type = val.type;
		},
		unloadingPortChangeAction(val) {
			this.editItem.unloadingPort.portId = val.id;
			this.editItem.unloadingPort.name = val.name;
			this.editItem.unloadingPort.type = val.type;
		},
		attachmentDownloadAction(attachment) {
			this.downloadingAttachmentId = attachment.id;
			remote.files.get(attachment.file.key, {
				onSuccess: (result) => {
					this.downloadingAttachmentId = null;
					const url = window.URL.createObjectURL(new Blob([result]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", attachment.file.key); //or any other extension
					document.body.appendChild(link);
					link.click();
					link.remove();
				},
				onFail: (_) => (this.downloadingAttachmentId = null),
			});
		},
		containerNoteAction(container) {
			this.showAlertDialog({
				title: this.$t("note"),
				message: container.note,
				buttonText: this.$t("hide"),
			});
		},
		attachmentNoteAction(attachment) {
			this.showAlertDialog({
				title: this.$t("note"),
				message: attachment.note,
				buttonText: this.$t("hide"),
			});
		},
		getEditableStatus(attachment) {
			return attachment.ownerId == this.corporation.id;
		},
		getVisibilityValue(accessModifier) {
			if (this.iAmSenderCompany) {
				switch (accessModifier) {
					case "sender_private":
						return this.$t("$accessModifier.$description.private");
					case "sender_protected":
						return this.$t("$accessModifier.$description.shared_with_custom");
					case "sender_receiver":
						return this.$t("$accessModifier.$description.shared_with_receiver");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			} else if (this.iAmReceiverCompany) {
				switch (accessModifier) {
					case "receiver_private":
						return this.$t("$accessModifier.$description.private");
					case "receiver_protected":
						return this.$t("$accessModifier.$description.shared_with_custom");
					case "sender_receiver":
						return this.$t("$accessModifier.$description.shared_with_sender");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			} else if (this.iAmSenderCustomsAgency) {
				switch (accessModifier) {
					case "sender_protected":
						return this.$t("$accessModifier.$description.hidden_from_receiver");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			} else if (this.iAmReceiverCustomsAgency) {
				switch (accessModifier) {
					case "receiver_protected":
						return this.$t("$accessModifier.$description.hidden_from_sender");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			}

			return accessModifier;
			// if (accessModifier == "public")
			// 	return
		},
	},
	created() {
		this.loadShipment(this.$route.params.id);
	},
};
</script>
