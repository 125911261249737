<template>
	<v-menu v-model="state" :close-on-content-click="false" transition="scale-transition" min-width="auto">
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:prepend-icon="prependIconResource"
				:value="displayValue"
				:label="label"
				:rules="rules"
				readonly
				v-bind="attrs"
				v-on="on"
			/>
		</template>
		<v-date-picker
			v-model="pickerValue"
			year-icon="mdi-calendar-blank"
			@input="state = false"
			@change="changeAction"
			no-title
		/>
	</v-menu>
</template>

<script>
import moment from "moment";

export default {
	props: {
		value: Object,
		showPrependIcon: Boolean,
		prependIcon: String,
		label: String,
		rules: Array,
	},
	data() {
		return {
			state: false,
			pickerValue: null,
		};
	},
	computed: {
		prependIconResource() {
			return this.showPrependIcon ? this.prependIcon || "mdi-calendar-outline" : undefined;
		},
		displayValue() {
			return this.value ? this.value.format(this.$t("$format.date")) : "";
		},
	},
	methods: {
		handleProps() {
			if (this.value) {
				this.pickerValue = this.value.format("YYYY-MM-DD");
			}
		},
		changeAction(val) {
			this.$emit("input", val ? moment(val) : null);
		},
	},
	created() {
		this.handleProps();
	},
};
</script>

<style></style>
