<template>
	<ext-dialog
		v-model="value"
		:title="dialogTitle"
		:primaryButtonText="$t('save_changes')"
		@primaryButtonAction="saveAction"
		@closed="closeAction"
	>
		<template slot="body">
			<v-form ref="form" v-model="valid" v-if="currentItem">
				<localized-text-field
					v-model="currentItem.name"
					:defaultLanguage="companyDefaultLanguage"
					:options="companyLanguageOptions"
					:rules="rules.name"
					:context="'product'"
					:label="$t('name')"
				/>
				<v-row class="ma-0">
					<v-col cols="8" class="pa-0">
						<product-group-select
							v-model="currentItem.productGroupId"
							clearable
							:label="$t('product_group')"
							:eager="true"
							:rules="rules.productGroupId"
						/>
					</v-col>
					<v-col cols="4" class="pa-0 pl-4">
						<v-text-field v-model="currentItem.code" :label="$t('code')" dense :rules="rules.code" />
					</v-col>
				</v-row>

				<v-col cols="12" class="pa-0">
					<v-autocomplete
						:label="$t('units')"
						v-model="currentItem.unitCodes"
						multiple
						class="rounded-xl"
						closable-chips
						outlined
						:items="unitOptions"
						:rules="rules.unit"
						:item-text="(item) => $t(`$unit.${item.name}`)"
						item-value="code"
						:loading="!unitOptions"
					>
						<template slot="selection" slot-scope="{ item }">
							<v-chip close @click:close="removeItem(item)"> {{ $t(`$unit.${item.name}`) }} </v-chip>
						</template>
						<template slot="item" slot-scope="{ item }">
							<v-chip> {{ $t(`$unit.${item.name}`) }} </v-chip>
						</template>
					</v-autocomplete>
				</v-col>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import ProductGroupSelect from "../../../controls/ProductGroupSelect.vue";
import LocalizedTextField from "../../../controls/LocalizedTextField";
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import { mapGetters } from "vuex";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	props: { value: Boolean, item: Object },
	components: { ExtDialog, ProductGroupSelect, LocalizedTextField },
	mixins: [uiMixin],
	data() {
		return {
			itemTemplate: {
				name: {},
				code: "",
				units: [],
				unitCodes: [],
				productGroupId: null,
			},
			currentItem: null,
			rules: {
				name: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "100",
							});
						else return true;
					},
				],
				localizedName: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (Object.values(v).find((i) => i.length > 100))
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "100",
							});
						else return true;
					},
				],
				productGroupId: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("product_group") })],
				unit: [(v) => Boolean(v.length) || this.$t("$validation.required", { item: this.$t("unit") })],
				code: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("code") });
						else if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("code"),
								length: "20",
							});
						return true;
					},
				],
			},
			valid: true,
			unitOptions: [],
		};
	},
	computed: {
		...mapGetters(["companyDefaultLanguage", "companyLanguageOptions"]),
		dialogTitle() {
			return this.currentItem?.id ? this.$t("edit_product") : this.$t("add_new_product");
		},
	},
	watch: {
		value(val) {
			if (val) {
				const res = this.item || this.itemTemplate;
				if (!res.name[this.companyDefaultLanguage]) res.name[this.companyDefaultLanguage] = "";
				for (const language of this.companyLanguageOptions) if (!res.name[language]) res.name[language] = "";
				this.currentItem = Object.assign({}, res);
				this.currentItem.name = Object.assign({}, res.name);
				this.currentItem.unitCodes = [];
				for (const unit of this.currentItem.units) this.currentItem.unitCodes.push(unit.code);
			} else {
				this.$refs.form.reset();
				this.currentItem = null;
			}
		},
	},
	created() {
		this.loadUnits();
	},
	methods: {
		removeItem(item) {
			const index = this.currentItem.unitCodes.findIndex((u) => u === item.code);
			if (index > -1) {
				this.currentItem.unitCodes.splice(index, 1);
			}
		},
		loadUnits() {
			remote.structures.getUnits({
				onSuccess: (result) => (this.unitOptions = result.items),
			});
		},
		saveAction() {
			if (this.valid) {
				if (this.currentItem.name && this.currentItem.name[this.companyDefaultLanguage] === "") {
					this.showAlertDialog({
						message: `${this.$t("$validation.required_for_default_language", {
							item: this.$t("name"),
						})} (${this.$t(`$language.${this.companyDefaultLanguage}`)})`,
					});
				} else {
					this.currentItem.units = [];
					for (const unitCode of this.currentItem.unitCodes) {
						const unit = this.unitOptions.find((i) => i.code === unitCode);
						if (unit) this.currentItem.units.push(unit);
					}
					this.showProgressDialog();
					if (this.currentItem.id) {
						remote.products.update(this.currentItem, {
							onSuccess: (result) => {
								this.hideProgressDialog();
								this.showSnackBar({
									message: this.$t("$message.updated", {
										item: this.$t("product"),
									}),
								});
								this.$editEvent("product");
								this.$emit("saved", result);
							},
							onFail: () => this.hideProgressDialog(),
						});
					} else {
						remote.products.create(this.currentItem, {
							onSuccess: (result) => {
								this.hideProgressDialog();
								this.showSnackBar({
									message: this.$t("$message.created", {
										item: this.$t("product"),
									}),
								});
								this.$addEvent("product");
								this.$emit("saved", result);
							},
							onFail: () => this.hideProgressDialog(),
						});
					}
				}
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.products.delete(this.currentItem.id, {
						onSuccess: (result) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("product"),
								}),
							});
							this.$emit("deleted", result);
						},
					});
				},
			});
		},
		closeAction() {
			this.$emit("input", false);
		},
	},
};
</script>
