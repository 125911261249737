<template>
	<v-card elevation="1" class="mt-4">
		<v-toolbar flat dense>
			<v-toolbar-title>
				{{ $t("attachments") }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn rounded small color="primary" @click="attachmentDialog.show()">
				{{ $t("add") }}
				<v-icon right> mdi-plus-circle </v-icon>
			</v-btn>
		</v-toolbar>
		<v-data-table
			:headers="attachmentListHeaders"
			:items="proformaInvoice.attachments"
			:items-per-page="-1"
			hide-default-footer
			:no-data-text="$t('$proformaInvoice.no_attachment_text')"
			disable-sort
		>
			<template v-slot:item.buttons="{ item }">
				<div class="d-flex align-center">
					<v-tooltip bottom open-delay="500">
						<template v-slot:activator="{ on }" v-if="item.note">
							<v-btn icon @click="attachmentNoteAction(item)" v-on="on">
								<v-icon small>mdi-notebook-outline</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("note") }}</span>
					</v-tooltip>

					<v-tooltip bottom open-delay="500">
						<template v-slot:activator="{ on }">
							<v-btn icon @click="handlePreviewFileAction(item)" v-on="on">
								<v-icon small>mdi-magnify-expand</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("file_view") }}</span>
					</v-tooltip>

					<v-tooltip bottom open-delay="500">
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								:loading="item.id == downloadingAttachmentId"
								:disabled="item.id == downloadingAttachmentId"
								@click="attachmentDownloadAction(item)"
								v-on="on"
							>
								<v-icon small>mdi-download-outline</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("download") }}</span>
					</v-tooltip>

					<v-tooltip bottom open-delay="500" v-if="getEditableStatus(item)">
						<template v-slot:activator="{ on }">
							<v-btn icon @click="attachmentDialog.show(item)" v-on="on">
								<v-icon small>mdi-pencil</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("edit") }}</span>
					</v-tooltip>
				</div>
			</template>
		</v-data-table>
		<file-view-dialog v-model="fileViewDialog.visibility" :item="fileViewDialog.item" @closed="fileViewDialog.closed" />

		<proforma-invoice-attachment-dialog
			v-model="attachmentDialog.visibility"
			:item="attachmentDialog.item"
			:proformaInvoice="proformaInvoice"
			:myRole="iAmSender ? 'sender' : 'receiver'"
			@saved="attachmentDialog.saved"
			@deleted="attachmentDialog.deleted"
		/>
	</v-card>
</template>

<script>
import remote from "../../../../data/remote";
import FileViewDialog from "../../../partials/dialogs/FileViewDialog.vue";
import ProformaInvoiceAttachmentDialog from "../dialogs/ProformaInvoiceAttachmentDialog";
import { mapGetters } from "vuex";
import uiMixin from "../../../../store/interfaces/ui.mixin";

export default {
	props: { proformaInvoice: Object },
	components: {
		ProformaInvoiceAttachmentDialog,
		FileViewDialog,
	},
	mixins: [uiMixin],
	data() {
		return {
			fileViewDialog: {
				visibility: false,
				item: null,
				show(file) {
					this.item = file;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				closed: () => {
					this.fileViewDialog.hide();
				},
			},
			downloadingAttachmentId: null,
			attachmentListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("type"),
					value: "file.type",
					sortable: false,
				},
				{
					value: "buttons",
					align: "right",
					sortable: false,
					width: 145,
				},
			],
			attachmentDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.attachmentDialog.item = item;
					this.attachmentDialog.visibility = true;
				},
				hide: () => {
					this.attachmentDialog.item = null;
					this.attachmentDialog.visibility = false;
				},
				saved: (created) => {
					this.attachmentDialog.hide();
					this.showSnackBar({
						message: this.$t(created ? "$message.created" : "$message.updated", {
							item: this.$t("attachment"),
						}),
					});
					this.$emit("changed");
				},
				deleted: () => {
					this.attachmentDialog.hide();
					this.showSnackBar({
						message: this.$t("$message.deleted", {
							item: this.$t("attachment"),
						}),
					});
					this.$emit("changed");
				},
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
		iAmSender() {
			return this.company.id === this.proformaInvoice.sender.companyId;
		},
	},
	methods: {
		handlePreviewFileAction(item) {
			this.previewFileAction(item);
			this.fileViewDialog.show(item);
		},
		previewFileAction(file) {
			if (!file || !file.file || !file.file.key) {
				console.error("Invalid file object passed:", file);
				return;
			}

			remote.files.get(file.file.key, {
				onSuccess: (result) => {
					const fileBlob = new Blob([result]);
					const fileUrl = window.URL.createObjectURL(fileBlob);
					this.fileViewDialog.item = {
						url: fileUrl,
						type: file.file.type,
						name: file.name,
					};
					this.fileTitle = file.name;
					this.isModalOpen = true;
				},
			});
		},
		attachmentNoteAction(attachment) {
			this.showAlertDialog({
				title: this.$t("note"),
				message: attachment.note,
				buttonText: this.$t("hide"),
			});
		},
		attachmentDownloadAction(attachment) {
			this.downloadingAttachmentId = attachment.id;
			remote.files.get(attachment.file.key, {
				onSuccess: (result) => {
					this.downloadingAttachmentId = null;
					const url = window.URL.createObjectURL(new Blob([result]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", attachment.file.key); //or any other extension
					document.body.appendChild(link);
					link.click();
					link.remove();
				},
				onFail: (_) => (this.downloadingAttachmentId = null),
			});
		},
		getEditableStatus(attachment) {
			return attachment.ownerId == this.company.id;
		},
	},
};
</script>

<style></style>
