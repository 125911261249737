import ls from "local-storage";

const prefix = "extrimp";

const setValue = (key, value) => ls.set(`${prefix}__${key}`, value);

const getValue = (key, defaultValue) => ls.get(`${prefix}__${key}`) || defaultValue;

export const Setting = {
	locale: { key: "locale", type: "string" },
	dark_mode: { key: "dark_mode", type: "bool", default: false },
	data_table_page_size: { key: "data_table_page_size", type: "number" },
};

/**
 * @param {Object} value
 */
function setSettings(value) {
	setValue("settings", value);
}

function getSettings() {
	return getValue("settings", {});
}

/**
 * @param {Setting} key
 * @param {any} value
 */
export const setSetting = (setting, value) => {
	const settings = getSettings();
	if (setting.type === "string") settings[setting.key] = String(value);
	else if (setting.type === "bool") settings[setting.key] = Boolean(value);
	else if (setting.type === "number") settings[setting.key] = Number(value);
	else settings[setting.key] = value;
	setSettings(settings);
};

/**
 * @param {Setting} setting
 */
export const getSetting = (setting) => getSettings()[setting.key] || setting.default;

export const setTokenData = ({ accessToken, expiresAt, refreshToken }) => {
	return setValue("token_data", { accessToken, expiresAt, refreshToken });
};

export const getTokenData = () => {
	return getValue("token_data", {});
};
