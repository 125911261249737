<template>
	<v-container class="px-2 pb-0">
		<v-row justify="space-between">
			<v-col v-if="title" cols="auto">
				<v-card-title class="font-weight-bold px-0">
					{{ title }}
				</v-card-title>
			</v-col>
			<v-col v-if="showFilter" cols="auto">
				<v-btn class="filter-button my-4" outlined :color="filtered ? 'primary' : 'grey'" @click="filterButtonAction">
					<v-icon class="icon-filter pr-2" />
					{{ $t("filter") }}
				</v-btn>
			</v-col>
		</v-row>

		<v-card outlined elevation="0" class="ant-card py-2">
			<v-data-table
				:headers="modifiedHeaders"
				:items="items"
				:hide-default-footer="true"
				:loading="loading"
				:disable-sort="true"
				style="cursor: pointer"
				@click:row="handleRowClick"
			>
				<template #header.partnershipStatus>
					<div class="d-flex align-center">
						<span>{{ $t("partnership_status") }}</span>
						<v-tooltip bottom offset-y color="black">
							<template v-slot:activator="{ on, attrs }">
								<v-icon small class="ml-2" v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
							</template>
							<div class="custom-tooltip">
								<p>{{ $t("$partnershipStatusTooltip.description") }}</p>
								<b>{{ $t("$partnershipStatusTooltip.verifiedPartnersTitle") }}</b>
								{{ $t("$partnershipStatusTooltip.verifiedPartnersDescription") }}<br />
								<b>{{ $t("$partnershipStatusTooltip.createdHereTitle") }}</b>
								{{ $t("$partnershipStatusTooltip.createdHereDescription") }}
							</div>
						</v-tooltip>
					</div>
				</template>

				<template v-slot:item.product_id="{ item }">
					<slot name="item.product_id" :item="item">
						{{ item.product_id }}
					</slot>
				</template>
				<template #item.createdAt="{ item }">
					<slot name="item.createdAt" :item="item" />
				</template>

				<template #item.payment_amount="{ item }">
					<slot name="item.payment_amount" :item="item" />
				</template>

				<template #item.payment_status="{ item }">
					<slot name="item.payment_status" :item="item" />
				</template>

				<template #item.recurring_payment="{ item }">
					<slot name="item.recurring_payment" :item="item" />
				</template>

				<template #item.type="{ item }">
					<slot name="item.type" :item="item" />
				</template>
				<template #item.name="{ item }">
					<slot name="item.name" :item="item" />
				</template>
				<template v-slot:item.from="{ item }">
					<slot name="item.from" :item="item">
						{{ item.from }}
					</slot>
				</template>
				<template v-slot:item.to="{ item }">
					<slot name="item.to" :item="item">
						{{ item.to }}
					</slot>
				</template>
				<template v-slot:item.method="{ item }">
					<slot name="item.method" :item="item">
						{{ item.method }}
					</slot>
				</template>
				<template v-slot:item.date="{ item }">
					<slot name="item.date" :item="item">
						{{ item.date }}
					</slot>
				</template>
				<template v-slot:item.partnershipStatus="{ item }">
					<slot name="item.partnershipStatus" :item="item">
						{{ item.partnershipStatus }}
					</slot>
				</template>
				<template v-slot:item.status="{ item }">
					<slot name="item.status" :item="item">
						{{ item.status }}
					</slot>
				</template>

				<template #item.actions="{ item }">
					<div class="d-flex justify-end mr-2">
						<v-btn text @click.stop="deleteItem(item)">
							<v-icon class="icon-delete" size="large"></v-icon>
						</v-btn>
						<v-btn v-if="isAttachment" plain text @click.stop="downloadItem(item)">
							<v-icon class="icon-download" size="large"></v-icon>
						</v-btn>
					</div>
				</template>

				<template v-if="items.length === 0" #no-data>
					<div class="mt-10 mb-5">
						<v-icon class="icon-empty-state" size="28" color="black"></v-icon>
						<v-col>
							<p class="font-weight-bold mb-1 black--text">
								{{ $t("no_entity", { entityName: entityName }) }}
							</p>
							<p>{{ $t("no_entity_message", { entityName: entityName }) }}</p>
						</v-col>
					</div>
				</template>
			</v-data-table>
		</v-card>

		<v-card-actions class="my-4 pl-0">
			<v-row align="center" justify="end">
				<v-col>
					<slot name="actions">
						<v-btn v-if="buttonText" color="accent" outlined large elevation="0" @click="buttonAction">
							<v-icon class="icon-plus mr-2" start size="medium"> </v-icon>
							{{ buttonText }}
						</v-btn>
					</slot>
				</v-col>
				<v-col cols="auto" class="d-flex align-center pr-0">
					<label for="rows-per-page" class="mr-2">{{ $t("rows_per_page") }}</label>
					<v-select
						v-model="computedItemsPerPage"
						:items="itemsPerPageOptions"
						hide-details
						dense
						outlined
						class="mr-4 hide-select-input"
						:menu-props="{ offsetY: true }"
					/>

					<v-pagination v-model="computedPage" :length="Math.ceil(itemsLength / computedItemsPerPage)" circle />
				</v-col>
			</v-row>
		</v-card-actions>
	</v-container>
</template>

<script>
export default {
	props: {
		title: String,
		showFilter: { type: Boolean, default: false },
		filtered: { type: Boolean, default: false },
		headers: Array,
		items: Array,
		itemsLength: Number,
		itemsPerPageOptions: Array,
		loading: Boolean,
		options: Object,
		entityName: { type: String, default: "item" },
		isAttachment: { type: Boolean, default: false },
		buttonText: { type: String, default: null },
	},
	data() {
		return {
			currentTab: 0,
			currentTabContent: null,
		};
	},

	computed: {
		computedItemsPerPage: {
			get() {
				return this.options.itemsPerPage;
			},
			set(value) {
				this.$emit("update:options", { ...this.options, itemsPerPage: value, page: 1 });
			},
		},
		computedPage: {
			get() {
				return this.options.page;
			},
			set(value) {
				this.$emit("update:options", { ...this.options, page: value });
			},
		},
		modifiedHeaders() {
			return this.headers.map((header) => {
				if (header.value === "actions") {
					return { ...header, align: "end", class: "actions-column" };
				}
				return header;
			});
		},
	},
	watch: {
		itemsLength(newVal, oldVal) {
			const totalPages = Math.ceil(newVal / this.computedItemsPerPage) || 1;
			if (this.computedPage > totalPages) {
				this.computedPage = totalPages;
			} else if (totalPages === 0) {
				this.computedPage = 1;
			}
		},
	},
	methods: {
		handleRowClick(click, table) {
			this.$emit("row-click", table.item);
		},
		deleteItem(item) {
			this.$emit("delete-item", item);
		},
		buttonAction() {
			this.$emit("button-click");
		},
		filterButtonAction() {
			this.$emit("filter-button-click");
		},
	},
};
</script>

<style scoped>
.filter-button {
	border-radius: 4px !important;
	font-size: 18px !important;
	line-height: 20px !important;
	font-weight: normal !important;
	letter-spacing: 0px !important;
	/* color: rgb(142, 142, 142); */
	background-color: white !important;
}
</style>
