<template>
	<ext-dialog
		v-model="value"
		:title="$t('filter')"
		:primaryButtonText="$t('ok')"
		@primaryButtonAction="okButtonAction"
		:secondaryButtonText="$t('clear')"
		@secondaryButtonAction="clearButtonAction"
		@closed="cancelButtonAction"
	>
		<template slot="body">
			<v-text-field v-model="panelData.text" :label="`${$t('name')} / ${$t('code')}`" clearable />
			<product-group-select v-model="panelData.productGroupId" clearable :eager="true" :label="$t('product_group')" />
		</template>
	</ext-dialog>
</template>

<script>
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";
import ProductGroupSelect from "../../../controls/ProductGroupSelect";

export default {
	props: { value: Boolean, data: Object },
	components: { ExtDialog, ProductGroupSelect },
	data() {
		return { panelData: Object };
	},
	watch: {
		value(val) {
			if (val) {
				this.panelData.text = this.data.text;
				this.panelData.productGroupId = this.data.productGroupId;
			}
		},
	},
	methods: {
		okButtonAction() {
			this.$emit("done", {
				text: this.panelData.text || undefined,
				productGroupId: this.panelData.productGroupId || undefined,
			});
		},
		clearButtonAction() {
			this.$emit("done", {
				text: undefined,
				productGroupId: undefined,
			});
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
	},
};
</script>
