<template>
	<v-container class="background-container" tag="section" aria-label="Introduction Section">
		<v-row class="d-flex align-center justify-center mt-16">
			<v-col cols="12" md="5" tag="header" :class="isMobile ? 'mt-4' : ''" class="px-6">
				<v-row :class="[{ 'justify-center text-center': isMobile }]">
					<h1 :class="isMobile ? 'v-typography--title' : 'v-typography--title4'">
						{{ $t(`$landing.$home.title`) }}
					</h1>
				</v-row>
				<v-row :class="[{ 'justify-center text-center': isMobile }]">
					<h2 :class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'" class="my-2">
						{{ $t(`$landing.$home.text`) }}
					</h2>
				</v-row>
				<v-row :class="[{ 'justify-center': isMobile }]">
					<v-btn
						class="consult-button mt-4"
						x-large
						color="secondary"
						@click="goToContact"
						aria-label="Consult us button"
					>
						{{ $t("consult_us") }}
					</v-btn>
				</v-row>
			</v-col>

			<v-col cols="12" md="7" tag="aside">
				<figure class="video-wrapper" @click="playVideo" aria-label="Antrego introduction video">
					<img
						v-if="!videoPlaying"
						src="~@/assets/antrego_video.png"
						class="cover-image"
						alt="Antrego Introduction Video Thumbnail"
					/>
					<iframe
						v-if="videoPlaying"
						src="https://www.youtube.com/embed/QXB4C6jvRio?autoplay=1"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
						class="video-iframe"
					/>
				</figure>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: { isMobile: Boolean },
	data() {
		return {
			videoPlaying: false,
		};
	},
	methods: {
		playVideo() {
			this.videoPlaying = true;
		},
		goToContact() {
			this.$nextTick(() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" }));
		},
	},
};
</script>

<style scoped>
.background-container {
	background-image: url("~@/assets/home_bg.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@media (max-width: 600px) {
	.video-wrapper {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%;
		height: 0;
	}

	.cover-image {
		width: 100%;
		height: auto;
		border-radius: 8px !important;
	}

	.video-iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.consult-button {
		width: 70%;
		height: 50px;
		padding: 16px;
		margin: 0 auto;
	}
}

.consult-button {
	background-color: #cc2828;
	color: white;
	text-transform: none;
	width: 15rem;
	height: 3.5rem;
	border-radius: 10px;
}

.small-button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.8rem;
}

.video-wrapper {
	width: 100%;
	padding-bottom: 56.25%;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	margin-top: 2rem;
	height: 0;
}

.cover-image {
	width: 100%;
	height: auto;
	object-fit: contain;
	border-radius: 10px;
}

.video-iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.v-container {
	max-width: 100%;
	overflow-x: hidden;
}

.mobile-title {
	font-size: 1rem;
}
</style>
