<template>
	<page-template>
		<!-- 	<template slot="title">{{ $t("product_group") }}</template> -->
		<template slot="content">
			<div class="mx-4">
				<v-col cols="auto" class="d-flex align-center">
					<v-card-title class="pa-0 mt-2 mx-3 d-flex justify-start font-weight-bold">
						{{ $t("product_groups") }}
					</v-card-title>
				</v-col>
				<v-card outlined elevation="0" class="mt-1 mx-6 ant-card">
					<div class="d-flex align-center mt-6 ml-12">
						<v-row>
							<v-col cols="4">
								<p class="grey--text v-typography--table-header">{{ $t(`$productGroup.name`) }}</p>
							</v-col>
							<v-col cols="4" class="text-center">
								<p class="grey--text v-typography--table-header">
									{{ $t(`$productGroup.number_of_product`) }}
								</p>
							</v-col>
							<v-col cols="4" class="d-flex justify-end pr-10">
								<p class="grey--text v-typography--table-header">{{ $t("actions") }}</p>
							</v-col>
						</v-row>
					</div>
					<v-treeview
						open-all
						:items="productGroupTree.children"
						expand-icon="mdi-chevron-down"
						v-if="productGroupTree && productGroupTree.children.length"
						hoverable
					>
						<template v-slot:label="{ item }">
							<v-row @click="dialog.show(item)" style="cursor: pointer">
								<v-col cols="4" class="pa-6">
									{{ item.name[$lang] || item.name["_default"] }}
								</v-col>
								<v-col cols="4" class="text-center pa-6">
									{{ item.productCount }}
								</v-col>
								<v-col cols="4" class="d-flex justify-end">
									<v-btn text class="pa-6 mr-5" @click.stop="deleteItem(item)">
										<v-icon class="icon-delete" size="large"></v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-divider />
						</template>
					</v-treeview>
					<div
						v-else-if="productGroupTree"
						class="mt-10 mb-5 d-flex flex-column align-center justify-center text-center"
					>
						<v-icon class="icon-empty-state mb-2" size="28" color="black"></v-icon>
						<v-col>
							<p class="font-weight-bold mb-1 black--text">
								{{ $t("no_entity", { entityName: $t("product_group") }) }}
							</p>
							<p>{{ $t("no_entity_message", { entityName: $t("product_group") }) }}</p>
						</v-col>
					</div>
				</v-card>
			</div>

			<v-btn
				v-if="productGroupTree && productGroupTree.children.length"
				class="mt-6 mx-10"
				color="accent"
				outlined
				large
				elevation="0"
				@click="dialog.show()"
			>
				<v-icon class="icon-plus mr-2" start size="medium"> </v-icon>
				{{ $t("$productGroup.add_new_product_group") }}
			</v-btn>
			<product-group-dialog
				v-model="dialog.visibility"
				:item="dialog.item"
				:productGroupTree="productGroupTree"
				:maxParentCount="maxParentCount"
				@saved="dialog.saved"
				@closed="dialog.closed"
				@deleted="dialog.deleted"
			/>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../store/interfaces/ui.mixin";
import PageTemplate from "../templates/PageTemplate";
import ProductGroupDialog from "../partials/dialogs/ProductGroupDialog.vue";
import remote from "../../data/remote";

export default {
	mixins: [uiMixin],

	components: {
		PageTemplate,
		ProductGroupDialog,
	},

	data() {
		return {
			maxParentCount: 5,
			productGroupTree: null,

			dialog: {
				visibility: false,
				item: null,
				show(item = null) {
					this.item = item;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: () => {
					this.loadProductGroupTree();
					this.dialog.hide();
				},
				closed: () => {
					this.dialog.hide();
				},
				deleted: () => {
					this.loadProductGroupTree();
					this.dialog.hide();
				},
			},
		};
	},

	created() {
		this.$trackPageView(this.$route.path);
		this.loadProductGroupTree();
	},

	methods: {
		deleteItem(item) {
			if (item.children && item.children.length > 0) {
				this.showAlertDialog({
					message: this.$t("$productGroup.has_children_fail_message"),
				});
				return;
			}

			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.removeItemFromTree(this.productGroupTree, item.id);
					this.deleting = true;
					remote.productGroups.delete(item.id, {
						onSuccess: () => {
							this.$emit("deleted");
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("product_group"),
								}),
							});
						},
						onFail: (error) => {
							if (error) console.error(error);
						},
					});
					this.deleting = false;
				},
			});
		},
		loadProductGroupTree() {
			remote.productGroups.getMyProductGroupTree({
				onSuccess: (result) => {
					this.productGroupTree = result;
					this.handleParentCount(this.productGroupTree, 0);
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},
		handleParentCount(productGroupTree, level) {
			productGroupTree.level = level;
			if (level === 5) productGroupTree.id = productGroupTree._id;
			if (productGroupTree.children) level++;
			for (const child of productGroupTree.children) {
				this.handleParentCount(child, level);
				if (level > 0 && level < 4) productGroupTree.productCount += child.productCount;
			}
		},
	},
};
</script>

<style scoped>
.custom-add-btn {
	border: 1.6px solid #005d8f;
	color: #005d8f;
}
</style>
