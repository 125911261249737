<template>
	<v-container class="fill-height">
		<v-row align="center" justify="center">
			<v-col class="text-center" cols="12">
				<h1 class="display-1 font-weight-thin my-4">
					{{
						$t("$home.$noCompany.title", {
							name: `${user.firstName} ${user.lastName}`,
						})
					}}
				</h1>
				<h3 class="font-weight-thin mb-0 mb-sm-10">
					{{ $t("$home.$noCompany.description") }}
				</h3>
			</v-col>

			<v-col cols="1" class="d-none d-sm-flex" />

			<v-col cols="12" sm="5">
				<v-card class="ant-card mx-auto" max-width="500" @click="invitationCodeDialog.show()">
					<v-img src="@/assets/home_img_3.jpg" height="240px" />
					<v-card-title>
						{{ $t("$home.$noCompany.join_company_title") }}
					</v-card-title>
					<v-card-subtitle style="height: 60px">
						{{ $t("$home.$noCompany.join_company_subtitle") }}
					</v-card-subtitle>
				</v-card>
			</v-col>

			<v-col cols="12" sm="5">
				<v-card class="ant-card mx-auto" max-width="500" @click="createCorporationDialog.show()">
					<v-img src="@/assets/home_img_4.jpg" height="240px" />
					<v-card-title>
						{{ $t("$home.$noCompany.create_company_title") }}
					</v-card-title>
					<v-card-subtitle style="height: 60px">
						{{ $t("$home.$noCompany.create_company_subtitle") }}
					</v-card-subtitle>
				</v-card>
			</v-col>

			<v-col cols="1" class="d-none d-sm-flex" />
		</v-row>

		<invitation-code-dialog v-model="invitationCodeDialog.visibility" @succeeded="invitationCodeDialog.succeeded" />

		<create-corporation-dialog
			v-model="createCorporationDialog.visibility"
			@created="createCorporationDialog.created"
			:user="user"
		/>
	</v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateCorporationDialog from "./dialog/CreateCorporationDialog.vue";
import InvitationCodeDialog from "./dialog/InvitationCodeDialog.vue";

export default {
	components: { CreateCorporationDialog, InvitationCodeDialog },
	data() {
		return {
			invitationCodeDialog: {
				visibility: false,
				show: () => {
					this.invitationCodeDialog.invitationCode = null;
					this.invitationCodeDialog.visibility = true;
				},
				hide: () => {
					this.invitationCodeDialog.visibility = false;
				},
				succeeded: () => {
					this.invitationCodeDialog.hide();
					this.$router.go();
				},
			},
			createCorporationDialog: {
				visibility: false,
				show: () => {
					this.createCorporationDialog.visibility = true;
				},
				hide: () => {
					this.createCorporationDialog.visibility = false;
				},
				created: (_a) => {
					console.log("object", _a);
					this.createCorporationDialog.hide();
					this.$router.go();
				},
			},
		};
	},
	computed: {
		...mapState("auth", ["user"]),
	},
	methods: {
		...mapActions("auth", ["signOut"]),
		companyProfileAction() {
			this.$router.push({ name: "companyProfile" });
		},
	},
};
</script>

<style>
.v-card__title {
	word-break: break-word;
}
</style>
