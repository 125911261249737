<template>
	<v-card>
		<v-card-title :class="getTitleStyle">
			<v-row justify="center" class="pa-4">
				<v-icon x-large :color="getColor">
					{{ getIcon }}
				</v-icon>
			</v-row>
		</v-card-title>

		<v-card-title :class="getTitleStyle">
			<v-row v-if="isPaymentSuccess" justify="center" class="pa-4">
				{{ $t("payment_success") }}
			</v-row>
			<v-row v-else-if="isPaymentOk" justify="center" class="pa-4">
				{{ $t("payment_awaited") }}
			</v-row>
			<v-row v-else justify="center" class="pa-4">
				{{ $t("operation_fail") }}
			</v-row>
		</v-card-title>

		<v-card-subtitle v-if="isPaymentSuccess" class="subtitle-1 pa-8">
			{{ $t(`$subscription.$message.success`) }}
		</v-card-subtitle>
		<v-card-subtitle v-else-if="isPaymentOk" class="subtitle-1 pa-8">
			{{ $t(`$subscription.$message.ok`) }}
		</v-card-subtitle>
		<v-card-subtitle v-else class="subtitle-1 pa-8">
			{{ $t("$subscription.$message.fail") }}
		</v-card-subtitle>
		<v-divider class="ma-4" />
		<v-card-text class="subtitle-1 px-8">
			<v-row justify="center">
				<v-col> {{ $t("transaction_no") }} </v-col>
				<v-col> {{ oid }} </v-col>
			</v-row>
		</v-card-text>

		<v-card-actions class="py-16">
			<v-spacer />
			<v-btn v-if="isPaymentSuccess" color="accent" @click="goToHomeAction">
				{{ $t("home_page") }}
				<v-icon right> mdi-home-outline </v-icon>
			</v-btn>
			<v-btn v-else-if="isPaymentOk" color="accent" @click="goToHistoryAction">
				{{ $t("payment_history") }}
				<v-icon right> mdi-clipboard-text-clock </v-icon>
			</v-btn>
			<v-btn v-else color="accent" @click="feedbackAction">
				{{ $t("send_feedback") }}
				<v-icon right> mdi-comment-quote-outline </v-icon>
			</v-btn>
			<v-spacer />
		</v-card-actions>
	</v-card>
</template>

<script>
import remote from "../../../../data/remote";
import { paymentStatus } from "../../../../utils/enum";

export default {
	props: { oid: String, product: String },
	data() {
		return {
			payment: null,
		};
	},
	computed: {
		getTitleStyle() {
			return this.isPaymentSuccess
				? "success--text text-h4"
				: this.isPaymentOk
					? "warning--text text-h4"
					: "error--text text-h4";
		},
		getColor() {
			return this.isPaymentSuccess ? "success" : this.isPaymentOk ? "warning" : "error";
		},
		getIcon() {
			return this.isPaymentSuccess
				? "mdi-check-circle-outline"
				: this.isPaymentOk
					? "mdi-clock-outline"
					: "mdi-close-circle-outline";
		},
		isPaymentSuccess() {
			return this.payment && this.payment.payment_status === paymentStatus.Success ? true : false;
		},
		isPaymentOk() {
			return this.payment && this.payment.payment_status === paymentStatus.InProgress ? true : false;
		},
	},
	methods: {
		loadPaymentInfo() {
			remote.payment.find(this.oid, {
				onSuccess: (result) => {
					this.payment = result;
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},
		goToHomeAction() {
			this.$router.push({ name: "home" });
		},
		goToHistoryAction() {
			this.$router.push({ name: "companyProfile" });
		},
		feedbackAction() {
			this.$router.push({ name: "companyProfile" });
		},
	},
	created() {
		this.loadPaymentInfo();
	},
};
</script>
