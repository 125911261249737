import { render, staticRenderFns } from "./LandingContact.vue?vue&type=template&id=7b1a8c56&scoped=true"
import script from "./LandingContact.vue?vue&type=script&lang=js"
export * from "./LandingContact.vue?vue&type=script&lang=js"
import style0 from "./LandingContact.vue?vue&type=style&index=0&id=7b1a8c56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1a8c56",
  null
  
)

export default component.exports