import Model from "../_model";

export default class SystemEnvironmentModel extends Model {
	constructor(provider) {
		super(provider, "antrego/system_environment/?type={type}");
	}

	_getUrl(type) {
		let url = this.apiMethod.replace("{type}", type);
		return url;
	}

	find(type, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(type),
			onSuccess,
			onFail,
		});
	}
}
