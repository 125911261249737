<template v-slot:extension>
	<v-container class="ma-0">
		<v-card v-if="!loading && hasProgressPayment" class="my-4 px-4 rounded-xl" elevation="0" outlined color="info">
			<v-card-text class="background--text">
				<v-icon color="background">mdi-cash-clock</v-icon>
				{{ $t("$subscription.$message.payment_process_is_ongoing") }}
			</v-card-text>
		</v-card>

		<v-card v-if="!loading && lastPaymentHasError" class="my-4 px-4 rounded-xl" elevation="0" outlined color="error">
			<v-card-text class="white--text">
				<v-icon class="mr-2" color="white">mdi-information-variant-circle-outline</v-icon>
				{{ $t("$subscription.$message.check_your_payment_method") }}
			</v-card-text>
		</v-card>

		<v-card
			v-else-if="!loading && expiredSubscription"
			class="my-4 px-4 rounded-xl"
			elevation="0"
			outlined
			color="info"
		>
			<v-card-text>
				<v-icon class="mr-2">mdi-information-variant-circle-outline</v-icon>
				{{ $t("$subscription.$message.try_to_get_paid_again") }}
			</v-card-text>
		</v-card>
		<!-- TODO: Try to get repaid again action -->
		<!-- <v-card v-else-if="!loading && expiredSubscription" class="my-4 pa-4" color="info">
			<v-card-text>
				<v-icon class="mr-2">mdi-information-variant-circle-outline</v-icon>
				{{ $t("$subscription.$message.try_to_get_paid_again") }}
			</v-card-text>
		</v-card> -->

		<v-card v-else-if="subscriptionItems" class="my-4 ant-card" outlined elevation="0">
			<v-card-text class="pa-6">
				<v-row justify="space-between" align="center">
					<v-col cols="6" class="pa-2">
						<span class="text-body-2 font-weight-bold">{{ $t("current_plan") }}</span>
						<v-card-title class="font-weight-bold mt-2 text-capitalize black--text pa-0">
							{{ getAntProductName }} {{ $t("subscription") }}
						</v-card-title>
						<div class="text-body-2 mt-1">
							{{ formattedDate(subscriptionInfo.createdAt) }} – {{ formattedDate(subscriptionInfo.expiresAt) }}
						</div>
					</v-col>

					<v-col cols="3" class="pa-0">
						<span class="text-body-2 font-weight-bold">{{ $t("payment") }}</span>
						<div class="font-weight-bold mt-2">${{ paymentHistory?.[0]?.payment_amount || 0 }}</div>
						<div class="text-body-2 mt-1">{{ subscriptionPeriod }}</div>
					</v-col>

					<v-col cols="3" class="text-right pa-0">
						<v-card-text v-if="!hasSubscription" class="warning--text">
							{{ $t("you_dont_have_any_active_subscription") }}
						</v-card-text>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- <v-card v-if="subscriptionItems" class="my-4 px-4 rounded-xl" elevation="0" outlined>
			<v-row>
				<v-col cols="12" sm="3" md="5" lg="4" xl="4">
					<v-card-subtitle class="text-body-2 font-weight-bold px-0">{{ $t("current_plan") }}</v-card-subtitle>
					<title-value-list-item :title="$t('name')" :value="getAntProductName" />
				</v-col>
				<v-col cols="12" sm="3" md="2" lg="3" xl="3">
					<title-value-list-item :title="$t('start_time')" :value="formattedDate(subscriptionInfo.createdAt)" />
				</v-col>
				<v-col cols="12" sm="3" md="2" lg="3" xl="3">
					<title-value-list-item :title="$t('expires_at')" :value="formattedDate(subscriptionInfo.expiresAt)" />
				</v-col>
				<v-col cols="12" sm="3" md="3" lg="2" xl="2">
					<v-btn
						v-if="hasSubscription"
						:class="$vuetify.breakpoint.xsOnly ? 'mb-4 warning' : 'my-4 warning'"
						elevation="1"
						text
						:disabled="loading"
						@click="unsubscribeAction"
					>
						{{ $t("unsubscribe") }}
					</v-btn>
					<v-card-text v-else class="warning--text">
						{{ $t("you_dont_have_any_active_subscription") }}
					</v-card-text>
				</v-col>
			</v-row>
		</v-card> -->

		<v-card v-if="savedCardInfo" class="my-4 px-4 rounded-xl" elevation="0" outlined>
			<v-card-title>
				{{ $t("saved_card_info") }}
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="3" class="py-0">
						<title-value-list-item :title="$t('name')" :value="savedCardInfo.c_name" />
					</v-col>
					<v-col cols="12" sm="3" class="py-0">
						<title-value-list-item :title="$t('bank')" :value="savedCardInfo.c_bank" />
					</v-col>
					<v-col cols="12" sm="3" class="py-0">
						<title-value-list-item :title="$t('credit_card_no')" :value="getCardNo" />
					</v-col>
					<v-col cols="12" sm="3" class="py-0">
						<title-value-list-item :title="$t('date')" :value="getCardExpireDate" />
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<template v-if="paymentHistory && paymentHistory.length > 0">
			<v-card-title>
				{{ $t("payment_history") }}
			</v-card-title>
			<common-table
				:headers="headers"
				:items="paymentHistory"
				:options.sync="tableOptions"
				:items-length="pagination.totalItemCount"
				:items-per-page="tableOptions.itemsPerPage"
				:items-per-page-options="tableFooterProps.itemsPerPageOptions"
				:footer-props="tableFooterProps"
				:loading="loading"
				style="cursor: pointer"
				:entity-name="$t('transactions')"
				disable-sort
			>
				<template #item.product_id="{ item }">
					{{ getProductName(item.product_id) }}
				</template>
				<template #item.createdAt="{ item }">
					{{ item.createdAt.format($t("$format.date_time")) }}
				</template>
				<template #item.payment_amount="{ item }"> {{ item.payment_amount }} {{ item.currency }} </template>
				<template #item.payment_status="{ item }">
					{{ getPaymentStatusText(item.payment_status) }}
				</template>
				<template #item.recurring_payment="{ item }">
					{{ item.recurring_payment == 1 ? $t("renewal") : $t("purchase") }}
				</template>
			</common-table>
		</template>
	</v-container>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import TitleValueListItem from "../../partials/TitleValueListItem";
import { mapGetters } from "vuex";
import { paymentStatus } from "../../../utils/enum";
import { pagination as paginationMixin } from "../../mixins/utils.mixin";
import { antProductProductTypes } from "../../../utils/enum";
import CommonTable from "../../partials/CommonTable.vue";

export default {
	mixins: [paginationMixin, uiMixin],
	components: { TitleValueListItem, CommonTable },
	data() {
		return {
			loading: false,
			beingPayback: false,
			subscriptionInfo: null,
			subscriptionItems: null,
			savedCardInfo: null,
			antProducts: null,
			paymentHistory: null,
			headers: [
				// { text: this.$t("NO"), value: "merchant_oid" },
				{ text: this.$t("name"), value: "product_id" },
				{ text: this.$t("amount"), value: "payment_amount" },
				{ text: this.$t("status"), value: "payment_status" },
				{ text: this.$t("type"), value: "recurring_payment" },
				{ text: this.$t("date"), value: "createdAt" },
				/* 	{ value: "buttons", align: "right", sortable: false, width: 145 }, */
			],
		};
	},
	computed: {
		...mapGetters(["hasSubscription", "company"]),
		lastPayment() {
			return this.paymentHistory?.length > 0 ? this.paymentHistory[0] : null;
		},
		expiredSubscription() {
			return this.hasSubscription && this.subscriptionInfo?.expiresAt <= new Date() ? true : false;
		},
		lastPaymentHasError() {
			return this.lastPayment ? this.lastPayment.payment_status == paymentStatus.Failed : false;
		},
		hasProgressPayment() {
			return this.lastPayment ? this.lastPayment.payment_status == paymentStatus.InProgress : false;
		},
		getCardNo() {
			return "**** **** **** " + this.savedCardInfo.last_4;
		},
		getCardExpireDate() {
			return this.savedCardInfo.month + " / " + this.savedCardInfo.year;
		},
		getAntProductName() {
			var antProduct;
			this.subscriptionItems.find((p) => {
				if (p.antProduct.type === antProductProductTypes.Actual) antProduct = p.antProduct;
			});
			return antProduct ? `${this.$t(antProduct.category)}/${this.$t(antProduct.name)}` : "-";
		},
		subscriptionPeriod() {
			if (!this.subscriptionInfo) return "";
			return this.subscriptionInfo.subscriptionItems[0].antProduct.name === "monthly"
				? this.$t("monthly")
				: this.$t("yearly");
		},
	},
	methods: {
		loadSubscriptionInfo() {
			this.loading = true;
			remote.companies.getMySubscription({
				onSuccess: (result) => {
					this.subscriptionInfo = result.hasValue === false ? null : result;
					this.subscriptionItems = this.subscriptionInfo?.subscriptionItems;
					this.loading = false;
				},
				onFail: (error) => {
					if (error) console.error(error);
					this.loading = false;
				},
			});
		},

		loadItems() {
			this.loading = true;
			remote.companies.getMyPaymentHistory(
				{
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
							}
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.paymentHistory = result.items;
						this.pagination = result.pagination;
						this.loading = false;
					},
					onFail: (error) => {
						if (error) console.error(error);
						this.loading = false;
					},
				}
			);
		},

		loadAntProducts() {
			remote.antProduct.filter({
				onSuccess: (result) => (this.antProducts = result.items),
				onFail: (error) => console.error(error),
			});
		},

		unsubscribeAction() {
			this.showConfirmDialog({
				message: this.$t("unsubscribe_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();

					remote.companies.getUnsubscribe({
						onSuccess: (_) => {
							this.hideProgressDialog();
							this.loadSubscriptionInfo();
							this.showSnackBar({ message: this.$t("$subscription.$message.unsubscribed") });
						},
						onFail: (error) => {
							if (error) console.error(error);
							this.hideProgressDialog();
							this.loadSubscriptionInfo();
						},
					});
				},
			});
		},

		availableForPayback(item) {
			return (
				this.lastPayment.merchant_oid == item.merchant_oid &&
				(item.payment_status === paymentStatus.Success || item.payment_status === paymentStatus.InProgress)
			);
			// return this.subscription.merchantOid === item.merchant_oid;
		},

		// repaidAction(item) {
		// 	this.showConfirmDialog({
		// 		message: this.$t("payback_confirm_message") + " " + this.$t("payback_warning_message"),
		// 		onConfirm: () => {
		// 			this.beingPayback = true;
		// 			remote.payment.payback(item.merchant_oid, {
		// 				onSuccess: (result) => {
		// 					this.beingPayback = false;
		// 					this.loadItems();
		// 					this.loadSubscriptionInfo();
		// 					this.showSnackBar({
		// 						message: this.$t("$message.process_has_been_started", {
		// 							item: this.$t("payback"),
		// 						}),
		// 					});
		// 				},
		// 				onFail: () => (this.beingPayback = false),
		// 			});
		// 		},
		// 	});
		// },

		formattedDate(date) {
			return date ? date.format(this.$t("$format.date")) : "";
		},

		getPaymentStatusText(val) {
			switch (val) {
				case paymentStatus.Repaid:
					return this.$t("repaid");
				case paymentStatus.Failed:
					return this.$t("failed");
				case paymentStatus.InProgress:
					return this.$t("in_progress");
				case paymentStatus.Success:
					return this.$t("success");
				default:
					return this.$t("in_progress");
			}
		},

		getMyCardInfo() {
			remote.payment.getMyCardInfo({
				onSuccess: (result) => {
					this.savedCardInfo = result == "" ? null : result;
				},
				onFail: (e) => {
					return console.error(e);
				},
			});
		},

		goToSubscriptionAction() {
			this.$router.push({ name: "subscription" });
		},

		getProductName(id) {
			for (const p of this.antProducts) if (p.id == id) return `${this.$t(p.category)}/${this.$t(p.name)}`;
			return "-";
		},
	},
	created() {
		this.getMyCardInfo();
		this.loadSubscriptionInfo();
		this.loadItems();
		this.loadAntProducts();
	},
};
</script>
