<template>
	<v-container class="px-0 pb-0">
		<common-table
			:headers="headers"
			:items="items"
			:items-length="pagination.totalItemCount"
			:options.sync="tableOptions"
			:loading="loading"
			:items-per-page-options="tableFooterProps.itemsPerPageOptions"
			:entity-name="$t('partner')"
			@delete-item="deleteAction"
			@row-click="itemAction"
			style="cursor: pointer"
			disable-sort
		>
			<template v-slot:actions>
				<v-row no-gutters>
					<v-btn
						v-if="showCreateButton"
						color="accent"
						outlined
						large
						elevation="0"
						class="mr-2"
						@click="dialog.show(null, {})"
					>
						<v-icon class="icon-plus mr-2" start size="medium"></v-icon>
						{{ $t("create") }}
					</v-btn>
					<v-btn
						v-if="showSendInvitationButton"
						color="accent"
						outlined
						large
						elevation="0"
						@click="showInvitationDialog"
					>
						<v-icon class="icon-email mr-2" start size="medium"></v-icon>
						{{ $t("send_invitation") }}
					</v-btn>
				</v-row>
			</template>

			<template #item.partnershipStatus="{ item }">
				<v-chip
					:ripple="false"
					:color="item.corporationId ? 'grey lighten-2' : 'accent lighten-5'"
					:text-color="item.corporationId ? 'grey darken-4' : 'accent darken-4'"
				>
					<v-icon class="mr-2" small>
						{{ item.corporationId ? "icon-verified" : "icon-edit" }}
					</v-icon>
					{{ item.partnershipStatus }}
				</v-chip>
			</template>
		</common-table>

		<partner-dialog
			v-model="dialog.visibility"
			:item="dialog.item"
			:itemId="dialog.itemId"
			@saved="dialog.saved"
			@deleted="dialog.deleted"
			@closed="dialog.closed"
		/>

		<invitation-dialog
			v-if="invitationDialogVisibility"
			v-model="invitationDialogVisibility"
			:isEmployee="false"
			:defaultType="defaultType"
			@saved="invitationDialogSaved"
		/>
	</v-container>
</template>

<script>
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import remote from "../../../../data/remote";
import { mapState, mapGetters } from "vuex";
import PartnerDialog from "../dialogs/PartnerDialog.vue";
import InvitationDialog from "../../company_profile/dialogs/InvitationDialog.vue";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import CommonTable from "../../../partials/CommonTable.vue";
import { partnerTypes } from "../../../../utils/enum";

export default {
	props: {
		corporationId: String,
		types: Array, // ["company", "abstract_company", "customs_agency"]
		partnerDialogState: Boolean,
		invitationCodeDialogState: Boolean,
		needRefresh: Boolean,
		showCreateButton: {
			type: Boolean,
			default: false,
		},
		showSendInvitationButton: {
			type: Boolean,
			default: false,
		},
	},
	components: { PartnerDialog, CommonTable, InvitationDialog },
	mixins: [paginationMixin, uiMixin],
	data() {
		return {
			items: [],
			loading: false,
			invitationDialogVisibility: false,
			headers: [
				{ text: this.$t("name"), value: "tableName", width: 350 },
				{ text: this.$t("country"), value: "tableCountryName", width: 350 },
				{ text: this.$t("partnership_status"), value: "partnershipStatus" },
				{ text: this.$t("actions"), value: "actions" },
			],
			dialog: {
				visibility: false,
				item: null,
				itemId: null,
				show: (item, { itemId }) => {
					this.dialog.item = item;
					this.dialog.itemId = itemId;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
					this.$emit("partnerDialogClosed");
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				closed: () => {
					this.$emit("partnerDialogClosed");
				},
			},
		};
	},
	computed: {
		...mapState(["countries", "corporation"]),
		...mapGetters(["company", "customsAgency"]),
		defaultType() {
			if (this.types.includes(partnerTypes.Company) && this.types.includes(partnerTypes.AbstractCompany)) {
				return "company_partner";
			} else if (this.types.includes(partnerTypes.CustomsAgency)) {
				return "customs_partner";
			}
			return null;
		},
	},
	watch: {
		partnerDialogState(value) {
			if (value) this.dialog.show(null, {});
		},
		invitationCodeDialogState(value) {
			if (value) {
				this.showCodeInputDialog();
			}
		},
		needRefresh(value) {
			if (value) {
				this.loadItems();
				this.$emit("refreshed");
			}
		},
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.corporations.partners.filter(
				this.corporationId,
				{
					filter: { types: this.types },
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.items = result.items.sort((a, b) => {
							if (a.createdAt && b.createdAt) return a.createdAt < b.createdAt ? 1 : -1;
							else return !a.createdAt ? 1 : -1;
						});
						for (const item of this.items) {
							if (this.company) {
								item.tableName = item.name;
								item.tableCountryName = this.$t(`$country.${this.countries.find((i) => i.id == item.countryId).name}`);
							} else if (this.customsAgency) {
								item.tableName = item.ownerCompany.name;
								item.tableCountryName = this.$t(
									`$country.${this.countries.find((i) => i.id == item.ownerCompany.countryId).name}`
								);
							}
							if (item.corporationId) {
								item.partnershipStatus = this.$t("verified_partner");
							} else {
								item.partnershipStatus = this.$t("created_here");
							}
						}
						this.pagination.totalItemCount = result.pagination.totalItemCount;
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				}
			);
		},
		deleteAction(item) {
			if (item.corporationId) {
				this.showAlertDialog({
					message: this.$t("$message.cannot_delete_verified_partner"),
				});
				return;
			}
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.corporations.partners.delete(this.corporation.id, item.id, {
						onSuccess: (result) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("business_partner"),
								}),
							});
							this.dialog.deleted();
						},
					});
				},
			});
		},
		itemAction(item) {
			this.dialog.show(item, { itemId: this.customsAgency ? item.id : null });
		},
		showInvitationDialog() {
			this.invitationDialogVisibility = true;
		},
		invitationDialogSaved() {
			this.invitationDialogVisibility = false;
			this.loadItems();
		},
		showCodeInputDialog() {
			this.showTextInputDialog({
				title: this.$t("use_invitation_code"),
				message: this.$t("use_invitation_code_description"),
				inputTitle: this.$t("code"),
				inputRules: [
					(v) => (Boolean(v) && Boolean(v.trim())) || this.$t("$validation.required", { item: this.$t("code") }),
				],
				onSubmit: (text) => {
					return new Promise((resolve, reject) => {
						this.showProgressDialog();
						remote.invitations.accept(text.trim(), {
							onSuccess: (result) => {
								this.showSnackBar({
									message: this.$t("$proformaInvoice.demand_revise_success_message"),
								});
								this.hideProgressDialog();
								this.loadItems();
								this.$emit("saved", result);
								return resolve(true);
							},
							onFail: (e) => {
								this.hideProgressDialog();
								reject(e);
							},
						});
					});
				},
				didClose: () => this.$emit("invitationCodeDialogClosed"),
			});
		},
	},
	created() {
		this.loadItems();
	},
};
</script>
