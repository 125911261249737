<template>
	<page-template>
		<template slot="content">
			<v-container class="px-2 pb-0 mt-4">
				<v-row justify="space-between">
					<v-col cols="auto">
						<v-card-title class="font-weight-bold px-0 py-0">
							{{ $t("proforma_invoices") }}
						</v-card-title>
					</v-col>
					<v-col cols="auto" class="pr-2">
						<v-btn
							class="filter-button"
							outlined
							:color="filterIcon === 'mdi-filter' ? 'primary' : 'grey'"
							@click="filterDialog.show"
						>
							<v-icon class="icon-filter pr-2">{{ filterIcon }}</v-icon>
							{{ $t("filter") }}
						</v-btn>
					</v-col>
				</v-row>
			</v-container>

			<v-card class="ant-card pa-2 pb-0 mt-4" outlined elevation="0">
				<v-tabs v-model="tab" color="anchor" class="mb-4">
					<v-tab>{{ $t("outgoing") }}</v-tab>
					<v-tab>{{ $t("incoming") }}</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab" class="cardWidth">
					<v-tab-item>
						<proforma-invoices-table
							role="sender"
							:filterData="filterDialog.dataForSender"
							:initialPage="senderInitialPage"
							:atFront="Boolean(tab == 0)"
							:listChanged="listChanged"
							:buttonText="$t('new')"
							@rowAction="rowAction"
							@buttonClick="detailDialog.show(null)"
						/>
					</v-tab-item>

					<v-tab-item>
						<proforma-invoices-table
							role="receiver"
							:filterData="filterDialog.dataForReceiver"
							:initialPage="receiverInitialPage"
							:atFront="Boolean(tab == 1)"
							:listChanged="listChanged"
							@rowAction="rowAction"
						/>
					</v-tab-item>
				</v-tabs-items>
			</v-card>

			<proforma-invoice-filter-dialog
				v-model="filterDialog.visibility"
				:data="filterDialogData"
				@done="filterDialog.done"
			/>

			<proforma-invoice-dialog
				v-model="detailDialog.visibility"
				:item="detailDialog.item"
				@saved="detailDialog.saved"
				@deleted="detailDialog.deleted"
			/>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import PageTemplate from "../../templates/PageTemplate.vue";
import ProformaInvoiceDialog from "./dialogs/ProformaInvoiceDialog.vue";
import ProformaInvoiceFilterDialog from "./dialogs/ProformaInvoiceFilterDialog.vue";
import ProformaInvoicesTable from "./partial/ProformaInvoicesTable.vue";

export default {
	mixins: [uiMixin],
	components: {
		PageTemplate,
		ProformaInvoicesTable,
		ProformaInvoiceDialog,
		ProformaInvoiceFilterDialog,
	},
	data() {
		return {
			tab: 0,
			senderInitialPage: undefined,
			receiverInitialPage: undefined,
			listChanged: false,
			filterDialog: {
				visibility: false,
				dataForSender: { role: "sender", referenceId: null, statuses: [] },
				dataForReceiver: { role: "receiver", referenceId: null, statuses: [] },
				show: () => (this.filterDialog.visibility = true),
				hide: () => (this.filterDialog.visibility = false),
				done: (data) => {
					if (this.tab) this.filterDialog.dataForReceiver = data;
					else this.filterDialog.dataForSender = data;
					this.filterDialog.hide();
				},
			},
			detailDialog: {
				visibility: false,
				item: null,
				show(item) {
					this.item = item;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: (_) => {
					this.listChanged = !this.listChanged;
				},
				deleted: (_) => {
					this.detailDialog.hide();
					this.listChanged = !this.listChanged;
				},
			},
		};
	},
	computed: {
		filterIcon() {
			return this.filterDialogData.referenceId || this.filterDialogData.statuses.length
				? "mdi-filter"
				: "mdi-filter-outline";
		},
		filterDialogData() {
			return this.tab ? this.filterDialog.dataForReceiver : this.filterDialog.dataForSender;
		},
	},
	methods: {
		rowAction(item) {
			this.detailDialog.show(item);
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.tab = Number(this.$route.query.tab) || 0;
		const page = this.$route.query.page ? Number(this.$route.query.page) : undefined;
		if (this.tab) {
			this.receiverInitialPage = page;
			this.filterDialog.dataForReceiver.referenceId = this.$route.query.referenceId;
			if (this.$route.query.statuses)
				this.filterDialog.dataForReceiver.statuses = this.$route.query.statuses.split(",");
		} else {
			this.senderInitialPage = page;
			this.filterDialog.dataForSender.referenceId = this.$route.query.referenceId;
			if (this.$route.query.statuses) this.filterDialog.dataForSender.statuses = this.$route.query.statuses.split(",");
		}
	},
};
</script>

<style scoped>
.cardWidth {
	width: 100% !important;
}
.filter-button {
	border-radius: 4px !important;
	font-size: 18px !important;
	line-height: 20px !important;
	font-weight: normal !important;
	letter-spacing: 0px !important;
	background-color: white !important;
}
</style>
