import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import en from "vuetify/es5/locale/en";
import tr from "vuetify/es5/locale/tr";
import { Setting, getSetting } from "../data/local/ls";

Vuetify.config.silent = false; // suppresses all Vuetify logs and warnings when true.

Vue.use(Vuetify);

export const color = {
	primary: "#002234",
	secondary: "#CC2828",
	accent: "#005D8F",
	error: "#CC2828",
	info: "#2196F3",
	success: "#2D8742",
	warning: "#E06A00",
	anchor: "#786F77",
	backgroundLight: "#FBFBFB",
	backgroundDark: "#141414",
	//
	// red: "#CC2828",
	// blue: "#005D8F",
	grey: "#EAEAEA",
	grey2: "#786F77",
	// grayBG: "#FBFBFB",
	stroke: "#F7F7F7",
	// text: "#141414", // background
	text2: "#2B2D32",
	text3: "#4A4C51",
	// darkBlue: "#002234",
	// green: "#2D8742",
	white: "#FFFFFF",
};

export default new Vuetify({
	theme: {
		dark: getSetting(Setting.dark_mode),
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: color.primary,
				secondary: color.secondary,
				accent: color.accent,
				error: color.error,
				info: color.info,
				success: color.success,
				warning: color.warning,
				anchor: color.anchor,
				background: color.backgroundLight,
				gradient: {
					primary: [colors.teal.darken4, colors.teal.lighten4],
					secondary: [colors.amber.darken4, colors.amber.lighten4],
					accent: [colors.pink.darken4, colors.pink.lighten4],
					error: [colors.red.darken4, colors.red.lighten4],
					info: [colors.blue.darken4, colors.blue.lighten4],
					success: [colors.green.darken4, colors.green.lighten4],
					warning: [colors.amber.darken4, colors.amber.lighten4],
				},
			},
			dark: {
				primary: color.accent,
				secondary: color.secondary,
				accent: color.primary,
				error: color.error,
				info: color.info,
				success: color.success,
				warning: color.warning,
				anchor: color.anchor,
				background: color.backgroundDark,
				gradient: {
					primary: [colors.teal.lighten4, colors.teal.darken4],
					secondary: [colors.amber.lighten4, colors.amber.darken4],
					accent: [colors.pink.lighten4, colors.pink.darken4],
					error: [colors.red.lighten4, colors.red.darken4],
					info: [colors.blue.lighten4, colors.blue.darken4],
					success: [colors.green.lighten4, colors.green.darken4],
					warning: [colors.amber.lighten4, colors.amber.darken4],
				},
			},
			// dark/light: {
			// primary: color.primary,
			// secondary: color.secondary,
			// accent: color.accent,
			// error: colors.red.darken1,
			// info: colors.blue.darken1,
			// success: colors.green.darken1,
			// warning: colors.amber.darken1,
			// anchor: colors.teal.lighten5,
			// background: color.backgroundLight,
			// gradient: {
			// 	primary: [colors.teal.darken4, colors.teal.lighten4],
			// 	secondary: [colors.amber.darken4, colors.amber.lighten4],
			// 	accent: [colors.pink.darken4, colors.pink.lighten4],
			// 	error: [colors.red.darken4, colors.red.lighten4],
			// 	info: [colors.blue.darken4, colors.blue.lighten4],
			// 	success: [colors.green.darken4, colors.green.lighten4],
			// 	warning: [colors.amber.darken4, colors.amber.lighten4],
			// },
			// },
		},
		icons: {
			iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
		},
	},
	lang: {
		locales: { en, tr },
	},
});
