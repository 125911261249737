<template>
	<ext-dialog
		v-model="value"
		:title="dialogTitle"
		dialogType="large"
		:primaryButtonText="primaryButtonText"
		@primaryButtonAction="primaryButtonAction"
		@closed="closeAction"
	>
		<template slot="body">
			<div v-if="currentItem">
				<div class="d-flex align-center mb-2">
					<span class="font-weight-bold mr-2">{{ $t("partnership_status") }}</span>
					<v-chip
						:ripple="false"
						:color="isVerifiedPartner ? 'grey lighten-2' : 'accent lighten-5'"
						:text-color="isVerifiedPartner ? 'grey darken-4' : 'accent darken-4'"
						class="ml-2"
					>
						<v-icon class="mr-2" small>
							{{ isVerifiedPartner ? "icon-verified" : "icon-edit" }}
						</v-icon>
						{{ partnershipStatus }}
					</v-chip>
				</div>
				<div class="d-flex align-center mb-4">
					<v-icon small class="mr-2"> mdi-information-outline </v-icon>
					<span>{{ partnershipDescription }}</span>
				</div>
			</div>
			<v-form ref="form" v-model="valid" v-if="currentItem">
				<v-row>
					<v-col cols="6" class="pb-0">
						<v-text-field
							v-model="currentItem.name"
							:label="$t('name') + '*'"
							:rules="rules.name"
							prepend-icon="mdi-signature-text"
							:disabled="currentItem.type != partnerTypes.AbstractCompany"
						/>
					</v-col>
					<v-col cols="6" class="pb-0">
						<country-select
							v-model="currentItem.countryId"
							:options="countries"
							:label="$t('country') + '*'"
							:rules="rules.country"
							prepend-icon="mdi-earth-box"
							:disabled="currentItem.type != partnerTypes.AbstractCompany"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6">
						<v-text-field
							v-model="currentItem.email"
							:label="$t('email') + '*'"
							:rules="rules.email"
							prepend-icon="mdi-email"
							:disabled="currentItem.type !== partnerTypes.AbstractCompany"
						/>
					</v-col>
					<v-col cols="6">
						<v-text-field
							v-model="currentItem.phoneNumber"
							:label="$t('phone_number')"
							:rules="rules.phoneNumber"
							type="number"
							hide-spin-buttons
							prepend-icon="mdi-phone"
							:disabled="currentItem.type != partnerTypes.AbstractCompany"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6">
						<v-text-field
							v-model="currentItem.website"
							:label="$t('website')"
							prepend-icon="mdi-web"
							:disabled="currentItem.type !== partnerTypes.AbstractCompany"
						/>
					</v-col>
					<v-col cols="6">
						<v-text-field
							v-model="currentItem.taxNumber"
							:label="this.$t('tax_number')"
							type="number"
							hide-spin-buttons
							:rules="rules.taxNumber"
							prepend-icon="mdi-receipt-text-outline"
							:disabled="currentItem.type != partnerTypes.AbstractCompany"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-text-field
							v-model="currentItem.address"
							:label="$t('address') + '*'"
							:rules="rules.address"
							counter
							prepend-icon="mdi-office-building-marker-outline"
							:disabled="currentItem.type != partnerTypes.AbstractCompany"
						/>
					</v-col>
				</v-row>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import CountrySelect from "../../../controls/CountrySelect";
import LocalizedTextField from "../../../controls/LocalizedTextField";
import { mapGetters, mapState } from "vuex";
import { partnerTypes } from "../../../../utils/enum";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	mixins: [uiMixin],
	props: ["value", "item", "itemId"],
	components: { CountrySelect, LocalizedTextField, ExtDialog },
	data() {
		return {
			currentItem: null,
			valid: null,
			saving: false,
			deleting: false,
			itemTemplate: {
				name: "",
				countryId: null,
				address: "",
				phoneNumber: "",
				taxNumber: "",
				email: "",
				website: "",
				type: partnerTypes.AbstractCompany,
			},
			rules: {
				name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("name"),
							});
						else if (v && v.length < 3)
							return this.$t("$validation.min_length", {
								item: this.$t("name"),
								length: 3,
							});
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: 100,
							});
						return true;
					},
				],
				country: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("country"),
							});
						return true;
					},
				],
				address: [
					(v) => {
						if (!v || v.trim().length === 0) {
							return this.$t("$validation.required", { item: this.$t("address") });
						}
						if (v.length > 140) {
							return this.$t("$validation.max_length", { item: this.$t("address"), length: 140 });
						}
						return true;
					},
				],
				phoneNumber: [
					(v) => {
						if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("phone_number"),
								length: 20,
							});
						return true;
					},
				],
				taxNumber: [
					(v) => {
						return true;
					},
				],
				email: [
					(v) => {
						if (!v || v.trim().length === 0) {
							return this.$t("$validation.required", { item: this.$t("email") });
						}
						if (!/.+@.+\..+/.test(v)) {
							return this.$t("$validation.invalid", { item: this.$t("email") });
						}
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapGetters(["defaultLanguage"]),
		...mapState(["corporation", "countries"]),
		partnerTypes: () => partnerTypes,
		dialogTitle() {
			if (this.currentItem?.id && this.currentItem?.type !== this.partnerTypes.AbstractCompany) {
				return `${this.$t("business_partner")}`;
			} else if (this.currentItem?.id && this.currentItem?.type === this.partnerTypes.AbstractCompany) {
				return `${this.$t("edit")} ${this.$t("business_partner")}`;
			} else {
				return `${this.$t("new")} ${this.$t("business_partner")}`;
			}
		},
		isAbstractCompany() {
			return this.currentItem?.type === this.partnerTypes.AbstractCompany;
		},
		primaryButtonText() {
			return this.isAbstractCompany ? this.$t("save") : this.$t("close");
		},
		primaryButtonAction() {
			return this.isAbstractCompany ? this.saveAction : this.closeAction;
		},
		partnershipStatus() {
			if (this.currentItem) {
				return this.currentItem.corporationId ? this.$t("verified_partner") : this.$t("created_here");
			}
			return "";
		},
		isVerifiedPartner() {
			return this.currentItem && !!this.currentItem.corporationId;
		},
		partnershipDescription() {
			if (this.currentItem) {
				if (this.isVerifiedPartner) {
					return this.$t("$partnershipStatusTooltip.verifiedPartnersDescription");
				} else {
					return this.$t("$partnershipStatusTooltip.createdHereDescription");
				}
			}
			return "";
		},
	},
	watch: {
		value(val) {
			if (val) {
				if (this.itemId) this.loadItem(this.itemId, this.item.ownerCompany.companyId);
				else this.currentItem = Object.assign({}, this.item || this.itemTemplate);
			} else {
				this.$refs.form.reset();
				this.currentItem = null;
			}
		},
	},
	methods: {
		loadItem(id, ownerCompanyId) {
			remote.corporations.partners.findCorporationForCustomsAgency(this.corporation.id, id, ownerCompanyId, {
				onSuccess: (result) => (result ? (this.currentItem = result) : null),
			});
		},
		saveAction() {
			if (this.valid) {
				this.saving = true;
				if (this.currentItem.id) {
					remote.corporations.partners.update(this.corporation.id, this.currentItem, {
						onSuccess: (result) => {
							this.saving = false;
							this.showSnackBar({
								message: this.$t("$message.updated", {
									item: this.$t("business_partner"),
								}),
							});
							this.$editEvent("partner");
							this.$emit("saved", result);
						},
						onFail: () => this.hideProgressDialog(),
					});
				} else {
					remote.corporations.partners.create(this.corporation.id, this.currentItem, {
						onSuccess: (result) => {
							this.saving = false;
							this.showSnackBar({
								message: this.$t("$message.created", {
									item: this.$t("business_partner"),
								}),
							});
							this.$addEvent("partner");
							this.$emit("saved", result);
						},
						onFail: () => this.hideProgressDialog(),
					});
				}
			} else this.$refs.form.validate();
		},

		closeAction() {
			this.$emit("input", false);
			this.$emit("closed");
		},
	},
};
</script>
