<template>
	<v-list-item
		active-class="accent background--text"
		@click="clickAction"
		:link="isLinkButton"
		:to="isLinkButton ? url : null"
	>
		<v-list-item-action>
			<v-icon class="px-1">{{ icon }}</v-icon>
		</v-list-item-action>
		<v-list-item-content>
			<v-list-item-title class="button">
				{{ title }}
			</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	props: ["title", "icon", "url"],
	computed: {
		isLinkButton() {
			return this.url != null;
		},
	},
	methods: {
		clickAction() {
			if (!this.isLinkButton) this.$emit("click", true);
		},
	},
};
</script>
